<template>
  <div>
    <iframe
      id="pdf-js-viewer"
      ref="pdf_frame"
      :src="`/digital-locker/pdf/web/viewer.html?file=${btoa(uri)}`"
      frameborder="0"
      width="100%"
      height="100%"
      style="flex: 1"
    />
  </div>
</template>

<script>
  export default {

    props: {
      uri: {
        type: String,
        required: true,
      },
      contentMaxHeight: {
        type: Number,
        required: false,
        default: 800,
      },
      downloadAllowed: {
        type: Boolean,
        default: true,
      },
    },

    mounted () {
      this.webViewerLoadedHandler = (event) => {
        this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
          event: 'view-only',
        }), '*')

        if (!this.downloadAllowed) {
          this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
            event: 'disallow-download',
          }), '*')
        }
      }
      window.addEventListener('webviewerloaded', this.webViewerLoadedHandler)
    },

    destroyed () {
      window.removeEventListener('webviewerloaded', this.webViewerLoadedHandler)
    },

    methods: {
      btoa (data) {
        return window.btoa(data)
      },
    },
  }
</script>
<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="scss" scoped>
#pdf-js-viewer {
  min-height: 80vh;
}
</style>
