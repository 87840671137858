import { render, staticRenderFns } from "./PdfViewerV0.vue?vue&type=template&id=4cc554b9&scoped=true&"
import script from "./PdfViewerV0.vue?vue&type=script&lang=js&"
export * from "./PdfViewerV0.vue?vue&type=script&lang=js&"
import style0 from "./PdfViewerV0.vue?vue&type=style&index=0&id=4cc554b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc554b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VFadeTransition,VIcon,VOverlay,VProgressCircular})
