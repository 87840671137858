import Vue from 'vue'
import upperFirst from 'lodash.upperfirst'
import camelCase from 'lodash.camelcase'

const requireCoreComponent = require.context(
  '@/components/core', true, /\.vue$/,
)

requireCoreComponent.keys().forEach(fileName => {
  const componentConfig = requireCoreComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )

  Vue.component(`Common${componentName}`, componentConfig.default || componentConfig)
})
