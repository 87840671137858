import Vue from 'vue'
import * as mainService from '@/components/common/mixins/index.js'
import { PORTAL } from '@/constants'
import * as CONSTS from '@/constants'
import store from '@/store'

Vue.directive('feature', (el, binding, vnode) => {
  if (!isAllowedFeature(binding.value)) {
    // replace HTMLElement with comment node
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    })
    vnode.elm = comment
    vnode.text = ' '
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }
})

function isAllowedFeature (feature) {
  const allowedFeatureList = getFeatureList()
  let isFeatureAllowed = false
  if (allowedFeatureList && allowedFeatureList.length > 0) {
    const userRoles = getUserRole()
    const currentFeature = allowedFeatureList.find(item => item.featureName === feature)
    for (const item of userRoles) {
      if (currentFeature && currentFeature.allowedRoles.includes(item)) {
          isFeatureAllowed = true
          break
      }
    }
  } else {
    // We are keeping this condition as fetaure RBAC is not implemented in other portals,
    //  for common component featurees we are allowing if feature is not found
    isFeatureAllowed = true
  }
return isFeatureAllowed
}

function getFeatureList () {
  const portal = mainService.$getPortal()
  if (portal === PORTAL.FID_ADMIN) {
    return CONSTS.FID_FEATURE_ROLES
  } else if (portal === PORTAL.CAPTABLE) {
    return CONSTS.CAPTABLE_FEATURE_ROLES
  } else {
    return []
  }
}

function getUserRole () {
  return store.getters['auth/roles']
}
