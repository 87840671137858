var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.layout === 'card')?_c('v-card',{staticClass:"mt-0",attrs:{"width":"320"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.document.document_name || _vm.document.document_type)+" "),(_vm.loading || _vm.docLoading)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary","size":"20"}}):_vm._e()],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.oppDocument)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-list-item-subtitle',[_vm._v("Opportunity")])],1),_c('v-col',[_c('v-list-item-title',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{ name: 'DigitalRoadshow.Landing', params: { id: _vm.document.opportunity_id } }}},[_vm._v(" "+_vm._s(_vm.document.opportunity_name)+" ")])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-list-item-subtitle',[_vm._v("Updated")])],1),_c('v-col',[_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.document.modified_on))+" ")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-list-item-subtitle',[_vm._v("Type")])],1),_c('v-col',[_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.document.document_type)+" ")])],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('common-trs-btn',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"type":"tertiary","dark":"","text":"","fab":"","small":""}},'common-trs-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3256108283)},[_c('v-list',{attrs:{"dense":""}},[(_vm.showViewBtn && !_vm.selecteddocument)?_c('v-list-item',{attrs:{"loading":_vm.loading || _vm.docLoading},on:{"click":function($event){return _vm.loadDocument(_vm.document)}}},[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")]):_vm._e(),(_vm.showViewBtn && _vm.selecteddocument && _vm.selecteddocument.signed_url)?_c('common-common-preview-file-modal',{attrs:{"fullscreen":"","initial-visible":true,"title":_vm.CONSTS.DOCUMENT_TYPES_TEXT[_vm.document.document_type],"url":_vm.selecteddocument.signed_url,"mime-type":_vm.selecteddocument.mime_type,"has-download-btn":true,"description":_vm.selecteddocument.description},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"loading":_vm.loading || _vm.docLoading}},on),[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")])]}}],null,false,2978193692)}):_vm._e(),(_vm.showSignBtn)?_c('v-list-item',{attrs:{"loading":_vm.loading || _vm.signLoading},on:{"click":function($event){return _vm.fidDocSign(_vm.document)}}},[_vm._v(" Sign ")]):_vm._e()],1)],1)],1)],1),_vm._t("card-bottom-actions")],2):(_vm.layout ==='table')?_c('tr',[_vm._t("table-content",[_c('td',[_vm._v(_vm._s(_vm.document.document_name || _vm.document.document_type))]),(_vm.oppDocument)?_c('td',[_c('router-link',{attrs:{"to":{ name: 'OpportunityDetail', params: { id: _vm.document.opportunity_id } }}},[_vm._v(" "+_vm._s(_vm.document.opportunity_name)+" ")])],1):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.document.modified_on)))]),_c('td',[_vm._v(_vm._s(_vm.document.document_type))]),_c('td',[(_vm.showViewBtn && !_vm.selecteddocument)?_c('common-trs-btn',{staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.docLoading},on:{"click":function($event){return _vm.loadDocument(_vm.document)}}},[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")]):_vm._e(),(_vm.showViewBtn && _vm.selecteddocument && _vm.selecteddocument.signed_url)?_c('common-common-preview-file-modal',{attrs:{"fullscreen":"","initial-visible":true,"title":_vm.CONSTS.DOCUMENT_TYPES_TEXT[_vm.document.document_type],"url":_vm.selecteddocument.signed_url,"mime-type":_vm.selecteddocument.mime_type,"has-download-btn":true,"description":_vm.selecteddocument.description},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('common-trs-btn',_vm._g({staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.docLoading}},on),[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")])]}}],null,false,75851508)}):_vm._e(),(_vm.showSignBtn)?_c('common-trs-btn',{staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.signLoading},on:{"click":function($event){return _vm.fidDocSign(_vm.document)}}},[_vm._v(" Sign ")]):_vm._e()],1)]),_c('td',[_vm._t("table-actions")],2)],2):(_vm.layout === 'tree-view')?_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.document.document_name || _vm.document.document_type)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$date(_vm.document.modified_on))+" ")]),_c('v-col',[(_vm.showViewBtn && !_vm.selecteddocument)?_c('common-trs-btn',{staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.docLoading},on:{"click":function($event){return _vm.loadDocument(_vm.document)}}},[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")]):_vm._e(),(_vm.showViewBtn && _vm.selecteddocument && _vm.selecteddocument.signed_url)?_c('common-common-preview-file-modal',{attrs:{"fullscreen":"","initial-visible":true,"title":_vm.CONSTS.DOCUMENT_TYPES_TEXT[_vm.document.document_type],"url":_vm.selecteddocument.signed_url,"mime-type":_vm.selecteddocument.mime_type,"has-download-btn":true,"description":_vm.selecteddocument.description},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('common-trs-btn',_vm._g({staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.docLoading}},on),[_vm._v(" "+_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View')+" ")])]}}],null,false,75851508)}):_vm._e(),(_vm.showSignBtn)?_c('common-trs-btn',{staticClass:"primary--text",attrs:{"text":"","small":"","type":"tertiary","loading":_vm.loading || _vm.signLoading},on:{"click":function($event){return _vm.fidDocSign(_vm.document)}}},[_vm._v(" Sign ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }