<template>
  <v-card-actions
    class="trs-card-actions d-flex align-center justify-space-between"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </v-card-actions>
</template>

<script>
  export default {
    name: 'CommonTrsModalCardActions',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
