<template>
  <v-row
    class="text-no-wrap"
    v-bind="{ ...defaultAttrs, ...$attrs }"
  >
    <v-col
      cols="3"
      class="text-right grey--text mt-3"
    >
      <slot name="label">
        {{ label }}
        <span
          v-if="!required"
        >&nbsp;(optional)</span>
      </slot>
    </v-col>

    <v-col>
      <slot />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'CommonInputHorizonLayout',

    components: {
    },

    props: {
      label: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      defaultAttrs: {},
    }),

    computed: {
    },

    watch: {
    },

    async created () {
    },

    methods: {
    },
  }
</script>

<style lang="sass">
</style>
