<template>
  <v-dialog
    v-model="internalValue"
    :max-width="800"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          Crop Image
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="closeSimple"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>

      <common-trs-modal-card-text v-if="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          alt="Source Image"
          :min-crop-box-height="minCropBoxHeight"
          :min-crop-box-width="minCropBoxWidth"
          :auto-crop-area="0.2"
          @ready="onReady"
          @crop="onCrop"
        />
      </common-trs-modal-card-text>

      <common-trs-modal-card-actions>
        <common-trs-btn
          class="mr-3 primary--text"
          type="tertiary"
          text
          @click="close"
        >
          Do not crop
        </common-trs-btn>

        <common-trs-btn
          type="primary"
          text
          class="white--text"
          @click="onYes"
        >
          Crop
        </common-trs-btn>
      </common-trs-modal-card-actions>
    </common-trs-modal-card>
  </v-dialog>
</template>
<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import * as utils from '@/components/core/utils'
  export default {
    name: 'CropperModal',

    components: { VueCropper },

    props: {
      minCropBoxHeight: {
        type: String,
        default: '200',
      },
      minCropBoxWidth: {
        type: String,
        default: '200',
      },
    },

    data () {
      return {
        internalValue: false,
        // imgSrc: 'https://s3.amazonaws.com/dev.static.trellisplatform.com/790_display_picture.png',
        imgSrc: '',
        originalFile: null,
      }
    },

    computed: {

    },
    methods: {
      onReady () {},

      onCrop () {},
      async open (options) {
        this.internalValue = true
        this.originalFile = options.file
        this.imgSrc = await utils.readAsBase64(this.originalFile)
        if (this.$refs.cropper) {
          this.$refs.cropper.replace(this.imgSrc)
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      closeSimple (ev) {
        this.internalValue = false
        this.resolve && this.reject()
      },
      close (ev) {
        this.internalValue = false
        this.$emit('close', ev)
        this.reject && this.resolve(this.originalFile)
      },
      onYes (ev) {
        const croppedImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
        const file = utils.convertDataURLtoBlob(croppedImg)
        this.internalValue = false
        this.$emit('yes', ev, file)
        this.resolve && this.resolve(file)
      },
    },
  }
</script>
<style lang="scss" scoped>

</style>
