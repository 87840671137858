<template>
  <common-trs-card class="pa-2">
    <slot
      name="prepend"
    />
    <v-row
      v-if="$scopedSlots['primary-filter'] || $scopedSlots['actions']"
      dense
      align="center"
    >
      <v-col
        cols="12"
        sm="4"
      >
        <slot
          name="primary-filter"
          v-bind="{ toggleExpanded, expanded }"
        />
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <slot
          name="actions"
        />
      </v-col>
    </v-row>

    <div
      v-if="appliedFilter > 0"
      class="ma-2 primary--text"
    >
      ({{ appliedFilter }}) Filters Applied
    </div>

    <v-expand-transition>
      <v-row
        v-show="expanded"
        dense
      >
        <slot name="content" />
      </v-row>
    </v-expand-transition>
    <slot
      name="append"
    />
  </common-trs-card>
</template>

<script>
  export default {
    name: 'BaseCommonFilterCard',

    components: {
    },

    props: {
      appliedFilter: {
        type: Number,
        default: 0,
      },
      noHeaderPadding: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        expanded: false,
      }
    },

    computed: {
    },

    watch: {
    },

    mounted () {
    },

    methods: {
      toggleExpanded () {
        this.expanded = !this.expanded
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
