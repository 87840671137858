import { render, staticRenderFns } from "./CardTitle.vue?vue&type=template&id=6391130c&scoped=true&"
import script from "./CardTitle.vue?vue&type=script&lang=js&"
export * from "./CardTitle.vue?vue&type=script&lang=js&"
import style0 from "./CardTitle.vue?vue&type=style&index=0&id=6391130c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6391130c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardTitle})
