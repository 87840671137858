<template>
  <div class="toggle-btn-container">
    <v-btn-toggle
      class="v-btn-toggle"
      mandatory
    >
      <common-trs-btn
        v-for="(opt, index) in options"
        :key="index"
        class="toggle-btn-active-inactive"
        :type="opt.value === internalValue ? 'primary' : 'tertiary'"
        v-bind="$attrs"
        @click="internalValue = opt.value; $emit('click')"
      >
        {{ opt.label }}
      </common-trs-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
  /**
    <common-common-filter-card>
      <any content>
    </common-common-filter-card>
    */
  export default {
    name: 'BaseCommonButtonToggle',

    components: {
    },

    props: {
      value: {
        type: String,
        default: () => {},
      },
      // text, value
      options: {
        type: Array,
        default: () => [],
      },
      size: {
        type: String,
        default: 'medium',
      },
    },

    data () {
      return {
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value || this.options[0].value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (!val) {
            this.internalValue = this.options[0].value
          }
        },
        immediate: true,
      },
    },

    methods: {
    },
  }
</script>
<style lang="scss" scoped>
.toggle-btn-container {
   display: inline-block;
   border: 1px solid var(--v-primary-base);
   border-radius: 4px;
  .toggle-btn-active-inactive {
      padding: 16px 11px!important;
      border-radius: 2px!important;
      border: none;
      font: normal 500 10px/12px $font-family-base!important;
  }
}
::v-deep .v-btn:before {
  background: transparent;
}
</style>
