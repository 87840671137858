import axios from 'axios'
import { PORTAL_BRANDING_JSON_URL } from '@/constants'

const DEFAULT_PORTAL_BRANDING = {
  logo: require('@/components/common/assets/logos/1.svg'),
  help_address: 'support@trellishelp.zendesk.com',
}

const DEFAULT_PORTAL_BRANDING_CLEARLIST = {
  logo: 'https://s3.amazonaws.com/dev.static.trellisplatform.com/logos/portal.clearlist.com.png',
  help_address: 'support@clearlist.com',
}

export const getPortalBranding = async () => {
  try {
    const resp = await axios.get(`${PORTAL_BRANDING_JSON_URL}`)

    return resp.data
  } catch (err) {
    return location.hostname.indexOf('clearlist') !== -1 ? DEFAULT_PORTAL_BRANDING_CLEARLIST : DEFAULT_PORTAL_BRANDING
  }
}
