export const ACTIVITY_TYPE = {
  DATAROOM_DOCUMENTS_VIEWED: 'DATAROOM_DOCUMENTS_VIEWED',
  DOCUMENTS_VIEWED: 'DOCUMENTS_VIEWED',
  OPPORTUNITY_VIEWED: 'OPPORTUNITY_VIEWED',
  FORM_VIEWED: 'FORM_VIEWED',
  GET_CROWDSMART_EMBEDDED_APP_CODE: 'GET_CROWDSMART_EMBEDDED_APP_CODE',
  FID_ADMIN_DATAROOM_DOCUMENTS_VIEWED: 'FID_ADMIN_DATAROOM_DOCUMENTS_VIEWED',
  NDA_DOCUMENTS_POSTED: 'NDA_DOCUMENTS_POSTED',
}

export const ACTIVITY_TYPE_NAMES = {
  DATAROOM_DOCUMENTS_VIEWED: 'DataRoom Documents Viewed',
  DOCUMENTS_VIEWED: 'Documents Viewed',
  OPPORTUNITY_VIEWED: 'Opportunity Viewed',
  FORM_VIEWED: 'Form Viewed',
  GET_CROWDSMART_EMBEDDED_APP_CODE: 'Get Crowdsmart Embedded App Code',
  FID_ADMIN_DATAROOM_DOCUMENTS_VIEWED: 'Fid Admin DataRoom Documents Viewed',
  NDA_DOCUMENTS_POSTED: 'NDA Documents Posted',
}

export const ACTIVITY_TYPES = [
  {
    text: 'DataRoom Documents Viewed',
    value: ACTIVITY_TYPE.DATAROOM_DOCUMENTS_VIEWED,
  },
  {
    text: 'Documents Viewed',
    value: ACTIVITY_TYPE.DOCUMENTS_VIEWED,
  },
  {
    text: 'Opportunity Viewed',
    value: ACTIVITY_TYPE.OPPORTUNITY_VIEWED,
  },
  {
    text: 'Form Viewed',
    value: ACTIVITY_TYPE.FORM_VIEWED,
  },
  {
    text: 'Get Crowdsmart Embedded App Code',
    value: ACTIVITY_TYPE.GET_CROWDSMART_EMBEDDED_APP_CODE,
  },
]
