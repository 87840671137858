<template>
  <div>
    <list-filter
      v-if="!notification && !hideFilterWidget"
      v-model="filters"
      :hide-filters="hideFilters"
      :hide-header="hideHeader"
      class="mb-2"
      :align-right="true"
      :portal="$getPortal()"
      :hide-opp-filter="hideOppFilter"
    />
    <div
      v-if="notification"
      class="d-flex justify-space-between my-5 px-3"
      style="min-width: 370px"
    >
      <div class="font-weight-medium text-body-2 trsText--text">
        Communications ({{ items.length }})
      </div>
      <div
        v-if="items.length > itemsForNotification"
        class="primary--text text-subtitle-1 font-weight-regular ml-auto"
      >
        <div
          class="cursor-pointer ttrsText--text text-caption primary--text font-weight-medium"
          @click="goToSeeAll"
        >
          See All
        </div>
      </div>
    </div>
    <create-item
      v-if="showCreateItem && !notification"
      :placeholder="placeholder"
      :message-name="messageName"
      @create="createItem"
    />
    <list
      v-if="!hideHistory"
      :loading="loading"
      :options.sync="options"
      :items="itemsPerPage"
      :notification="notification"
      @loadData="loadData"
    >
      <template v-slot:item="{ item }">
        <slot
          name="item"
          :item="item"
          :update="updateItem"
          :remove="removeItem"
        />
      </template>
      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]
      >
        <slot :name="slot" />
      </template>
    </list>
  </div>
</template>

<script>
  import * as communicationSrvc from '@/components/common/services/communications'
  import debounce from 'lodash/debounce'

  export default {
    name: 'ListWrapper',

    components: {
      List: () => import('./List'),
      ListFilter: () => import('./ListFilter'),
      CreateItem: () => import('./CreateItem'),
    },

    props: {
      placeholder: {
        default: 'Enter your comment or question...',
      },
      messageName: {
        default: 'Comment/Question',
      },
      hideFilterWidget: {
        default: false,
      },
      hideHistory: {
        default: false,
      },
      hideFilters: {
        validate: () => true,
        default: () => [],
      },
      preFilters: {
        type: Object,
        default: () => {},
      },
      showCreateItem: {
        type: Boolean,
        default: false,
      },
      doReload: {
        type: Boolean,
        default: false,
      },
      base: {
        type: String,
        default: '',
      },
      hideHeader: {
        type: Boolean,
        default: true,
      },
      hideOppFilter: {
        type: Boolean,
        default: true,
      },
      notification: {
        type: Boolean,
        default: false,
      },
      seeAllCommunications: {
        validate: () => true,
      },
      documentId: {
        type: String,
        default: '',
      },
      documentType: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      queryLoaded: false,
      options: {
        pager: {
          itemsPerPage: 10,
          page: 1,
          total: 1,
        },
      },
      sort: {
        sortBy: ['created_on'],
        sortDesc: [false],
      },
      filters: {},
      loading: false,
      itemsForNotification: 3,

      items: [],
    }),

    computed: {
      portal () {
        return this.$getPortal()
      },
      itemsPerPage () {
        const itemsPerPage = this.notification ? this.itemsForNotification : this.options.pager.itemsPerPage
        const itemsCopy = JSON.parse(JSON.stringify(this.items))
        const startFrom = (this.options.pager.page * itemsPerPage) - itemsPerPage
        return itemsCopy.splice(startFrom, itemsPerPage)
      },
    },

    watch: {
      preFilters: {
        handler (val) {
          this.filters = {
            ...this.filters,
            ...val,
          }
        },
        deep: true,
        immediate: true,
      },
      filters: {
        handler: debounce(function (val) {
          this.options.page = 1
          this.loadData()
        }, 500),
        deep: true,
      },
      options: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
      doReload: {
        handler (val) {
          if (val) {
            this.loadData()
          }
        },
        immediate: true,
      },
    },

    created () {
      this.loadData()
    },

    methods: {
      async createItem (data) {
        this.loading = true
        try {
          let payload = {
            ...data,
            opportunity_id: this.preFilters.opportunity_id,
          }
          if (this.documentId) {
            payload = {
              ...payload,
              document_id: this.documentId,
              document_type: this.documentType,
            }
          }
          await communicationSrvc.createCommunication(payload, { base: this.base })
          this.$emit('created')
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to create item',
          })
        }
        this.loading = false
        await this.loadData()
      },
      async removeItem (id) {
        const item = this.items.filter(t => t.id === id)[0]
        this.loading = true
        try {
          await communicationSrvc.deleteCommunication(id, { base: this.base }, item.source)
          this.$store.commit('app/showMessage', { text: 'Successfully deleted the communication item' })
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to remove item',
          })
        }
        this.loading = false
        await this.loadData()
      },
      async updateItem (id, data) {
        const item = this.items.filter(t => t.id === id)[0]
        this.loading = true
        try {
          const apiResponse = await communicationSrvc.updateCommunication(id, data, { base: this.base }, item.source)
          this.items = this.items.map(o => o.id === apiResponse.id ? apiResponse : o)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to update item',
          })
        }
        this.loading = false
      },
      async loadData () {
        let apiResponse
        this.loading = true
        try {
          this.sort.sortBy = ['modified_on']
          if (this.notification && this.filters && this.base !== 'tenant') {
            this.filters.has_response = null
          }

          apiResponse = await communicationSrvc.getCommunications({
            sortDesc: [this.filters.sortByDate],
            sortBy: this.sort.sortBy,
            page: this.options.pager.page,
            itemsPerPage: this.options.pager.itemsPerPage,
            filters: {
              ...this.filters,
            },
            // query
          }, { base: this.base })

          if (this.portal === this.CONSTS.PORTAL.ISSUER) {
            this.items = apiResponse.results || []
            this.options.pager.total = Math.ceil(apiResponse.count / this.options.pager.itemsPerPage)

            this.$emit('count', apiResponse.count)
          } else {
            this.items = apiResponse || []
            this.options.pager.total = Math.ceil(this.items.length / this.options.pager.itemsPerPage)

            this.$emit('count', this.items.length)
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
        this.loading = false
      },
      goToSeeAll () {
        this.$emit('see-all', true)
        this.$emit('hideContent')
        this.$router.push(this.seeAllCommunications)
      },
    },
  }
</script>
<style lang="scss">
</style>
