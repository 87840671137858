<template>
  <v-dialog
    v-model="visible"
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      >
        <common-trs-btn
          type="primary"
          class="white--text"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Review Document
        </common-trs-btn>
      </slot>
    </template>
    <div
      class="d-flex flex-column"
      style="width: 100%; height: 100%;"
    >
      <iframe
        id="pdf-js-viewer"
        :key="iframeKey"
        ref="pdf_frame"
        :src="`/digital-locker/pdf/web/viewer.html?file=${btoa(docUrl)}&mode=${mode}`"
        frameborder="0"
        width="100%"
        style="flex: 1"
      />
    </div>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DocumentPdfForm',
    props: {
      docUrl: {
        type: String,
      },
      mode: {
        type: String,
        default: () => 'fill-form',
      },
      initialData: {
        type: Array,
        default: () => [],
      },
      noSignButton: {
        type: Boolean,
        default: false,
      },
      noSaveForm: {
        type: Boolean,
        default: false,
      },
      initialVisible: {
        type: Boolean,
        default: false,
      },
      viewOnly: {
        type: Boolean,
        default: false,
      },
      showGuide: {
        type: Boolean,
        default: false,
      },
      guideTitle: {
        type: String,
        default: 'Document Editing',
      },
      guideText: {
        type: String,
        default: 'Please edit your document and save data.',
      },
    },
    data () {
      return {
        visible: false,
        dataList: [],
        iframeKey: 0,
      }
    },
    watch: {
      visible (v) {
        if (v) {
          this.registerWindowCallback()
        } else {
          this.removeWindowCallback()
          this.$emit('close')
        }
      },
      initialData () {
        this.iframeKey += 1
        this.handleLoad()
      },
    },
    mounted () {
      this.handleLoad()
    },
    methods: {
      handleLoad () {
        const self = this
        this.dataList = this.initialData
        this.visible = this.initialVisible
        this.webViewerLoadedHandler = (event) => {
          if (!this.$refs.pdf_frame) {
            console.log('PDF Frame not initiated yet.')
            return
          }
          this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
            event: 'data-list',
            dataList: this.dataList,
            guideInfo: {
              showGuide: this.showGuide,
              guideTitle: this.guideTitle,
              guideText: this.guideText,
            },
          }), '*')
          if (this.noSaveForm) {
            this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
              event: 'no-save-form',
            }), '*')
          }
          if (this.noSignButton) {
            this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
              event: 'no-sign-btn',
            }), '*')
          }
          if (this.viewOnly) {
            this.$refs.pdf_frame.contentWindow.postMessage(JSON.stringify({
              event: 'view-only',
            }), '*')
          }
        }
        this.windowMessageListner = message => {
          if (typeof message.data !== 'string') {
            return
          }
          try {
            const eventData = JSON.parse(message.data)
            if (['close', 'sign-pdf'].includes(eventData.event)) {
              self.visible = false
              if (eventData.event === 'close') {
                return
              }
            }
            this.$emit(eventData.event, this.objectToArray(eventData.data, eventData.requiredFields || []))
          } catch (err) {
            console.log('err: ', err)
          }
        }
      },
      registerWindowCallback () {
        window.addEventListener('message', this.windowMessageListner)
        window.addEventListener('webviewerloaded', this.webViewerLoadedHandler)
      },
      removeWindowCallback () {
        window.removeEventListener('webviewerloaded', this.webViewerLoadedHandler)
        window.removeEventListener('message', this.windowMessageListner)
      },
      objectToArray (obj, requiredFields) {
        return Object.keys(obj || {}).map(key => ({
          field: key,
          value: obj[key],
          field_flag: requiredFields.includes(key) ? 'required' : (this.initialData.find(d => d.field === key) || {}).field_flag,
        }))
      },
      btoa (data) {
        return window.btoa(data)
      },
    },
  }
</script>
