<template>
  <v-card
    v-bind="$attrs"
    class="pa-2 ma-0 elevation-0 rounded-0"
    v-on="$listeners"
  >
    <div class="ml-auto text-center">
      <div
        class="trsText--text text-body-1 text--lighten-1"
        v-text="title"
      />

      <h3 class="trsText--text text-h3 font-weight-medium">
        {{ value }}
      </h3>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialStatsCard',

    inheritAttrs: false,

    props: {
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
