// eslint-disable
import { api } from '@/api'

// initial state
const initialState = {
  stageIcons: [],
}

// getters
const getters = {
  stageIcons: state => state.stageIcons,
}

// actions
const actions = {
  async initData ({ commit }, data) {
    const stageIcons = (await api.get('data-entities/icons_stage', null, { base: 'common' })).data
    commit('setStageIcons', stageIcons.value)
  },
}

// mutations
const mutations = {
  setStageIcons (state, data) {
    state.stageIcons = data
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
