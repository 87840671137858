<template>
  <div>
    <div v-if="mode === 'edit' || mode === 'create'">
      <v-label>{{ `${inputTempl.label}${inputTempl.mandatory ? '*' :''}` }}</v-label>
      <v-row no-gutters>
        <v-col
          v-for="(line, index) in internalValue"
          :key="index"
          cols="12"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="ruleSets"
            :name="inputTempl.label"
          >
            <common-input-text-field
              v-model="internalValue[index]"
              :error-messages="errors"
              color="primary"
              validate-on-blur
              dense
            >
              <template v-slot:prepend>
                <span class="text-h4 muted indexing">{{ index+1 }}.</span>
              </template>
              <template v-slot:append-outer>
                <common-trs-btn
                  type="tertiary"
                  icon
                  @click="deleteLine(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </common-trs-btn>
              </template>
            </common-input-text-field>
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
        >
          <validation-observer
            v-slot="{ valid }"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="ruleSets"
              :name="inputTempl.label"
            >
              <common-input-text-field
                v-model="newLine"
                :error-messages="errors"
                color="primary"
                validate-on-blur
                dense
                placeholder="Type line item to add..."
                @keydown.enter="addLine"
              >
                <template v-slot:prepend>
                  <span class="text-h4 muted indexing" />
                </template>
                <template v-slot:append-outer>
                  <common-trs-btn
                    type="tertiary"
                    icon
                    :disabled="!valid"
                    @click="addLine"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </common-trs-btn>
                </template>
              </common-input-text-field>
            </validation-provider>
          </validation-observer>
        </v-col>

        <v-col
          cols="12"
        >
          <span class="input-template-comment">{{ inputTempl.comment }}</span>
        </v-col>
      </v-row>
    </div>

    <div v-if="mode === 'view'">
      <v-label>{{ `${inputTempl.label}${inputTempl.mandatory ? '*' :''}` }}</v-label>
      <v-row
        v-if="internalValue && internalValue.length > 0"
        no-gutters
      >
        <ol>
          <li
            v-for="(line, index) in internalValue"
            :key="index"
            cols="12"
          >
            {{ line }}
          </li>
        </ol>
      </v-row>
      <v-row v-else>
        <v-col>No data</v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InputTemplateText',

    props: {
      value: {
        type: Array,
      },
      inputTempl: {
        type: Object,
      },
      mode: {
        type: String,
      },
    },

    data: () => ({
      newLine: '',
    }),

    computed: {
      ruleSets () {
        const ruleSets = {}
        if (this.inputTempl.mandatory) {
          ruleSets.required = true
        }
        if (this.inputTempl.regex) {
          ruleSets.regex = RegExp(this.inputTempl.regex)
        }
        return ruleSets
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      internalValue (val) {
        if (!Array.isArray(val)) {
          this.internalValue = []
        }
      },
    },

    methods: {
      deleteLine (index) {
        this.internalValue = this.internalValue.filter((line, i) => i !== index)
      },
      addLine (event) {
        event.preventDefault()
        let val = this.internalValue
        if (!Array.isArray(val)) {
          val = []
        }
        this.internalValue = val.concat([this.newLine])
        this.newLine = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  span.indexing {
    min-width: 20px;
  }
</style>
