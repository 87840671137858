export const DR_MODES = {
  PRESENTATION: 'PRESENTATION',
  DILIGENCE: 'DILIGENCE',
}

export const DR_TYPES = {
  GENERAL: 'GENERAL',
  FUND: 'FUND',
  SPV: 'SPV',
}
