<template>
  <div class="dashboard-core-header px-2">
    <v-app-bar
      v-bind="{ ...$attrs }"
      v-on="$listeners"
    >
      <v-app-bar-title
        v-if="$route.meta.title"
        class="text-h4 font-weight-medium mx-2"
        shrink-on-scroll
        v-text="$route.meta.title"
      />
      <v-app-bar-title
        v-else
        class="text-h4 font-weight-medium mx-2"
        shrink-on-scroll
        v-text="$greeting(userProfile.avatarText)"
      />
      <v-spacer />
      <template class="position-relative">
        <!-- /Applications slot -->
        <slot
          name="applications"
        />
        <app-bar-communication-popup :is-viewer="isViewer" />
        <!-- Alerts Popup -->
        <div v-if="!isViewer && portal !== CONSTS.PORTAL.TR_ADMIN">
          <common-trs-nav-nav-popup
            :icon-name="'icon-notification-indicator trsText--text'"
            :tooltip-title="'Notifications'"
            :show-green-indicator="socketHasNewMessage"
            @click="onAlertClicked"
          >
            <template v-slot:content="{ hideContent }">
              <alerts-card
                :alerts="alerts"
                :notifications="notifications"
                :system-notifications="systemNotifications"
                :system-notification-count="systemNotificationCount"
                :see-all-alerts="seeAllAlerts"
                @hideContent="hideContent"
                @alert-selected="(item) => alertSelected(item, hideContent)"
              />
            </template>
          </common-trs-nav-nav-popup>
        </div>
        <!-- Profile -->
        <common-trs-nav-nav-popup
          :icon-name="'icon-notification-indicator trsText--text'"
          :tooltip-title="'Profile'"
        >
          <template v-slot:activator="{ menu, attrs }">
            <div
              color="primary"
              v-bind="attrs"
              class="cursor-pointer mx-3"
              :class="!profile || profile.length === 0 ? 'disable-events' : ''"
              v-on="menu"
            >
              <common-trs-nav-avatar
                :class="portal !== CONSTS.PORTAL.INVESTOR ? 'cursor-pointer' : ''"
                :profile="userProfile"
              />
            </div>
          </template>
          <template v-slot:content="{ hideContent }">
            <v-list
              v-if="profile && profile.length > 0"
            >
              <v-list-item
                v-for="(item, index) in profile"
                :key="index"
                @click="() => {
                  $router.push({ name: item.to, params: { currentTab: item.currentTab }});
                  hideContent()
                }"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </common-trs-nav-nav-popup>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
  // Utilities
  import AlertsCard from './nav/AlertsCard'
  import AppBarCommunicationPopup from './nav/AppBarCommunicationPopup'
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AlertsCard,
      AppBarCommunicationPopup,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      padding: {
        type: Number,
        default: 24,
      },
      profile: {
        type: Array,
        default: () => [],
      },
      isViewer: {
        type: Boolean,
        default: false,
        required: false,
      },
      alerts: {
        type: Array,
        default: () => [],
        required: false,
      },
      notifications: {
        type: Array,
        default: () => [],
        required: false,
      },
      systemNotifications: {
        type: Array,
        default: () => [],
        required: false,
      },
      systemNotificationCount: {
        type: Number,
        default: 0,
      },
      seeAllAlerts: {
        type: String,
        default: '',
        required: false,
      },
    },

    data: () => ({}),

    computed: {
      socketHasNewMessage () {
        return this.$store.getters['socket/hasNewMessage']
      },
      profileData () {
        return this.$store.getters['account/profile']
      },
      user () {
        return this.$store.getters['auth/user']
      },
      ...mapState(['drawer']),
      portal () {
        return this.$getPortal()
      },
      userProfile () {
        return {
          avatar: ((this.profileData.profile_details || {}).logo_url) || this.user.avatar_url,
          avatarText: `${this.profileData.first_name || ''} ${this.profileData.last_name || ''}`,
          group: '/profile',
          title: `${this.profileData.first_name} ${this.profileData.last_name}`,
          subtitle: `TID: ${this.profileData.trellis_uid}`,
          children: [
            {
              to: 'me',
              title: 'My Profile',
            },
          ],
        }
      },

    },

    methods: {
      alertSelected (item, hideContent) {
        this.emitAlert(item)
        hideContent()
      },
      ...mapMutations('app', {
        setDrawer: 'SET_DRAWER',
      }),
      emitAlert (item) {
        this.$emit('alert-selected', item)
      },
      onAlertClicked () {
        if (this.portal !== this.CONSTS.PORTAL.TR_ADMIN) {
          this.$store.commit('socket/hasReadNewMessage', null, { root: true })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dashboard-core-header {
    width: 100%;
    max-width: 1300px !important;
    border-radius: 4px !important;

    .v-app-bar {
      width: 100%;
    }
  }
  .v-list {
    .v-list-item {
      cursor: pointer!important;
    }
  }
  .disable-events {
    pointer-events: none;
  }
</style>
