export const hasPermissionRoles = (cRoles, tRoles) => {
  let hasPermission = false
  try {
    if (cRoles && Array.isArray(cRoles)) {
      cRoles.forEach(role => {
        if (tRoles.indexOf(role) !== -1) {
          hasPermission = true
        }
      })
    } else {
      hasPermission = true
    }
  } catch (err) {
    // pass
  }
  return hasPermission
}

export const sleep = (time) => Promise(resolve => setTimeout(resolve, time))

export const readAsBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export function convertDataURLtoBlob (dataurl) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function downloadAsFile (content, filename) {
  const anchor = document.createElement('a')
  anchor.href = content
  anchor.download = filename || true
  anchor.click()
}
