<template>
  <v-avatar
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <img
      v-if="url"
      :alt="alt"
      :src="url"
    >
    <span class="white--text text-h1">{{ initial }}</span>
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-avatar>
</template>

<script>
  /**
      - Extends v-avatar.
      - Show initials if avatar is empty, else show avatar image

      - Example
      <common-common-avatar
        :alt="fullName"
        :src="investor.avatar"
      />
    */
  export default {
    name: 'BaseCommonCommonAvatar',

    props: {
      alt: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        defaultAttrs: {
          size: 150,
          color: 'primary',
        },
      }
    },
    computed: {
      initial () {
        if (!this.alt) {
          return ''
        }
        return this.alt.split(' ').slice(0, 2).map(v => v.slice(0, 1)).join('')
      },
    },
    watch: {
    },

    methods: {
    },
  }
</script>

<style lang="stylus" scoped>

</style>
