<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center px-2 py-2"
      :class="headerClass"
    >
      <label
        class="mr-auto"
        :class="titleClass"
      >
        {{ title }}
      </label>
      <div class="d-flex align-center">
        <slot name="header-right" />
        <v-btn
          :disabled="!prevAvailable"
          fab
          icon
          size="35"
          small
          class="mr-3 white"
          @click="prev"
        >
          <v-icon color="primary">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!nextAvailable"
          fab
          icon
          size="35"
          small
          class="white mr-0"
          @click="next"
        >
          <v-icon color="primary">
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
    <vue-slick-carousel
      v-if="items.length > 0"
      ref="carousel"
      :arrows="false"
      v-bind="$attrs"
      :class="bodyClass"
      @afterChange="handleAfterChange"
    >
      <div
        v-for="(item, idx) of items"
        :key="idx"
      >
        <slot
          name="item"
          :item="item"
          :idx="idx"
        />
      </div>
    </vue-slick-carousel>
  </div>
</template>
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'

  export default {
    name: 'CommonCarousel',
    components: { VueSlickCarousel },
    props: {
      items: {
        type: Array,
        required: true,
      },
      titleClass: {
        type: String,
      },
      headerClass: {
        type: String,
      },
      bodyClass: {
        type: String,
      },
      title: {
        type: String,
      },
    },
    data: () => ({
      currentIdx: -1,
    }),
    computed: {
      nextAvailable () {
        return this.currentIdx + 4 < this.items.length
      },
      prevAvailable () {
        return this.currentIdx > 0
      },
    },
    methods: {
      prev () {
        this.$refs.carousel.prev()
      },
      next () {
        this.$refs.carousel.next()
      },
      handleAfterChange (idx) {
        this.currentIdx = idx
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .v-btn--fab.v-size--small {
  height: 35px;
  width: 35px;
  .v-icon {
    font-size: 16px;
  }
}
</style>
