<template>
  <v-img
    v-if="publicBranding.logo"
    class="mx-auto mb-4"
    :src="publicBranding.logo"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  />
</template>

<script>
  export default {
    name: 'AppLogo',

    props: {
    },

    data: () => ({
      defaultAttrs: {
        'max-height': 100,
        'max-width': 100,
      },
    }),

    computed: {
      publicBranding () {
        return this.$store.getters['publicBranding/s3Data']
      },
    },

    async created () {
    },
  }
</script>
