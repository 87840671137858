// eslint-disable-next-line
import theme from '@/components/core/styles/theme'

const DEFAULT_THEME = {
  themeColors: theme,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
  showImg: true,
}

// initial state
const initialState = {
  ...DEFAULT_THEME,
}

// getters
const getters = {
  theme: state => state,
}

// actions
const actions = {
}

// mutations
const mutations = {
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_SCRIM (state, payload) {
    state.barColor = payload
  },
  SET_THEME_COLORS (state, payload) {
    state.themeColors = payload
  },
  RESET (state) {
    state.themeColors = DEFAULT_THEME.themeColors
    state.barColor = DEFAULT_THEME.barColor
    state.barImage = DEFAULT_THEME.barImage
    state.showImg = DEFAULT_THEME.showImg
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
