<template>
  <common-material-snackbar
    v-model="snackbar"
    :color="message.color"
    :timeout="message.timeout"
    :top="message.y === 'top'"
    :bottom="message.y === 'bottom'"
    :right="message.x === 'right'"
    :left="message.x === 'left'"
    :multi-line="message.mode === 'multi-line'"
    :vertical="message.mode === 'vertical'"
  >
    {{ message.text }}
  </common-material-snackbar>
</template>

<script>
  /**
    * color: 'success',
    * snackbar: false,
    * y: 'bottom',
    * x: null,
    * mode: '',
    * timeout: 4000,
    * text: '',
    */
  export default {
    data () {
      return {
        snackbar: false,
        // y: 'bottom',
        // x: null,
        // mode: '',
        // timeout: 4000,
        // text: ''
      }
    },
    computed: {
      message () {
        return this.$store.getters['app/message']
      },
    },
    watch: {
      message: {
        handler (msg) {
          if (msg && msg.text) {
            this.snackbar = true
          }
        },
        // immediate: true,
      },
    },
  }
</script>

<style lang="stylus" scoped>

</style>
