import { render, staticRenderFns } from "./AlertsCard.vue?vue&type=template&id=2fe5a8e4&scoped=true&"
import script from "./AlertsCard.vue?vue&type=script&lang=js&"
export * from "./AlertsCard.vue?vue&type=script&lang=js&"
import style0 from "./AlertsCard.vue?vue&type=style&index=0&id=2fe5a8e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe5a8e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDivider,VIcon,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VSimpleTable,VTab,VTabItem,VTabs,VTabsItems})
