<template>
  <v-menu
    v-model="internalValue"
    offset-y
    class="menu-card mt-2"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{on: tooltip }">
          <slot
            name="activator"
            v-bind="{ tooltip, menu, attrs }"
          >
            <div class="p-relative">
              <div
                v-if="showGreenIndicator"
                class="green-indicator"
              />
              <common-trs-btn
                class="mx-3"
                type="tertiary"
                color="trsText"
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                @click="$emit('click')"
              >
                <v-icon>
                  {{ iconName }}
                </v-icon>
              </common-trs-btn>
            </div>
          </slot>
        </template>
        <span>{{ tooltipTitle }}</span>
      </v-tooltip>
    </template>

    <common-trs-card
      class="alert-table"
    >
      <slot
        name="content"
        v-bind="{ show: internalValue, hideContent, showContent }"
      />
    </common-trs-card>
  </v-menu>
</template>
<script>
  export default {
    name: 'NeedsAttention',

    props: {
      iconName: {
        type: String,
        required: true,
      },
      tooltipTitle: {
        type: String,
        required: true,
      },
      showGreenIndicator: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        internalValue: false,
      }
    },

    computed: {
    },

    methods: {
      showContent () {
        this.internalValue = true
      },
      hideContent () {
        this.internalValue = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .alert-table {
    border-top: 3px solid var(--v-primary-base);
    border-radius: 0px !important;
    max-width: 600px;
  }
  ::v-deep.v-menu__content {
    top: 75px !important;
    background: white;
    max-height: 500px;
    overflow-y: auto;
    background: var(--v-accent-lighten1)!important;
    z-index: 2 !important;
  }
  .green-indicator {
    position: absolute;
    top: 0px;

    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--v-success-base);
    border-radius: 50%;
    right: 25px;
    top: 16px;
    z-index: 1;
  }
</style>
