<template>
  <v-btn
    v-if="pemAllowed"
    ref="self"
    class="ma-0"
    :class="classes"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-btn>
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    name: 'BaseBtn',
    mixins: [permissionMixin],

    props: {
      type: {
        type: String,
        validate: (val) => ['primary', 'secondary', 'disabled', 'tetiary', 'danger', ''].indexOf(val) !== -1,
        default: '',
      },
      trsIcon: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
      }
    },

    computed: {
      actualType () {
        if (this.$attrs.disabled) {
          return 'disabled'
        } else {
          return this.type
        }
      },
      classes () {
        const classes = {
          [`trs-${this.actualType}-btn`]: true,
        }
        if (this.trsIcon) {
          classes['trs-icon'] = true
        }
        return classes
      },
      defaultAttrs () {
        const attrs = {}
        if (this.actualType === 'secondary') {
          attrs.outlined = true
          attrs.color = 'primary'
        }
        if (this.actualType === 'tertiary') {
          attrs.text = true
          attrs.color = 'primary'
        }
        if (this.actualType === 'danger') {
          attrs.outlined = true
          attrs.color = 'error'
        }

        if (this.trsIcon) {
          attrs.fab = true
        }
        return {
          ...attrs,
        }
      },
    },

    mounted () {
    },
  }
</script>
<style lang="scss" scoped>
  .v-btn {
    box-sizing: border-box;
    box-shadow: none;
    &.trs-icon {
      border-radius: 0px
    }
  }
  .trs-primary-btn {
    background: linear-gradient(180deg, var(--v-primary-lighten1) 0%, var(--v-primary-base) 100%) !important;
    border: 1px solid var(--v-primary-darken1);
  }
  .trs-secondary-btn {
    background: linear-gradient(180deg, #FFFFFF 0%, #EDF2F3 100%) !important;
    border: 1px solid #D7E0E2;
  }
  .trs-disabled-btn {
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background: #EDF2F3FF !important;
    }
    background: #EDF2F3FF !important;
    border: 1px solid #D7E0E2 !important;
    color: #B9C5C7 !important;
  }
  .v-btn.error--text {
    text-transform: none;
  }
  .v-sheet button.v-btn.error--text.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 12px !important;
  }
  .v-btn.primary--text {
    text-transform: none;
  }
  .v-sheet button.v-btn.primary--text.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 12px !important;
  }
  .v-btn.white--text {
    text-transform: none;
  }
  .v-sheet button.v-btn.white--text.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 12px !important;
  }
</style>
