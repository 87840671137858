<template>
  <common-trs-card
    v-bind="$attrs"
    class="v-card--dashboard pa-3 pb-0 d-flex flex-column mt-0 mb-0 rounded-0"
  >
    <div class="trs-carousel-header mx-n3 mt-n4 pa-5 font-weight-bold">
      <div>
        <slot name="heading" />
      </div>
    </div>
    <slot />
  </common-trs-card>
</template>

<script>
  export default {
    name: 'MaterialCard',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      icon: {
        type: String,
        default: undefined,
      },
    },

    computed: {
    },
  }
</script>

<style lang="sass" scoped>
  .v-card--dashboard
    &__heading
      position: relative
      top: -25px
  .v-card--dashboard
    border: 0 !important
</style>
