let envT = 'prod'

const hostName = location.hostname
if (hostName.indexOf('localhost') > -1 || hostName.indexOf('dev') > -1) {
  envT = 'dev'
} else if (hostName.indexOf('qa') > -1 || hostName.indexOf('sandbox') > -1 || hostName.indexOf('portal-uat') > -1) {
  envT = 'qa'
} else if (hostName.indexOf('uat') > -1) {
  envT = 'uat'
}

export const env = envT
