<template>
  <v-data-table
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-if="!$attrs.hasOwnProperty('hide-default-footer')"
      v-slot:footer="{ props, on }"
    >
      <common-trs-data-table-paginator
        v-bind="{ ...props, ...$attrs.footerProps }"
        v-on="on"
      />
    </template>
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template v-slot:no-data>
      <slot name="no-data" />
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: 'CommonTrsDataTable',

    props: {
      disableCustomFooter: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        defaultAttrs: {
          hideDefaultFooter: true,
          headerProps: {
            'sort-icon': 'mdi-chevron-up',
          },
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  .v-data-table {
    ::v-deep .v-data-table__wrapper > table > tbody > tr > th,
    ::v-deep .v-data-table__wrapper > table > thead > tr > th,
    ::v-deep .v-data-table__wrapper > table > tfoot > tr > th {
      font-size: 0.875rem!important;
      font-weight: 400!important;
      color: var(--v-trsText-base);
      padding-left: 10px!important;
      opacity: 0.5;
      border-bottom: none!important;
    }
    ::v-deep .v-data-table__wrapper > table > tbody > tr > td,
    ::v-deep .v-data-table__wrapper > table > thead > tr > td,
    ::v-deep .v-data-table__wrapper > table > tfoot > tr > td {
      font-size: 1rem;
      font-weight: 400;
      color: var(--v-trsText-base);
      padding-left: 10px!important;
      border-bottom: none!important;
    }
  }
</style>
