/* eslint-disable */
/************ HERE Goes the GLOBAL constants of the trellis ***********/
import { env } from '@/components/common/constants/env'

export * from "./forms";
export * from "./opportunity";
export * from "./investment";
export * from "./nda";
export * from "./payment-btn-txt";
export * from "./payment-status";
export * from "./portal-branding";
export * from "./document";
export * from "./env";
export * from "./digital-roadshow";
export * from "./form-templates";
export * from "./system-notifications";
export * from "./activities";
export * from "./s3-public-branding";
export * from "./captable";
export * from "./investors";
export * from './features'

export const BUILD_NUMBER = "0.1.0.0";

/***************ROLES*****************/
export const FID_ROLE = {
  FIDUCIARY_SUPERADMIN: "fiduciary_superadmin",
  FIDUCIARY_ADMIN: "fiduciary_admin",
  KYC_APPROVER: "kyc_approver",
  OPPORTUNITY_APPROVER: "opportunity_approver",
  DEAL_EXECUTOR: "deal_executor",
  BD_TRADER: "bd_trader",
  AUTHORIZED_SIGNATORY: "fiduciary_authorized_signatory",
  FIDUCIARY_EDITOR: "fiduciary_editor",
  FIDUCIARY_VIEWER: "fiduciary_admin_viewer",
}

export const TRELLIS_ROLE = {
  TRELLIS_SUPERADMIN: "trellis_superadmin",
  TRELLIS_ADMIN: "trellis_admin",
  TRELLIS_OPERATOR: "trellis_operator",
};

export const ISSUER_ROLE = {
  SUPERADMIN: "issuer_superadmin",
  ISSUER_ADMIN: "issuer_admin",
  ISSUER_EDITOR: "issuer_editor",
  ISSUER_APPROVER: "issuer_opportunity_approver",
  ISSUER_SIGNATORY: "issuer_authorized_signatory",
  ISSUER_VIEWER: "issuer_viewer",
  CAPTABLE_ADMIN: "captable_admin",
  CAPTABLE_EDITOR: "captable_editor",
  CAPTABLE_VIEWER: "captable_viewer",
};

export const TRELLIS_ROLES = [
  { value: TRELLIS_ROLE.TRELLIS_SUPERADMIN, text: "Trellis Superadmin" },
  { value: TRELLIS_ROLE.TRELLIS_ADMIN, text: "Trellis Admin" },
  { value: TRELLIS_ROLE.TRELLIS_OPERATOR, text: "Trellis Operator" },
];
/*******************************************/

/***************Documents*****************/

export const FR_DOCUMENT_TYPE = {
  NDA: "NDA",
  DEAL: "DEAL",
  DATA_ROOM: "DATA_ROOM",
};

export const FR_DOCUMENT_TYPES = [
  FR_DOCUMENT_TYPE.NDA,
  FR_DOCUMENT_TYPE.DEAL,
  FR_DOCUMENT_TYPE.DATA_ROOM,
];

export const FR_DOCUMENT_TYPE_TITLES = {
  NDA: "NDA Document",
  DEAL: "Deal Document",
  DATA_ROOM: "Data Room Document",
};

export const NDA_DOCUMENT_STATUS = {
  CREATED: "CREATED",
  PENDING_DIGITIZATION: "PENDING_DIGITIZATION",
  DIGITIZED: "DIGITIZED",
};

export const DEAL_DOCUMENT_STATUS = {
  CREATED: "CREATED",
  PENDING_DIGITIZATION: "PENDING_DIGITIZATION",
  DIGITIZED: "DIGITIZED",
};

export const NDA_DOCUMENT_STATUS_TEXT = {
  CREATED: "Created",
  PENDING_DIGITIZATION: "Pending Digitization",
  DIGITIZED: "Digitized",
};

export const DOCUMENT_TYPES = [
  { value: "DEAL_DOCUMENT", text: "Deal Document" },
  { value: "NDA_DOCUMENT", text: "NDA Document" },
];

export const DOCUMENT_TYPE_TEXT = {
  DEAL_DOCUMENT: "Deal Document",
  NDA_DOCUMENT: "NDA Document",
};

export const TRELLIS_ADMIN_DOCUMENT_STATUSES = [
  { value: "PENDING_DIGITIZATION", text: "Pending Digitization" },
  { value: "DIGITIZED", text: "Digitized" },
];

/*******************************************/

/*********** Invite Types ******************/

export const INVITE_TYPES = {
  ENTITY: "ENTITY",
  INDIVIDUAL: "INDIVIDUAL",
  INDIVIDUAL_FOR_ENTITY: "INDIVIDUAL_FOR_ENTITY",
};

export const INVITE_TYPES_SELECT_OPTIONS = [
  { text: "Entity", value: INVITE_TYPES.ENTITY },
  { text: "Individual", value: INVITE_TYPES.INDIVIDUAL },
  { text: "Individual for Entity", value: INVITE_TYPES.INDIVIDUAL_FOR_ENTITY },
];

export const KYC_INVITE_TYPES_SELECT_OPTIONS = [
  { text: "Entity", value: INVITE_TYPES.ENTITY },
  { text: "Individual", value: INVITE_TYPES.INDIVIDUAL },
];

export const INVITE_APPROVAL_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NA: "NA",
};
export const INVITE_APPROVAL_STATUSES = [
  { value: "", text: "All" },
  { value: "PENDING", text: "Pending Approval" },
  { value: "APPROVED", text: "Approved" },
  { value: "REJECTED", text: "Rejected" },
  { value: "NA", text: "Sent" },
];

/*******************************************/

/*********** Portals ******************/
export const PORTAL = {
  ISSUER: "issuers",
  INVESTOR: "digital-locker",
  FID_ADMIN: "fid-admin",
  TR_ADMIN: "trellis-admin",
  CAPTABLE: "acap",
};
/*******************************************/

/*********** Form Types ******************/
export const STATIC_ASSET_BASE_URL =
  "https://s3.amazonaws.com/dev.static.trellisplatform.com/icons/";

export const BUIDER_FORM_TYPE = {
  OPP_FORM: "OPP_FORM",
  ISSUER_FORM: "ISSUER_FORM",
  KYC_INDIVIDUAL: "KYC_INDIVIDUAL",
  KYC_ENTITY: "KYC_ENTITY",
  KYC_ENTITY_ONBOARDING: "KYC_ENTITY_ONBOARDING",
  USER_REQUIRED_FORMS: "USER_REQUIRED_FORMS",
  DEAL_DOCUMENT_FORM: "DEAL_DOCUMENT_FORM",
  NDA_DOCUMENT_FORM: "NDA_DOCUMENT_FORM",
  DATA_CONDUCTOR_FORM: 'DATA_CONDUCTOR_FORM',
};

export const BUIDER_FORM_TYPES = [
  { text: "None", value: "null" },
  { text: "Opportunity Forms", value: BUIDER_FORM_TYPE.OPP_FORM },
  { text: "Issuer Form", value: BUIDER_FORM_TYPE.ISSUER_FORM },
  { text: "KYC Individual Form", value: BUIDER_FORM_TYPE.KYC_INDIVIDUAL },
  { text: "KYC Entity Form", value: BUIDER_FORM_TYPE.KYC_ENTITY },
  {
    text: "KYC Entity Onboarding Form",
    value: BUIDER_FORM_TYPE.KYC_ENTITY_ONBOARDING,
  },
  { text: "Deal Document Form", value: BUIDER_FORM_TYPE.DEAL_DOCUMENT_FORM },
  { text: "NDA Document Form", value: BUIDER_FORM_TYPE.NDA_DOCUMENT_FORM },
  { text: "User Required Forms", value: BUIDER_FORM_TYPE.USER_REQUIRED_FORMS },
  { text: "Data Conductor Forms", value: BUIDER_FORM_TYPE.DATA_CONDUCTOR_FORM },
];
/*******************************************/

export const ENTITY_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  ENTITY: "ENTITY",
};

export const FID_ADMIN_ENTITY_TYPES_SELECT_OPTIONS = [
  { text: "All", value: null },
  { text: "Primary invites", value: true },
];

export const DOCUSIGN_EXPIRE_TIME = 15 * 60 * 1000; // 15 minutes

export const DATATABLE_DEFAULT_PAGINATION = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "All", value: 10000000 },
];

export const TERMS_STORAGE_KEY = "terms-agreed";

export const TRADE_HOST =
  location.hostname === "portal.clearlist.com"
    ? "https://trade.clearlist.com/market"
    : "https://trade-uat.clearlist.com/market";

export const TRADE_HOST_BY_DOMAIN = {
  'portal.clearlist.com': 'https://trade.clearlist.com/market',
  'portal-uat.clearlist.com': 'https://trade-uat.clearlist.com/market',
  'portal.sharenett.com': 'https://trade.sharenett.com/market',
  'sandbox.sharenett.com': 'https://trade.sharenett.com/market',
}
export const TRADE_HOST_BY_DOMAIN_RESULT = TRADE_HOST_BY_DOMAIN[location.hostname] || 'https://trade.clearlist.com/market'

export const FILE_TYPES = {
  DOCUMENT: "DOCUMENT",
  IMAGE: "IMAGE",
  DATA: "DATA",
  XLXS: 'XLXS',
  ZIP: "ZIP",
  PDF_IMAGE: 'PDF_IMAGE',
  ALL_DOCS: "ALL_DOCS",
  CSV: "CSV",
};

export const FILE_TYPES_DISPLAY = [
  { text: 'Document', value: FILE_TYPES.DOCUMENT, exts: 'PDF' },
  { text: 'Image', value: FILE_TYPES.IMAGE, exts: 'JPEG / PNG' },
  { text: 'Data', value: FILE_TYPES.DATA, exts:  'XLXS / CSV' },
  { text: 'XLXS', value: FILE_TYPES.XLXS, exts:  'XLXS' },
  { text: 'Zip', value: FILE_TYPES.ZIP, exts: 'ZIP' },
  { text: 'PDF and Image', value: FILE_TYPES.PDF_IMAGE, exts: 'PDF / JPG / PNG' },
  { text: 'All Docs', value: FILE_TYPES.ALL_DOCS, exts: 'PDF / XLXS' },
  { text: 'CSV', value: FILE_TYPES.CSV, exts: 'CSV' },
];

export const FILE_TYPE_DEFAULT = { text: 'Document', value: FILE_TYPES.DOCUMENT, exts: 'PDF' };

export const FILE_MIME_TYPE_DEFAULT = {
  type: FILE_TYPES.DOCUMENT,
  value: [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ],
};

export const FILE_MIME_TYPES = [
  {
    type: FILE_TYPES.DOCUMENT,
    value: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ],
  },
  { type: FILE_TYPES.IMAGE, value: ["image/jpeg", "image/png"] },
  {
    type: FILE_TYPES.DATA,
    value: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  { type: FILE_TYPES.ZIP, value: ["application/x-zip-compressed", 'application/zip'] },
  {
    type: FILE_TYPES.ALL_DOCS,
    value: [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "video/mp4",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
      "image/jpeg",
      "image/png",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  {
    type: FILE_TYPES.XLXS,
    value: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]
  },
  {
    type: FILE_TYPES.PDF_IMAGE,
    value: [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ]
  },
  {
    type: FILE_TYPES.CSV,
    value: [
      "text/csv",
    ],
  },
];

export const TRELLIS_URLS = {
  prod: 'https://identity.trellisplatform.com',
  uat: 'https://ims-uat-identity.trellisplatform.com',
  qa: 'https://ims.qa.trellisplatform.com',
  dev: 'https://ims.dev.trellisplatform.com',
}

export const CAPTABLE_HOST = `${location.origin}/acap`
export const ISSUER_CAPTABLE = `${location.origin}/issuers/captable`
export const ACCOUNT_SERVICE = `${location.origin}/account/my-services`
export const MY_DE_PORTAL = `${TRELLIS_URLS[env]}`
export const DL_PORTAL = `${location.origin}/digital-locker/portfolios`

export const STATIC_COMMUNICATION_FAQ_URL =
  "https://s3.amazonaws.com/dev.trellisplatform.com-branding/TrellisFaq.json";

export const MONTH_NAMES = [
	{
		"abbreviation": "Jan",
		"name": "January"
	},
	{
		"abbreviation": "Feb",
		"name": "February"
	},
	{
		"abbreviation": "Mar",
		"name": "March"
	},
	{
		"abbreviation": "Apr",
		"name": "April"
	},
	{
		"abbreviation": "May",
		"name": "May"
	},
	{
		"abbreviation": "Jun",
		"name": "June"
	},
	{
		"abbreviation": "Jul",
		"name": "July"
	},
	{
		"abbreviation": "Aug",
		"name": "August"
	},
	{
		"abbreviation": "Sep",
		"name": "September"
	},
	{
		"abbreviation": "Oct",
		"name": "October"
	},
	{
		"abbreviation": "Nov",
		"name": "November"
	},
	{
		"abbreviation": "Dec",
		"name": "December"
	}
]