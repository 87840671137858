<template>
  <common-item-group
    v-if="pemAllowed"
    :item="item"
    text
    sub-group
  />
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    name: 'ItemSubGroup',
    mixins: [permissionMixin],

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
    },
  }
</script>
