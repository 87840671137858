let API_URL = 'https://owner-api.stg.cyndxapi.com'

if (location.hostname.startsWith('portal')) {
  API_URL = 'https://owner-api.cyndxapi.com'
}

export const CYNDX_BASE_URL = API_URL // 'https://owner-api.prd-cdx.cyndx.dev'

export const CYNDX_CLIENT_DATA_TOKEN = 'CIS2yQEIorbJAQjBtskBCKmdygEInYfLAQiWocsBCJ2IzQE='

export const USER_ROLES = {
  TAPP_ADMIN: 'tapp_admin',
  CAPTABLE_ADMIN: 'captable_admin',
  CAPTABLE_EDITOR: 'captable_editor',
  CAPTABLE_VIEWER: 'captable_viewer',
  CAPTABLE_SUPER_ADMIN: 'captable_super_admin',
  HOLDER: 'holder',
}

export const ISSUER_CAPTABLE_ROLES = [
  USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_EDITOR, USER_ROLES.CAPTABLE_SUPER_ADMIN, USER_ROLES.CAPTABLE_VIEWER,
]

export const FINANCING_ROUND_FUNDING_TYPES = ['Pre-Seed', 'Angel Round', 'Seed Round', 'Venture Round', 'Bridge Round', 'Mezzanine Round', 'Other']

export const SECURITIES_PREFIX = {
  COMMON: 'CS',
  PREFERRED: 'PS',
  WARRANT: 'WA',
  DEBT: 'CN',
  CONVERTIBLE: 'CN',
  SAFE: 'SAFE',
  OPTION: 'OP',
}

export const SHARE_TERM_TYPES = {
  COMMON: 'common',
  PREFERRED: 'preferred',
  WARRANT: 'warrant',
  CONVERTIBLE: 'convertible',
  OPTION: 'option',
}
export const SUMMARY_FILTER_SECURITIES_TYPES = [
  {
    id: 'common',
    value: 'Common',
  },
  {
    id: 'preferred',
    value: 'Preferred',
  },
  {
    id: 'convertible',
    value: 'Convertibles',
  },
  {
    id: 'option',
    value: 'Restricted shares',
  },
]
export const HOLDERS_FILTER_SECURITIES_TYPES = [
  {
    id: 'common',
    value: 'Common Shares',
  },
  {
    id: 'preferred',
    value: 'Preferred Shares',
  },
  {
    id: 'warrant',
    value: 'Warrant',
  },
  {
    id: 'option',
    value: 'Options',
  },
]
export const CONVERTIBLE_TERM_TYPES = {
  DEBT: 'debt',
  SAFE: 'safe',
}

export const OPTIONS_TYPE = {
  ISO: 'iso',
  NSO: 'nso',
  ISONSO: 'iso_nso',
}

export const CONVERTIBLE_TERM_TYPES_TITLE = {
  DEBT: 'Convertible Note',
  SAFE: 'safe',
}

export const CONVERTIBLE_TYPES_LIST = [
  {
    title: 'Convertible Note',
    value: CONVERTIBLE_TERM_TYPES.DEBT,
  },
  {
    title: 'Safe',
    value: CONVERTIBLE_TERM_TYPES.SAFE,
  },
]

export const ALL_SECURITIES_PREFIX_LIST = [
  {
    type: SHARE_TERM_TYPES.COMMON,
    prefix: SECURITIES_PREFIX.COMMON,
  },
  {
    type: SHARE_TERM_TYPES.PREFERRED,
    prefix: SECURITIES_PREFIX.PREFERRED,
  },
  {
    type: SHARE_TERM_TYPES.WARRANT,
    prefix: SECURITIES_PREFIX.WARRANT,
  },
  {
    type: CONVERTIBLE_TERM_TYPES.DEBT,
    prefix: SECURITIES_PREFIX.DEBT,
  },
  {
    type: CONVERTIBLE_TERM_TYPES.SAFE,
    prefix: SECURITIES_PREFIX.SAFE,
  },
  {
    type: SHARE_TERM_TYPES.OPTION,
    prefix: SECURITIES_PREFIX.OPTION,
  },
]

export const SECURITIES_PREFIX_LIST = [
  {
    type: SHARE_TERM_TYPES.COMMON,
    prefix: SECURITIES_PREFIX.COMMON,
  },
  {
    type: SHARE_TERM_TYPES.PREFERRED,
    prefix: SECURITIES_PREFIX.PREFERRED,
  },
  {
    type: SHARE_TERM_TYPES.WARRANT,
    prefix: SECURITIES_PREFIX.WARRANT,
  },
  {
    type: SHARE_TERM_TYPES.OPTION,
    prefix: SECURITIES_PREFIX.OPTION,
  },
]

export const CONVERTIBLE_PREFIX_LIST = [
  {
    type: CONVERTIBLE_TERM_TYPES.DEBT,
    prefix: SECURITIES_PREFIX.DEBT,
  },
  {
    type: CONVERTIBLE_TERM_TYPES.SAFE,
    prefix: SECURITIES_PREFIX.SAFE,
  },
]

export const INTERST_TYPES = [
  {
    title: 'Simple',
    value: 'simple',
  },
  {
    title: 'Compound',
    value: 'compound',
  },
]

export const INTEREST_PERIOD = [
  {
    title: 'Daily',
    value: 'daily',
  },
  {
    title: 'Weekly',
    value: 'weekly',
  },
  {
    title: 'Monthly',
    value: 'monthly',
  },
  {
    title: 'Quarterly',
    value: 'quarterly',
  },
  {
    title: 'Semi Annually',
    value: 'semi_annually',
  },
  {
    title: 'Annually',
    value: 'annually',
  },
]

export const SHARE_TERMS_TYPE = [
  {
    type: 'common',
    name: 'Common Shares',
  },
  {
      type: 'preferred',
      name: 'Preferred Shares',
  },
]

export const INTREST_PRIORITY_TYPES = [
  {
    name: 'Stacked',
    type: 'stacked',
  },
  {
    name: 'Blended/Pari Passu',
    type: 'blended',
  },
]

export const USER_TYPES = {
  Existing: 'existing',
  New: 'new',
}

export const ISSUED_SECURITY_ACTIONS = {
  TRANSFER: 'transfer',
  MODIFY: 'modify',
  CANCEL: 'cancel',
  DELETE: 'delete',
  REPURCHASE: 'repurchase',
  EXERCISE: 'exercise',
  TERMINATE: 'terminate',
  VESTING: 'vesting',
  VIEW: 'view',
}

export const ISSUED_SHARE_TRANSFER_BASIS_TYPES = {
  '409A': 'valuation_409a',
  LAST_PRICE_ROUND: 'valuation_last_priced_round',
  OTHER: 'valuation_other',
}

export const ISSUED_SHARE_TRANSFER_BASIS_LIST = [
  {
    text: '409 A Valuation',
    value: ISSUED_SHARE_TRANSFER_BASIS_TYPES['409A'],
  },
  {
    text: 'Last Price Round',
    value: ISSUED_SHARE_TRANSFER_BASIS_TYPES.LAST_PRICE_ROUND,
  },
  {
    text: 'Other',
    value: ISSUED_SHARE_TRANSFER_BASIS_TYPES.OTHER,
  },
]

export const VESTING_TYPE = {
  TIME_BASED: 'time-based',
  CUSTOM: 'custom',
}

export const VESTING_PERIOD_TYPE = [
  { text: 'months', value: 'monthly' },
  { text: 'quarters', value: 'quarterly' },
  { text: 'periods', value: 'bimonthly' },
  { text: 'periods', value: 'semi_annually' },
  { text: 'years', value: 'annually' },
]

export const VESTING_TYPES = [
  {
    value: VESTING_TYPE.CUSTOM,
    title: 'Custom',
  },
  {
    value: VESTING_TYPE.TIME_BASED,
    title: 'Time-Based',
  },
]

export const VESTING_PERIOD_TYPE_SELECT = [
  { text: 'Monthly for', value: 'monthly' },
  { text: 'Quarterly for', value: 'quarterly' },
  { text: 'Bimonthly for', value: 'bimonthly' },
  { text: 'Semiannually for', value: 'semi_annually' },
  { text: 'Annually for', value: 'annually' },
]

export const YES_NO_RADIO = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
]

export const CLIFF_VESTING_TYPES = {
  YES: 'Yes',
  NO: 'No',
}

export const GRACE_PERIODS = {
  Death: 'death',
  Disability: 'disability',
  'Involuntary termination': 'involuntary_termination',
  Retirement: 'retirement',
  'Termination with cause': 'termination_with_cause',
  'Voluntary termination': 'voluntary_termination',
}

export const GRACE_PERIOD_DURATION_TYPE_LIST = [
  {
    title: 'Day',
    value: 'day',
  },
  {
    title: 'Month',
    value: 'month',
  },
  {
    title: 'Year',
    value: 'year',
  },
]

export const NOTES_DOCUMENT_API_TYPE = {
  SHARES: 'shares',
  WARRANTS: 'warrants',
  CONVERTIBLES: 'convertibles',
  OPTIONS: 'options',
  SHARE_TERMS: 'share-terms',
  WARRANT_TERMS: 'warrant-terms',
  CONVERTIBLE_TERMS: 'convertible-terms',
  EQUITY_INCENTIVE_PLANS: 'equity-incentive-plans',
  FINANCING_ROUNDS: 'financing-rounds',
}

export const DRAFTED_SECURITY_STATUS = {
  VALID: 'Ready to be issued',
  INVALID: 'Missing Information',
}

export const DRAFTED_SECURITY_STATUS_LIST = [
  {
    title: DRAFTED_SECURITY_STATUS.VALID,
    value: DRAFTED_SECURITY_STATUS.VALID,
  },
  {
    title: DRAFTED_SECURITY_STATUS.INVALID,
    value: DRAFTED_SECURITY_STATUS.INVALID,
  },
]

export const DEBT_CONVERTIBLE_COVERAGE_TYPES = [
  {
    title: 'Principal',
    value: 'principal',
  },
  {
    title: 'Principal and interest',
    value: 'principal_and_interest',
  },
]

export const ISSUER_SECURITY_CERTIFICATE_NAME = 'security_certificate.png'

export const SECURITY_CERTIFICAT_TEMPLATE_NAME = 'certificate_template.png'

export const CAPTABLE_API_KEYS = {
  dev: '8ZumOa5ai3rNorT32cBzdpT89HuMiJpKZ-HbUgWy8XHjwu5z9wd4Sw',
  qa: '0VMTENq1D2aJzoKwdnS7PTiXkdTq24yCpMZHvJgQeHhv5mlrKSOf2A',
  uat: 'dULJkO7085_iOD3QMI6OudxOsKxBgNMIUHT2ekIEHYAex3MBUYovzQ',
  prod: 'vBfHbocqfrf7SuuuIKJgbdqVKnWFRWSwOd5p5yWMO5pvxvRiT6Ty7Q',
}

export const CAPTABLE_SOURCES_API = {
  DL: 'tenant',
  ISSUER: 'issuer',
  V2: 'v2',
}

export const CAPTABLE_SOURCES_TYPES = {
  DL: 'DL',
  ISSUER: 'issuer',
  V2: 'TAPP',
}

export const CAPTABLE_SERVICE_REQUEST_STATUS = {
  APPROVED: 'APPROVED',
  CREATED: 'CREATED',
  REJECTED: 'REJECTED',
}

export const SERVIC_REQUEST_STATUS_LABELS = {
  APPROVED: 'Approved',
  CREATED: 'Created',
  REJECTED: 'Rejected',
}
