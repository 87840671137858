<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      lg="6"
      md="6"
    >
      <common-input-inline-date-picker
        v-model="dateValue"
        single-line
        :min-date="minDate"
      />
    </v-col>
    <v-col
      cols="12"
      lg="5"
      md="5"
      class="offset-0 offset-md-1"
    >
      <common-input-inline-time-picker
        v-model="timeValue"
        single-line
      />
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'BaseCommonInlineDatePicker',

    components: {
    },

    props: {
      value: {
        type: String,
        default: '',
      },
      minDate: {
        type: String,
        default: '',
        required: false,
      },
    },

    data () {
      return {
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      dateValue: {
        get () {
          return moment(this.internalValue).isValid()
            ? moment(this.internalValue).format('YYYY-MM-DD')
            : ''
        },
        set (val) {
          this.internalValue = moment(`${val} ${this.timeValue}`, 'YYYY-MM-DD HH:mm').toISOString()
        },
      },
      timeValue: {
        get () {
          return moment(this.internalValue).isValid()
            ? moment(this.internalValue).format('HH:mm')
            : ''
        },
        set (val) {
          this.internalValue = moment(`${this.dateValue} ${val}`, 'YYYY-MM-DD HH:mm').toISOString()
        },
      },
    },

    watch: {
    },

    methods: {
    },
  }
</script>
