import { FORM_BRANCH_EXPR, CAPTABLE_FEATURE_ROLES } from '@/constants'
import axios from 'axios'

export function branchCompare (lvalue, rvalue, expr) {
  let valid = false

  if (expr === FORM_BRANCH_EXPR.EQ) {
    if (lvalue === rvalue) {
      valid = true
    }
  }
  return valid
}

export function composeCaptableGetQuery (options) {
  const { sortDesc, sortBy, page = 1, itemsPerPage = 10, filters, query } = options
  const params = {
    page,
    limit: itemsPerPage,
  }

  if (sortDesc && sortDesc.length === 1) {
    params.ordering = `${sortDesc[0] ? '' : '-'}${sortBy[0]}`
  }

  if (filters) {
    for (const key in filters) {
      params[key] = filters[key]
    }
  }
  if (query && Array.isArray(query)) {
    params.query = `{${query.join(', ')}}`
  }

  return params
}

export function composeGetQuery (options) {
  const { sortDesc, sortBy, page = 1, itemsPerPage = 10, filters, query } = options
  const params = {
    page,
    limit: itemsPerPage,
  }

  if (sortDesc && sortDesc.length === 1) {
    params.ordering = `${sortDesc[0] ? '' : '-'}${sortBy[0]}`
  }

  if (filters) {
    for (const key in filters) {
      params[key] = filters[key]
    }
  }
  if (query && Array.isArray(query)) {
    params.query = `{${query.join(', ')}}`
  }

  return params
}

export function getFullLink (link) {
  return `${window.location.origin}/trellis-admin${link}`
}

export function downloadAsFile (content, filename) {
  const anchor = document.createElement('a')
  anchor.href = content
  anchor.target = '_blank'
  anchor.download = filename
  anchor.click()
}

export async function downloadAsWithAxios (content, filename) {
  const response = await axios.get(content, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
  })
  const a = document.createElement('a')
  const url = window.URL.createObjectURL(response.data)
  a.href = url
  a.download = filename
  a.click()
}

export function readFileContent (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.readAsText(file)
  })
}

export function getFeaturePermission (feature, roles) {
  let flag = false
  const currentFeature = CAPTABLE_FEATURE_ROLES.find((f) => f.featureName === feature)
  if (currentFeature && roles) {
    for (const item of roles) {
      if (currentFeature && currentFeature?.allowedRoles?.includes(item)) {
        flag = true
        break
      }
    }
  }
  return flag
}
