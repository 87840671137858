<template>
  <tr>
    <slot name="table-content">
      <td>
        {{ $date(document.modified_on) }}
      </td>
      <td v-if="oppDocument">
        <router-link
          class="font-weight-medium"
          :to="{ name: 'DigitalRoadshow.Landing', params: { id: document.opportunity_id }, query: { mode: CONSTS.DR_MODES.PRESENTATION } }"
          target="_blank"
        >
          {{ document.opportunity_name }}
        </router-link>
      </td>
      <td
        v-else
      >
        {{ document.document_name || document.document_type }}
      </td>
      <td
        v-if="!(tab === 0)"
      >
        {{ CONSTS.DOCUMENT_TYPES_TEXT[document.document_type] }}
      </td>
      <td>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <common-trs-btn
              v-if="showViewBtn && !selecteddocument"
              text
              icon
              type="secondary"
              class="primary--text rounded-sm mr-2"
              :loading="loading || docLoading"
              @click="loadDocument(document)"
              v-on="tooltip"
            >
              <v-icon>mdi-eye</v-icon>
            </common-trs-btn>
          </template>
          <span>{{ document.signed_document_id ? 'View Signed' : 'View' }}</span>
        </v-tooltip>
        <common-common-preview-file-modal
          v-if="showViewBtn && selecteddocument && selecteddocument.signed_url"
          fullscreen
          :initial-visible="true"
          :title="CONSTS.DOCUMENT_TYPES_TEXT[document.document_type]"
          :url="selecteddocument.signed_url"
          :mime-type="selecteddocument.mime_type"
          :has-download-btn="true"
          :description="selecteddocument.description"
        >
          <template v-slot:activator="{ on }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <common-trs-btn
                  text
                  small
                  icon
                  type="secondary"
                  class="primary--text rounded-sm"
                  :loading="loading || docLoading"
                  v-on="{ ...on, ...tooltip }"
                >
                  <v-icon>mdi-eye</v-icon>
                </common-trs-btn>
              </template>
              <span>{{ document.signed_document_id ? 'View Signed' : 'View' }}</span>
            </v-tooltip>
          </template>
        </common-common-preview-file-modal>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <common-trs-btn
              v-if="showSignBtn"
              text
              icon
              type="primary"
              class="white--text rounded-sm ml-2"
              :loading="loading || signLoading"
              @click="fidDocSign(document)"
              v-on="tooltip"
            >
              <v-icon>
                mdi-draw
              </v-icon>
            </common-trs-btn>
          </template>
          <span>Sign</span>
        </v-tooltip>
      </td>
    </slot>
  </tr>
</template>

<script>
  import { api } from '@/api'

  export default {
    name: 'CommonDocumentListItemV2',

    props: {
      document: {
        type: Object,
        default: () => {},
      },
      loading: {
        type: Boolean,
      },
      type: {
        type: String,
        default: '',
      },
      tab: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      selecteddocument: null,
      docLoading: false,
      signLoading: false,
    }),
    computed: {
      oppDocument () {
        return !!this.document.opportunity_id && !!this.document.opportunity_name
      },
      showSignBtn () {
        return this.document.action_required === this.CONSTS.DOC_ACTION_REQUIRED.SIGNATURE_REQUIRED && !this.document.signed_document_id
      },
      showViewBtn () {
        if (this.type !== 'fid-document') { return true }
        if (!this.document.action_required) { return true }
        if (this.document.signed_document_id) { return true }
        if (this.document.document_id) { return true }
        if (this.document.action_required === this.CONSTS.DOC_ACTION_REQUIRED.REVIEW_REQUIRED) { return true }
        return false
      },
    },
    methods: {
      async fidDocSign (document) {
        this.signLoading = true
        try {
          const apiResponse = await api.get(`fiduciary-documents/${document.doc_id}/esign?entity_uid=${document.entity ? document.entity.trellis_uid : ''}`, null)
          console.log('apiResponse: ', apiResponse.data.url)
          this.$store.dispatch('app/loading', 'Redirecting...', { root: true })
          window.open(apiResponse.data.url, '_self')
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to sign document',
          })
        }
        this.signLoading = false
      },
      async loadDocument (document) {
        this.docLoading = true
        try {
          let apiResponse = null
          switch (this.type) {
            case 'my-document':
              apiResponse = await api.get(`mydocuments/${document.doc_id}`, null, { base: 'tenant' })
              this.selecteddocument = apiResponse.data
              break
            case 'fid-document':
              apiResponse = document.signed_document_id
                ? await api.get(`mydocuments/${document.signed_document_id}`, null, { base: 'tenant' })
                : await api.get(`fiduciary-documents/${document.doc_id}?entity_uid=${document.entity ? document.entity.trellis_uid : ''}`, null)
              this.selecteddocument = document.signed_document_id
                ? apiResponse.data
                : apiResponse.data.fiduciary_document
              this.selecteddocument.description = apiResponse.data.description
              break
            case 'inv-document':
              apiResponse = await api.get(`investors/document/${document.doc_id}`, null, { base: 'tenant' })
              this.selecteddocument = apiResponse.data
              break
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load document',
          })
        }
        this.docLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
 ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   padding: 0px 0px;
 }
</style>
