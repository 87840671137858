import { env } from '@/components/common/constants/env'
export * from '@/components/common/constants'

export const TRELLIS_APIS = {
  prod: 'https://identity.trellisplatform.com/api',
  uat: 'https://ims-uat-identity.trellisplatform.com/api',
  qa: 'https://v2-qa.trellisplatform.com/api',
  dev: 'https://v2-dev.trellisplatform.com/api',
}

export const V1_API_BASE_URL = location.hostname === 'localhost'
  ? 'https://dev.trellisplatform.com/api/v1'
  : `${location.origin}/api/v1`

export const V2_API_BASE_URL = location.hostname === 'localhost' ? TRELLIS_APIS.dev : TRELLIS_APIS[`${env}`]

export const API_DEFAULT_SERVICE = 'captable-service'

export const FINANCING_ROUND_FUNDING_TYPES = ['Pre-Seed', 'Angel Round', 'Seed Round', 'Venture Round', 'Bridge Round', 'Mezzanine Round', 'Other']

export const INVITE_STATUS_TEXT = {
  PENDING: 'Pending',
  CREATED: 'Created',
  COMPLETED: 'Completed',
}

export const INVITE_STATUS_TYPES = [
  { text: 'Created', value: 'CREATED' },
  { text: 'Pending', value: 'PENDING' },
  { text: 'Completed', value: 'COMPLETED' },
]

export const BUILD_NUMBER = '1.0.3'
