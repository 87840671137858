<template>
  <v-dialog
    v-if="pemAllowed"
    v-model="internalValue"
    :max-width="400"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <validation-observer
      v-slot="{ valid }"
    >
      <common-trs-modal-card>
        <common-trs-modal-card-title>
          <common-trs-modal-card-title-content>
            {{ header }}
          </common-trs-modal-card-title-content>
          <v-spacer />

          <common-trs-btn
            aria-label="Close"
            type="secondary"
            class="rounded-0"
            color="primary"
            size="large"
            icon
            text
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </common-trs-btn>
        </common-trs-modal-card-title>

        <common-trs-modal-card-text>
          <div class="text-center ma-3">
            <div
              class="mb-5"
              style="word-break: break-word;"
            >
              {{ message }}
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Reason"
            >
              <common-input-text-field
                v-model="reason"
                :error-messages="errors"
                color="primary"
                validate-on-blur
                dense
                label="Reason"
              />
            </validation-provider>
          </div>
        </common-trs-modal-card-text>
        <common-trs-modal-card-actions>
          <common-trs-btn
            class="mr-3 primary--text"
            type="secondary"
            text
            @click="close"
          >
            Close
          </common-trs-btn>

          <common-trs-btn
            :disabled="!valid"
            type="primary"
            text
            class="white--text"
            @click="onYes"
          >
            Ok
          </common-trs-btn>
        </common-trs-modal-card-actions>
      </common-trs-modal-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    mixins: [permissionMixin],

    props: {
      message: {
        type: String,
        required: true,
      },
      header: {
        type: String,
        required: false,
        default: '',
      },
    },

    data () {
      return {
        internalValue: false,
        reason: '',
      }
    },

    computed: {
    },

    watch: {
      internalValue (val) {
        if (val) {
          this.reason = ''
        }
      },
    },

    methods: {
      close (ev) {
        this.internalValue = false
        this.$emit('close', ev)
      },
      onYes (ev) {
        this.internalValue = false
        this.$emit('decline', this.reason)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog .v-card .v-card__title .v-icon.header-icon {
    top: 0px;
    left: 0px;
  }
</style>
