<template>
  <common-trs-card
    class="mb-2 d-block header-container px-6 pt-6"
    elevation="0"
    :color="color"
  >
    <div class="d-flex py-3 align-center pa-0">
      <common-trs-btn
        v-if="hasBackButton"
        text
        color="#009F46"
        type="tertiary"
        class="back-btn"
        x-small
        @click="goBack"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </common-trs-btn>
      <div
        class="d-flex pa-2 flex-fill align-start flex-column flex-md-row align-md-center justify-space-between"
        no-gutters
      >
        <slot
          name="title"
          :parsedTitle="parsedTitle.value"
        >
          <v-tooltip
            v-if="parsedTitle.tooltip"
            bottom
          >
            <template v-slot:activator="{ on }">
              <span
                class="mx-3 text-h4 display-md-2 flex-fill overflow-limit"
                v-on="on"
              >
                {{ parsedTitle.value }}
              </span>
            </template>
            <span>{{ parsedTitle.tooltipMessage }}</span>
          </v-tooltip>
          <div
            v-else
            class="trsText--text text-h3 font-weight-bold"
          >
            {{ parsedTitle.value }}
          </div>
        </slot>
        <div
          class="d-flex justify-end align-self-end"
        >
          <slot name="actions" />
        </div>
      </div>
    </div>
    <slot name="append" />
  </common-trs-card>
</template>

<script>
  /**
    <common-app-page-header
      :title="$getFileNameFromSignedUrl(value.document.signed_url)"
      @goBack="goBack"
    >
      <template v-slot:title>
        <common-trs-btn>
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </common-trs-btn>
      </template>
      <template v-slot:actions>
        <common-trs-btn>
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </common-trs-btn>
      </template>
    </common-app-page-header>
    */
  export default {
    name: 'PageHeader',

    props: {
      hasBackButton: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
      },
      color: {
        type: String,
        default: 'rgb(242, 242, 242)',
      },
    },

    data: () => ({
    }),

    computed: {
      parsedTitle () {
        if (!this.title) {
          return {
            tooltip: false,
            value: '',
          }
        }
        const limit = 50
        if (this.title.length > limit) {
          return {
            tooltip: true,
            value: this.title.slice(0, 50) + '...',
            tooltipMessage: this.title,
          }
        } else {
          return {
            tooltip: false,
            value: this.title,
          }
        }
      },
    },

    watch: {
    },

    created () {
    },

    methods: {
      goBack () {
        if (this.$listeners && this.$listeners.goBack) {
          this.$listeners.goBack()
        } else {
          this.$router.back()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-card {
    display: flex;
    align-items: center;
  }
  .v-btn.back-btn {
    min-height: 50px;
    width: 50px;
    padding: 0px !important;
  }
  .overflow-limit {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    // white-space: nowrap;
  }
  .header-container{
    height: 78px;
  }
</style>
