<template>
  <div
    style="box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); height: 100%"
    class="mr-2 py-4"
  >
    <draggable
      v-model="internalItems"
      style="width: 100%;"
      :options="{ handle:'.drag-handle' }"
      @change="onMove"
    >
      <v-col
        v-for="(item, index) in internalItems"
        :key="index"
        cols="12"
        class="pa-0 draggable-item py-3 px-4 d-flex justify-space-between align-center cursor-pointer"
        :class="{
          'primary--text': index === internalValue
        }"
        :style="{
          'background' : index === internalValue ? backgroundColor : 'transparent'
        }"
        @click="internalValue = index"
      >
        <div
          class="muted indexing d-flex"
        >
          <v-icon
            v-if="showDrag"
            class="drag-handle mr-2"
            :class="{
              'primary--text': index === internalValue
            }"
          >
            mdi-drag
          </v-icon>
          <span v-line-clamp="1"><slot
            name="label"
            v-bind="{ item }"
          /></span>
        </div>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <common-trs-btn
              v-if="showDeleteItem"
              type="tertiary"
              icon
              v-on="on"
              @click="deleteItem($event, index)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </common-trs-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </v-col>
    </draggable>
    <slot name="actions" />
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    name: 'CoreLeftSideNav',

    components: {
      draggable,
    },

    props: {
      value: {
        type: Number,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      showDrag: {
        type: Boolean,
        default: false,
      },
      showDeleteItem: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalItems: [],
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      primaryColor () {
        return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].primary
      },
      backgroundColor () {
        return this.primaryColor + '33'
      },
    },

    watch: {
      items: {
        immediate: true,
        handler (val) {
          if (val) {
            this.internalItems = [...this.items]
            if (this.items.length > 0) {
              this.internalValue = 0
            }
          }
        },
      },
    },

    async created () {
    },

    methods: {
      onMove () {
        this.$emit('save', this.internalItems)
      },
      deleteItem (ev, index) {
        ev.stopPropagation()
        this.$emit('deleteItem', index, this.internalItems[index])
      },
      close () {
        this.internalDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .drag-handle {
    cursor: move;
    cursor: -webkit-grabbing;
  }
</style>
