export const DEFAULT_S3_DATA = {
  drOptions: {
    hideThumb: true,
    hideContact: true,
    poweredByText: 'Powered by Trellis Software Inc.',
    isserTab: {
      captable: {
        show: true,
      },
    },
    showIndicateInterestBtn: true,
    overviewTab: {
      companyDescriptionTitle: 'Company Description',
      showMap: true,
      showCompanyStage: true,
      showPreMoney: true,
      showPostMoney: true,
      showStartDate: true,
      showEndDate: true,
      showVideoTitle: false,
      videoSectionTitle: '',
    },
  },
}
