/* eslint-disable */
export const FID_ADMIN_INVESTMENT_STATUS_TEXT = {
  CREATED: 'Created',
  IOI_SENT: 'IOI Sent',
  DEAL_SIGN_IN_PROCESS: 'Deal Sign in Progress',
  DEAL_PENDING_REVIEW: 'Waiting for approval',
  DEAL_SIGNED: 'Deal Signed',
  DEAL_DECLINED: 'Deal Declined',
  DEAL_APPROVED: 'Deal Approved',
  DEAL_EXECUTED: 'Deal Executed',
}

export const FID_ADMIN_INVESTMENT_STATUS_COLOR = {
  CREATED: '',
  IOI_SENT: 'orange',
  DEAL_SIGN_IN_PROCESS: 'orange',
  DEAL_PENDING_REVIEW: 'orange',
  DEAL_SIGNED: 'orange',
  DEAL_DECLINED: 'red',
  DEAL_APPROVED: 'orange',
  DEAL_EXECUTED: 'success',
}

export const FID_ADMIN_OPPORTUNITY_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
  NDA_DECLINED: 'NDA_DECLINED',
}

export const FID_ADMIN_OPPORTUNITY_STATUSES = [
  'APPROVED',
  'PENDING',
  'CLOSED',
]

export const FID_ADMIN_OPPORTUNITY_FAVORITES = [
  { text: 'All', value: '' },
  { text: 'Thumbs Up', value: '1' },
  { text: 'Thumbs Down', value: '-1' },
  { text: 'Neutral', value: '0' },
]

export const FID_ADMIN_OPPORTUNITY_STATUS_TEXT = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  CLOSED: 'Closed',
  NDA_DECLINED: 'NDA Declined',
}

export const ISSUER_OPPORTUNITY_STATUS_TEXT = {
  PUBLISHED: 'Published',
  CREATED: 'Created',
  READY_FOR_REVIEW: 'Ready for Review',
  PENDING_REVIEW: 'Pending Review',
  CLOSED: 'Closed',
}

export const TRANSACTION_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
}
