<template>
  <div>
    <v-row
      class="card-item py-2 text-caption px-1 trsText--text cursor-pointer"
      no-gutters
      @click="goToSeeAll"
    >
      <v-col
        v-if="portal === CONSTS.PORTAL.INVESTOR"
        cols="8"
      >
        <div>
          {{ RESPONDENT_TYPE_TEXT[item.source] }}
          &nbsp;has answered your question:
        </div>
        <div class="font-weight-medium d-flex">
          <span>"</span>
          <common-text-truncate
            v-if="item.response"
            :text="item.request"
            :tooltip="false"
          />
          <span>"</span>
        </div>
      </v-col>
      <v-col
        v-else-if="portal === CONSTS.PORTAL.FID_ADMIN"
        cols="8"
      >
        <div v-if="item.response">
          {{ RESPONDENT_TYPE_TEXT[item.respondent_type] }}
          &nbsp;has answered your question:
        </div>
        <div v-else>
          {{ item.requestor_name }} has asked a question:
        </div>
        <div class="font-weight-medium d-flex">
          <span>"</span>
          <common-text-truncate
            :text="item.request"
            :tooltip="false"
          />
          <span>"</span>
        </div>
      </v-col>
      <v-col
        v-else
        cols="8"
      >
        <div>
          <span v-if="portal === CONSTS.PORTAL.ISSUER">
            {{ item.requestor_type === MSG_SOURCES.FIDUCIARY ? 'Fiduciary' : '' }}
          </span>
          {{ item.requestor_name }} has asked a question:
        </div>
        <div class="font-weight-medium d-flex">
          <span>"</span>
          <common-text-truncate
            :text="item.request"
            :tooltip="false"
          />
          <span>"</span>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex justify-end"
      >
        <div
          v-if="item.response"
        >
          {{ $date(item.modified_on) }}
        </div>
        <div
          v-else
        >
          {{ $date(item.created_on) }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CommonTextTruncate from '@/components/core/common/TruncateText'

  const MSG_SOURCES = {
    INVESTOR: 'INVESTOR',
    FIDUCIARY: 'FIDUCIARY',
  }

  const RESPONDENT_TYPE_TEXT = {
    ISSUER: 'Issuer',
    FIDUCIARY: 'Fiduciary',
  }

  export default {
    name: 'CardItem',
    components: {
      CommonTextTruncate,
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
      showOppoName: {
        type: Boolean,
        default: true,
      },
      showReply: {
        type: Boolean,
        default: false,
      },
      canReply: {
        type: Boolean,
        default: false,
      },
      seeAllCommunications: {
        validate: () => true,
      },
    },

    data: () => ({
      isReplying: false,
      response: '',
      MSG_SOURCES,
      RESPONDENT_TYPE_TEXT,
    }),

    computed: {
      portal () {
        return this.$getPortal()
      },
    },

    methods: {
      sendReply (id, data) {
        this.$emit('update', this.item.id, { response: this.item.response })
        this.isReplying = false
      },
      cancel () {
        this.item.response = this.response
        this.isReplying = false
      },
      reply () {
        this.response = this.item.response
        this.isReplying = true
      },
      goToSeeAll () {
        this.$emit('hideContent')
        this.$router.push(this.seeAllCommunications)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-item {
    border-top: 2px solid var(--v-trsBack-base);
    border-radius: 0px !important;
    min-height: 60px;
  }
</style>
