<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-combobox
        v-bind="{ ...defaultAttrs, ...$attrs }"
        class="trs-input"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $slots"
          v-slot:[slot]
        >
          <slot
            :name="slot"
          />
        </template>
      </v-combobox>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputCommbobox',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          menuProps: { offsetY: true },
          attach: true,
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
.input-combobox-auto-height {
  .v-select {
    ::v-deep .v-select__selections {
      overflow: visible;
      white-space: normal;
    }
  }

  ::v-deep .v-chip__content {
    text-transform: initial;
  }

  ::v-deep .v-chip {
    height: auto;
    margin-bottom: 5px !important;
  }
  ::v-deep .v-chip__content {
    white-space: initial;
    height: auto;
  }
}

</style>
