<template>
  <v-card
    class="trs-card ma-0"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
  import { VCard } from 'vuetify/lib'

  export default {
    name: 'Card',

    extends: VCard,

    data () {
      return {
        defaultAttrs: {
          elevation: 0,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .trs-card {
    // pass
  }
  .trs-card.border-none {
    border: none;
  }
</style>
