<template>
  <common-common-preview-file-modal
    fullscreen
    :title="title"
    :url="signedUrl"
    :mime-type="mimeType"
    :has-download-btn="hasDownloadBtn"
    :loading="loading"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
  </common-common-preview-file-modal>
</template>

<script>
  import { api } from '@/api'
  export default {
    name: 'PreviewFileModal',

    components: {
    },

    props: {
      documentId: {
        type: String,
        required: true,
      },
      apiUrl: {
        type: String,
        required: true,
      },
      apiOption: {
        validate: () => true,
      },
      title: {
        type: String,
        default: 'Preview',
      },
      hasDownloadBtn: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      signedUrl: '',
      mimeType: '',
      loading: false,
    }),

    computed: {
    },

    watch: {
      documentId: {
        handler (val) {
          if (val) {
            this.loadDocument(val)
          }
        },
        immediate: true,
      },
    },

    async created () {
    },

    mounted () {
    },

    methods: {
      async loadDocument (docId) {
        this.loading = true
        this.signedUrl = ''
        this.mimeType = ''
        try {
          const doc = await api.get(this.apiUrl, this.apiOption)
          console.log(doc)
        } catch (err) {
          //
        }
        this.loading = false
      },
    },
  }
</script>

<style lang="scss">
</style>
