// eslint-disable
import { getPortalBranding } from '@/components/common/services/portal-branding'
import { DEFAULT_S3_DATA } from '@/constants'
import merge from 'lodash/merge'

// initial state
const initialState = {
  s3Data: {},
}

// getters
const getters = {
  s3Data: state => state.s3Data,
}

// actions
const actions = {
  async getS3Data ({ commit }, data) {
    const json = await getPortalBranding()
    commit('setS3Data', json)
  },
}

// mutations
const mutations = {
  setS3Data (state, data) {
    state.s3Data = merge(
      {},
      DEFAULT_S3_DATA,
      data,
    )
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
