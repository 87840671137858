// eslint-disable
import { api } from '@/api'
import { DEFAULT_BRANDING } from '@/constants'

// initial state
const initialState = {
  branding: DEFAULT_BRANDING,
  profile: {},
}

// getters
const getters = {
  branding: state => state.branding,
  profile: state => state.profile,
}

// actions
const actions = {
  async setBranding ({ commit, state }, branding) {
    try {
      commit('app/setLoading', true, { root: true })
      const axiosResponse = await api.patch('accounts/branding', {
        branding,
      })
      commit('setBranding', branding)
      commit('app/setLoading', false, { root: true })
      return axiosResponse
    } catch (error) {
      commit('app/setLoading', false, { root: true })
      throw error
    }
  },
  async getProfile ({ commit, state }) {
    try {
      commit('app/setLoading', true, { root: true })
      const axiosResponse = await api.get('profile', null)
      const profile = axiosResponse.data
      commit('setProfile', profile)
      commit('app/setLoading', false, { root: true })
      return axiosResponse
    } catch (error) {
      commit('app/setLoading', false, { root: true })
      throw error
    }
  },
  async setProfile ({ commit }, data) {
    try {
      commit('app/setLoading', true, { root: true })
      const axiosResponse = await api.patch('profile', data)
      const profile = axiosResponse.data
      commit('setProfile', profile)
      commit('app/setLoading', false, { root: true })
      return axiosResponse
    } catch (error) {
      commit('app/setLoading', false, { root: true })
      throw error
    }
  },
  async getBranding ({ commit, state }) {
    try {
      commit('app/setLoading', true, { root: true })
      const axiosResponse = await api.get('accounts/branding')
      const branding = axiosResponse.data.branding
      commit('setBranding', {
        logo_url: branding.logo_url || DEFAULT_BRANDING.logo_url,
        show_name: branding.show_name || DEFAULT_BRANDING.show_name,
        colors: branding.colors
          ? { ...DEFAULT_BRANDING.colors, ...branding.colors }
          : DEFAULT_BRANDING.colors,
      })
      commit('app/setLoading', false, { root: true })
      return axiosResponse
    } catch (error) {
      commit('app/setLoading', false, { root: true })
      throw error
    }
  },
  setUserProfile ({ commit }, data) {
    commit('setProfile', data)
  },
}

// mutations
const mutations = {
  setBranding (state, data) {
    state.branding = data
  },
  setProfile (state, data) {
    state.profile = data
  },
  RESET (state) {
    state.branding = DEFAULT_BRANDING
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
