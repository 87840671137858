import { USER_ROLES } from './captable'

export const CAPTABLE_FEATURES = {
  CREATE_SECURITY_TERM: 'CREATE_SECURITY_TERM',
  EDIT_SECURITY_TERM: 'EDIT_SECURITY_TERM',
  DELETE_SECURITY_TERM: 'DELETE_SECURITY_TERM',
  VIEW_SECURITY_TERM: 'VIEW_SECURITY_TERM',
  AMEND_EIP: 'AMEND_EIP',

  DRAFT_SECURITY: 'DRAFT_SECURITY',
  EDIT_SECURITY: 'EDIT_SECURITY',
  DELETE_SECURITY: 'DELETE_SECURITY',
  VIEW_SECURITY: 'VIEW_SECURITY',
  ISSUE_SECURITY: 'ISSUE_SECURITY',

  CREATE_VESTING_SCHEDULE: 'CREATE_VESTING_SCHEDULE',
  EDIT_VESTING_SCHEDULE: 'EDIT_VESTING_SCHEDULE',
  DELETE_VESTING_SCHEDULE: 'DELETE_VESTING_SCHEDULE',
  VIEW_VESTING_SCHEDULES: 'VIEW_VESTING_SCHEDULES',

  CREATE_SCENARIO: 'CREATE_SCENARIO',
  EDIT_SCENARIO: 'EDIT_SCENARIO',
  DELETE_SCENARIO: 'DELETE_SCENARIO',
  VIEW_SCENARIOS: 'VIEW_SCENARIOS',
  CREATE_SCENARIO_FINANCING_ROUND: 'CREATE_SCENARIO_FINANCING_ROUND',

  INVITE_HOLDERS: 'INVITE_HOLDERS',
  VIEW_HOLDERS: 'VIEW_HOLDERS',

  VIEW_SERVICE_REQUESTS: 'VIEW_SERVICE_REQUESTS',
  SERVICE_REQUESTS_ACTIONS: 'SERVICE_REQUESTS_ACTIONS',
  RAISE_SECURITY_ACTION_REQUEST: 'RAISE_SECURITY_ACTION_REQUEST',

  ADD_CAPTABLE_USER: 'ADD_CAPTABLE_USER',
  EDIT_CAPTABLE_USER_ROLE: 'EDIT_CAPTABLE_USER_ROLE',
  VIEW_CAPTABLE_USERS: 'VIEW_CAPTABLE_USERS',
}

export const CAPTABLE_FEATURE_ROLES = [
  // SECURITY TERMS
  {
    featureName: CAPTABLE_FEATURES.CREATE_SECURITY_TERM,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.EDIT_SECURITY_TERM,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.DELETE_SECURITY_TERM,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_SECURITY_TERM,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_VIEWER,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.AMEND_EIP,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // SECURITIES
  {
    featureName: CAPTABLE_FEATURES.DRAFT_SECURITY,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.EDIT_SECURITY,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.DELETE_SECURITY,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.ISSUE_SECURITY,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_SECURITY,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_VIEWER,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // VESTING SCHEDULE
  {
    featureName: CAPTABLE_FEATURES.CREATE_VESTING_SCHEDULE,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.EDIT_VESTING_SCHEDULE,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.DELETE_VESTING_SCHEDULE,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_VESTING_SCHEDULES,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_VIEWER,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // SCENARIOS
  {
    featureName: CAPTABLE_FEATURES.CREATE_SCENARIO,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.EDIT_SCENARIO,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.DELETE_SCENARIO,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_SCENARIOS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_VIEWER,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.CREATE_SCENARIO_FINANCING_ROUND,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // HOLDERS
  {
    featureName: CAPTABLE_FEATURES.INVITE_HOLDERS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_HOLDERS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // SERVICE REQUESTS AND SECURITY ACTION REQUESTS
  {
    featureName: CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST,
    allowedRoles: [
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_SERVICE_REQUESTS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_VIEWER,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.SERVICE_REQUESTS_ACTIONS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  // CAPTABLE USER ACCESS
  {
    featureName: CAPTABLE_FEATURES.ADD_CAPTABLE_USER,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.EDIT_CAPTABLE_USER_ROLE,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
  {
    featureName: CAPTABLE_FEATURES.VIEW_CAPTABLE_USERS,
    allowedRoles: [
      USER_ROLES.CAPTABLE_ADMIN,
      USER_ROLES.CAPTABLE_EDITOR,
      USER_ROLES.CAPTABLE_SUPER_ADMIN,
    ],
  },
]
