<template>
  <div
    class="pdf-viewer-container"
  >
    <v-progress-circular
      v-if="loadedRatio > 0 && loadedRatio < 1"
      class="loader"
      :rotate="270"
      :size="100"
      :width="10"
      :value="loadedRatio * 100"
      color="primary"
    >
      {{ (loadedRatio*100).toFixed(0) }}
    </v-progress-circular>
    <!-- <div class="wrapper">
      <pdf
        v-for="i in +numPages || 10"
        :key="i"
        :src="uri"
        :page="i"
        class="pdf-viewer"
        @num-pages="numPages = $event"
        @error="onError"
        @progress="loadedRatio = $event"
      />
    </div> -->
    <pdf
      :src="uri"
      :page="page"
      class="pdf-viewer"
      @num-pages="numPages = $event"
      @error="onError"
      @progress="loadedRatio = $event"
    />

    <div
      class="actions"
    >
      <v-fade-transition>
        <v-overlay
          absolute
          class="overlay"
        >
          <common-trs-btn
            type="tertiary"
            color="white"
            icon
            dark
            @click="prevPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </common-trs-btn>
          <span>{{ page }} of {{ numPages }}</span>
          <common-trs-btn
            icon
            dark
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </common-trs-btn>
        </v-overlay>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
  /**
    * Takes uri as link to pdf file and preview and pdf with prev/next button
    */

  export default {
    name: 'BaseCommonButtonFileInput',

    components: {
      pdf: () => import('vue-pdf'),
    },

    props: {
      uri: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        page: 1,
        numPages: 1,
        loadedRatio: 0,
      }
    },

    computed: {
    },

    watch: {
      uri: {
        handler (val) {
          this.loadFile(val)
        },
        immediate: true,
      },
    },

    methods: {
      loadFile (uri) {
      },
      onError () {
        // pass
      },
      prevPage () {
        if (this.page > 1) {
          this.page--
        }
      },
      nextPage () {
        if (this.page < this.numPages) {
          this.page++
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pdf-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .loader {
    position: absolute;
    z-index: 1;
  }
  .pdf-viewer {
    border: 1px solid #cacaca;
    width: 100%;
  }
  .actions {
    height: 50px;
    position: absolute;
    width: 100%;
    bottom: 0;
    .overlay {
      align-items: center;
    }
  }
  .wrapper{
    overflow-y: auto;
    // Tip to mobile
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
</style>
