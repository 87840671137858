<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      class="mb-1"
    >
      <common-input-label
        class="text-left"
        :class="{ 'error--text': $attrs['hide-details'] && $attrs['error-messages'] && $attrs['error-messages'].length > 0 }"
        :disabled="disabled"
      >
        <slot name="label">
          <span v-if="$attrs.label">{{ $attrs.label }}</span>
        </slot>
      </common-input-label>
    </v-col>

    <v-col
      cols="12"
      class="mb-2 d-flex align-center"
    >
      <div class="level">
        <span class="level-item">Root</span>
        <span class="level-separator">/</span>
      </div>
      <div
        v-for="(ind, index) in Array(level).fill(0)"
        :key="index"
        class="d-flex align-center"
      >
        <span class="level-item">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Name'"
          >
            <v-combobox
              v-model="internalValue[index]"
              dense
              single-line
              :error-messages="errors"
              color="primary"
              :placeholder="'Name'"
              validate-on-blur
              :items="getCategoryNames(index)"
              :disabled="disabled"
              @blur="internalValue[index] && (internalValue[index] = internalValue[index].trim())"
            />
          </validation-provider>
        </span>
        <common-trs-btn
          v-if="index == level - 1"
          type="tertiary"
          text
          icon
          x-small
          :disabled="disabled"
          @click="deleteLast"
        >
          <v-icon size="14">
            mdi-delete
          </v-icon>
        </common-trs-btn>
        <span class="level-separator">/</span>
      </div>
      <div>
        <common-trs-btn
          type="tertiary"
          text
          icon
          x-small
          :disabled="disabled"
          @click="add"
        >
          <v-icon size="14">
            mdi-plus
          </v-icon>
        </common-trs-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputCheckbox',

    props: {
      value: {
        type: Object,
        required: true,
      },
      documents: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
      }
    },

    computed: {
      level () {
        return +this.internalValue.Type
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
    },

    methods: {
      getCategoryNames (level) {
        let docs = this.documents.filter(doc => doc.category && doc.category[0])
        for (let i = 0; i < level; i++) {
          docs = docs
            ? docs.filter(doc => doc.category && doc.category[i] === this.internalValue[i])
            : []
        }

        return docs.filter(doc => doc.category && doc.category[level]).map(doc => doc.category[level])
      },
      add () {
        this.internalValue = {
          ...this.internalValue,
          Type: String(this.level + 1),
        }
        this.$emit('change')
      },
      deleteLast () {
        this.internalValue = {
          ...this.internalValue,
          [this.level - 1]: '',
          Type: String(this.level - 1),
        }
        this.$emit('change')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .level-item {
  }
  .level-separator {
    padding-left: 10px;
    padding-right: 10px;
  }
  .v-input__control {
    width: 100px;
  }
</style>
