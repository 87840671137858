<template>
  <div
    v-if="publicBranding.help_address"
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      class="text-decoration-none privacy-policy-text"
      :href="`mailto:${publicBranding.help_address}`"
    >
      Help and Assistance
    </a>
  </div>
</template>

<script>
  export default {
    name: 'LoginHelpZendesk',

    props: {
    },

    data: () => ({
    }),

    computed: {
      publicBranding () {
        return this.$store.getters['publicBranding/s3Data']
      },
    },

    async created () {
    },
  }
</script>
