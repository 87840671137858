<template>
  <div>
    <v-row
      justify="center"
      align="center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'PageLoader',

    props: {
    },
  }
</script>
