<template>
  <div class="d-flex align-center">
    <v-text-field
      outlined
      type="number"
      hide-details
      v-bind="$attrs"
      :value="value"
      @input="onInput"
    />
    <div class="addons">
      <div
        class="control up"
        @click="onUp"
      />
      <div
        class="control down"
        @click="onDown"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'InvestInput',
    props: {
      value: Number,
    },
    methods: {
      onDown () {
        console.log('sssss')
        const temp = (this.value || 0) - 10000
        this.$emit('input', temp)
      },
      onUp () {
        this.$emit('input', (this.value || 0) + 10000)
      },
      onInput (e) {
        this.$emit('input', e.target.value)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -30px;
    .control {
      width: 0px;
      height: 0px;
      z-index: 100;
      &.up{
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #546062;
        margin-bottom: 2px;
        cursor: pointer;
        &:active {
          border-bottom-color: black;
        }
      }
      &.down{
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #546062;
        margin-top: 2px;
        cursor: pointer;
        &:active {
          border-top-color: black;
        }
      }
    }
  }
</style>
