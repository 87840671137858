<template>
  <v-label
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-label>
</template>

<script>
  export default {
    name: 'BaseInputLabel',

    props: {
    },
  }
</script>
<style lang="scss" scoped>
  .v-label {
    color: rgba(0, 0, 0, 0.9)
  }
</style>
