/** THIS IS COMMON CODE. PLEASE DO NOT CHANGE WITHOUT DISCUSSION WITH THE TEAM **/
import axios from 'axios'
import store from '@/store'
import router from '@/router'

import {
  CAPTABLE_AUTH_BASE,
  CYNDX_BASE_URL,
  // CYNDX_CLIENT_DATA_TOKEN,
} from '@/constants'

const axiosInstance = axios.create({
  baseURL: CYNDX_BASE_URL,
  timeout: 0,
  params: {}, // do not remove this, its added to add params later in the config
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (conf, request) => {
    const token = store.getters['auth/cyndxAccessToken']
    // This header needs confirmation from Cyndx
    // conf.headers.common['x-client-data'] = CYNDX_CLIENT_DATA_TOKEN
    if (token) {
      conf.headers.common.Authorization = `Bearer ${token}`
    }
    return conf
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout')
    router.push({ name: 'Dashboard' })
  }
  // Comment out refreshing token logic
  // const originalRequest = error.config
  // if (error.response &&
  //   error.response.status === 401 &&
  //   !originalRequest._retry &&
  //   error.response.config.url.indexOf('token/refresh') === -1) {
  //   originalRequest._retry = true
  //   return store.dispatch('auth/refresh')
  //     .then((res) => {
  //       if (res.status === 200) {
  //         originalRequest.headers.authorization = `Bearer ${res.data.access}`
  //         return axios(originalRequest)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       store.dispatch('auth/logout')
  //       router.push({ name: 'Login' })
  //     })
  // }
  return Promise.reject(error)
})

const defaultOption = {
  base: CYNDX_BASE_URL,
}

/**
  * options.base - base.trellisplatform.com
  */
const getUrl = (resource, options) => {
  let base = ''
  if (!options.base) {
    options.base = CYNDX_BASE_URL
  }

  if (options.base === 'auth') {
    base = CAPTABLE_AUTH_BASE
  } else { // can be common
    base = `${CYNDX_BASE_URL}`
  }
  return `${base}/${resource}`
}

export const api = {
  axiosInstance,

  /**
   * get - get request to the api
   *
   * @param {string} resource
   * @param {any} params
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async get (resource, params, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.get(getUrl(resource, options), params)
  },

  /**
   * post - post request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async post (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.post(getUrl(resource, options), body)
  },

  /**
   * patch - patch request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async patch (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.patch(getUrl(resource, options), body)
  },

  /**
   * put - put request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async put (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.put(getUrl(resource, options), body)
  },

  /**
   * delete - delete request to the api
   *
   * @param {string} resource
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async delete (resource, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.delete(getUrl(resource, options))
  },

  /**
   * upload - post multipart from
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async upload (resource, body, options, progressCallback) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.post(getUrl(resource, options), body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)

        progressCallback && progressCallback(uploadPercentage)
      },
    })
  },
}
