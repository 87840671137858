<template>
  <l-map
    ref="map"
    :zoom="zoom"
    :center="center"
  >
    <l-tile-layer
      :url="url"
    />
    <l-marker
      v-for="(marker, index) in chartData"
      :key="index"
      :lat-lng="marker.latlng"
    >
      <l-tooltip :content="getTooltipContent(marker)" />
    </l-marker>
  </l-map>
</template>

<script>
  import L from 'leaflet'
  import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'
  export default {
    name: 'DashboardMap',

    components: {
      LMap,
      LTileLayer,
      LMarker,
      LTooltip,
    },

    props: {
      value: {
        type: Array,
        default: undefined,
      },
    },

    data () {
      return {
        zoom: 14,
        center: L.latLng(32.763220, -96.739482),
        url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      }
    },

    computed: {
      chartData () {
        let locations = this.value ? this.value.filter(marker => marker.lat && marker.lng && marker.lat !== 'null' && marker.lng !== 'null') : []

        locations = locations.map(marker => {
          return {
            ...marker,
            latlng: L.latLng(marker.lat, marker.lng),
          }
        })

        const markers = locations.map(d => d.latlng)

        if (this.$refs.map && markers.length) {
          this.$refs.map.fitBounds(markers)
        }
        return locations
      },
    },

    methods: {
      getTooltipContent (item) {
        if (item.count > 1) {
          return `Location: ${item.city}, ${item.state}, ${item.country} (${item.count})`
        } else {
          return `Location: ${item.city}, ${item.state}, ${item.country}`
        }
      },
    },
  }
</script>

<style>
  .vue2leaflet-map {
    z-index: 0
  }
  .leaflet-control-attribution {
    display: none
  }
</style>
