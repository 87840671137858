<template>
  <v-overlay
    :value="loading"
    :opacity="0.9"
    style="z-index: 1000;"
  >
    {{ message }}
  </v-overlay>
</template>

<script>
  export default {
    name: 'PageLoader',

    props: {
      loading: {
        type: Boolean,
      },
    },

    computed: {
      message () {
        return this.$store.getters['app/loadingMessage']
      },
    },
  }
</script>
