<template>
  <v-card
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
  export default {
    name: 'CommonTrsModalCard',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
