<template>
  <v-pagination
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </v-pagination>
</template>

<script>
  export default {
    name: 'CommonTrsPagination',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-pagination {
    .v-pagination__navigation {
    }

    .v-pagination__item, .v-pagination__navigation {
      box-sizing: border-box;
      box-shadow: none;
      &.trs-icon {
        border-radius: 0px
      }

      background: linear-gradient(180deg, #FFFFFF 0%, #EDF2F3 100%) !important;
      border: 1px solid #D7E0E2;

      &.v-pagination__item--active {
        background: linear-gradient(180deg, var(--v-primary-lighten1) 0%, var(--v-primary-base) 100%) !important;
        border: 1px solid var(--v-primary-darken1);
      }
    }
  }
</style>
