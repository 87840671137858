<template>
  <v-tab
    v-if="pemAllowed"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-tab>
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    name: 'CommonTab',
    mixins: [permissionMixin],

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>
