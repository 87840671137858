import Vue from 'vue'
import VueI18n from 'vue-i18n'

import vuetifyEn from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const en = {
  ...require('@/locales/en.js').default,
  core: require('@/components/core/locales/en.js').default,
  $vuetify: vuetifyEn,
}

const messages = {
  en,
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})

export default i18n

// Hot updates
// if (module.hot) {
//   module.hot.accept(['./en'], function () {
//     i18n.setLocaleMessage('en', en)
//   })
// }
