<template>
  <div style="width: 100%">
    <div
      v-if="hasDownloadBtn"
      class="mb-2 justify-end d-flex mt-2 mr-2"
    >
      <common-trs-btn
        v-if="url"
        :download="filename"
        type="primary"
        class="white--text"
        target="_blank"
        rel="noopener noreferrer"
        :href="url"
      >
        Download
      </common-trs-btn>
    </div>
    <div
      v-if="description"
    >
      <item-info>
        <div class="trsText--text text-body-2">
          Description:
        </div>
        <p class="text-body-2">
          {{ description }}
        </p>
      </item-info>
    </div>
    <div
      class="preview-file d-flex justify-center align-center"
    >
      <div
        v-if="mimeType.indexOf('image/') !== -1"
        class="image-preview"
      >
        <img
          :src="url"
        >
      </div>
      <div
        v-else-if="mimeType.indexOf('pdf') !== -1"
        class="pdf-preview"
      >
        <common-pdf-viewer
          :download-allowed="hasDownloadBtn"
          :uri="url"
        />
      </div>
      <div
        v-else-if="mimeType.includes('openxmlformats')"
        class="office-preview"
      >
        <common-doc-viewer
          :download-allowed="hasDownloadBtn"
          :uri="url"
        />
      </div>
      <div
        v-else-if="mimeType.includes('video')"
        class="mp4-preview"
      >
        <video
          controls
          autoplay
          :src="url"
        />
      </div>
      <div
        v-else-if="isRawFile"
        class="preview-file"
      >
        <common-raw-file-viewer :uri="url" />
      </div>
      <div
        v-else
        class="pdf-preview text-center pa-3"
      >
        No preview available
      </div>
    </div>
  </div>
</template>

<script>
  /**
    * Previews pdf or image file. Requires uil and mimeType
    */
  export default {
    name: 'BaseCommonPreviewFile',

    components: {
      CommonPdfViewer: () => import('@/components/core/components/PdfViewer'),
      CommonDocViewer: () => import('@/components/core/components/DocViewer'),
      CommonRawFileViewer: () => import('@/components/core/components/RawFileViewer'),
    },

    props: {
      url: {
        type: String,
        required: true,
      },
      mimeType: {
        type: String,
        default: '',
      },
      hasDownloadBtn: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
      }
    },

    computed: {
      filename () {
        // Sample URL
        // https://trellisdev.s3.amazonaws.com/trellisdev/issuer/4a772412-7b52-4f32-b54d-c4d51b205a67/opportunity/Jio%20Petroleum%20Updated/DEAL_DOCUMENT/DEAL_DOCUMENT_Digitize%20and%20GET%20deal%20document%20API.postman_collection.json?AWSAccessKeyId=AKIA4B6YTUX4ZRPTSKXX&Signature=HUJ6Dl9wqcpZ98yuI53hs4YerMg%3D&Expires=1597267370
        // let url = this.url.split('?')[0]
        // url = url.slice(url.lastIndexOf('/'))
        return ''
      },
      isRawFile () {
        return this.mimeType.includes('json') || this.mimeType.includes('text')
      },
    },

    watch: {
    },

    methods: {
    },
  }
</script>

<style lang="scss" scoped>
  .preview-file {
    width: 100%;
  }

  .image-preview {

    img {
      max-width: 100%;
    }
  }
  .mp4-preview,
  .office-preview,
  .pdf-preview {
    width: 100%;
  }
  .mp4-preview {
    video {
      width: 100%;
    }
  }
</style>
