const initialState = {
  selectedUser: {},
}

const getters = {
  selectedUser: state => state.selectedUser,
}

const actions = {
  setSelectedUser ({ commit }, data) {
    commit('setSelectedUser', data)
  },
}

const mutations = {
  setSelectedUser (state, data) {
    state.selectedUser = data
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
