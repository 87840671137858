<template>
  <div>
    <div
      class="investor-name"
    >
      <span class="text-extra-small">
        Investor:
      </span>
      <span class="text-caption font-weight-medium ml-1">
        {{ info.text }}
      </span>
    </div>
    <div
      class="d-flex flex-row"
    >
      <v-card
        v-for="(label, key) in labels"
        :key="key"
        class="info-item"
        outlined
        tile
      >
        <div
          class="info-title font-weight-medium text-extra-small d-flex justify-center"
          :class="{active:info[key]}"
          v-text="label"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InlineInfo',

    props: {
      info: {
        type: Object,
        default: undefined,
      },
    },

    data () {
      return {
        labels: {
          nda_signed_date: 'NDA Signed',
          deal_signed_date: 'Deal Signed',
          counter_signed_deal_date: 'Deal Countersigned',
          payments_received: 'Payment Received',
          payments_acknowledged: 'Payment Acknowledged',
        },
      }
    },

    computed: {
      showIOI () {
        return this.$store.getters['publicBranding/showIOI']
      },
    },
    mounted () {
      if (!this.showIOI) {
        this.labels = {
          nda_signed_date: 'NDA Signed',
          deal_signed_date: 'Deal Signed',
          counter_signed_deal_date: 'Deal Countersigned',
          payments_received: 'Payment Received',
          payments_acknowledged: 'Payment Acknowledged',
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .info-item {
      flex: 1;
      color: black;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      border: 1px solid white;
      margin-top: 10px;
      border-radius: 3px !important;

    .info-title {
      padding: 7px;
      border-radius: 3px;
      height: 45px;
      align-items: center;
      border: 1px solid #d8d8d8;

      &.active {
        color: white;
        background: var(--v-primary-base);
        border: 0px;
      }
    }
  }
</style>
