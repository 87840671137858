<template>
  <div
    v-if="internalValue && internalValue.itemsPerPage && internalValue.total"
    class="py-2 trs-paginator"
  >
    <div class="d-flex flex-row justify-end">
      <div
        class="d-flex align-center"
        :style="{ opacity: 0.8 }"
      >
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="trsText--text text-body-1 text--light-1 mr-5 white-space-nowrap"
        >
          {{ $t('core.rowsPerPage') }}
        </div>
        <div style="width: 50px">
          <common-input-select
            v-model="internalValue.itemsPerPage"
            :items="CONSTS.DATATABLE_DEFAULT_PAGINATION"
            label="Items Per Page"
            hide-details
            single-line
            dense
            :filled="false"
            @input="itemsPerPageChange"
          />
        </div>
      </div>
      <common-trs-pagination
        v-model="internalValue.page"
        :length="internalValue.total"
        :total-visible="5"
        @input="$emit('paginationChange', $event)"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CommonTrsDataTableListPager',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
    methods: {
      itemsPerPageChange (v) {
        this.$emit('input', {
          ...this.value,
          itemsPerPage: v,
          page: 1,
        })
      },
    },
  }
</script>
