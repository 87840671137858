<template>
  <a
    :href="fullLink"
    rel="noopener noreferrer"
    target="_blank"
    style="text-decoration:none;"
  >
    <v-icon class="icon-color">
      mdi-linkedin
    </v-icon>
  </a>
</template>

<script>
  export default {
    props: {
      link: {
        type: String,
        default: null,
      },
    },
    computed: {
      fullLink () {
        return this.link && this.link.startsWith('http') ? this.link : `//${this.link}`
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icon-color{
    color: #0a66c2;
  }
</style>
