const fileDragMixin = {
  data: function () {
    return {
      dragHighlight: 0,
    }
  },
  destroyed () {
    document.removeEventListener('dragenter', this.dragOverHandle)
    document.removeEventListener('dragleave', this.dragEndHandle)
    window.removeEventListener('visibilitychange', this.clearDragClass)
    document.removeEventListener('drop', this.clearDragClass)
  },
  mounted () {
    document.addEventListener('dragenter', this.dragOverHandle)
    document.addEventListener('dragleave', this.dragEndHandle)
    window.addEventListener('visibilitychange', this.clearDragClass)
    document.addEventListener('drop', this.clearDragClass)
  },
  methods: {
    dragOverHandle (ev) {
      if (!window.preventGlobalDrag) {
        this.dragHighlight += 1
      }
    },
    dragEndHandle (ev) {
      if (!window.preventGlobalDrag) {
        this.dragHighlight -= 1
      }
    },
    clearDragClass () {
      this.dragHighlight = 0
      window.preventGlobalDrag = false
    },
  },
}

export default fileDragMixin
