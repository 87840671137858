<template>
  <div class="px-6 pt-6">
    <div class="px-1 py-2 trsText--text text-h3 font-weight-medium">
      {{ title }}
    </div>
    <div class="ml-1 trsText--text text-body-1 font-weight-light mb-2">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',

    props: {
      title: {
        type: String,
        default: '',
      },
      subTitle: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
