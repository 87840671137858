<template>
  <v-list
    nav
    dense
    class="pa-0"
  >
    <v-list-item-group
      v-model="internalValue"
      mandatory
      color="primary"
    >
      <template
        v-for="(item, index) in menuItems"
      >
        <v-divider
          :key="`divider-${index}`"
        />
        <v-list-item
          :key="index"
          class="pa-0 ma-0"
          flat
          active-class="white"
          :ripple="false"
        >
          <v-list-item-content>
            <v-list-item-title
              class="mx-2"
              v-text="item.label"
            />
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: 'CommonTrsListTabNav',

    components: {
    },

    props: {
      value: {
        type: Number,
      },
      menuItems: {
        type: Array,
        default: () => ([]),
      },
    },

    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
    mounted () {
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--active:before {
  background: #FFF,
}
</style>
