<template>
  <div class="d-flex flex-column ">
    <v-progress-circular
      v-if="loading"
      indeterminate
      class="mx-auto"
      color="primary"
    />
    <pre class="px-2 file-content">{{ content }}</pre>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'RawFileViewer',
    props: {
      uri: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        content: null,
        loading: false,
      }
    },
    mounted () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.loading = true
        try {
          const response = await axios.get(this.uri)
          this.content = response.data
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load file',
          })
        }
        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
.file-content {
  white-space: pre-wrap;
}
</style>
