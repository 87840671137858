export const FID_ADMIN_INVESTMENT_STATUS = {
  CREATED: 'CREATED',
  IOI_SENT: 'IOI_SENT',
  DEAL_PENDING_REVIEW: 'DEAL_PENDING_REVIEW',
  DEAL_SIGN_IN_PROCESS: 'DEAL_SIGN_IN_PROCESS',
  DEAL_SIGNED: 'DEAL_SIGNED',
  DEAL_DECLINED: 'DEAL_DECLINED',
  DEAL_EXECUTED: 'DEAL_EXECUTED',
  DEAL_APPROVED: 'DEAL_APPROVED',
}

export const FID_ADMIN_INVESTMENT_STATUSES = [
  { value: 'CREATED', text: 'Created' },
  { value: 'IOI_SENT', text: 'IOI Sent' },
  { value: 'DEAL_SIGNED', text: 'Deal Signed' },
  { value: 'DEAL_SIGN_IN_PROCESS', text: 'Deal Sign in Progress' },
  { value: 'DEAL_PENDING_REVIEW', text: 'Waiting for approval' },
  { value: 'DEAL_DECLINED', text: 'Deal Declined' },
  { value: 'DEAL_EXECUTED', text: 'Deal Executed' },
  { value: 'DEAL_APPROVED', text: 'Deal Approved' },
]
