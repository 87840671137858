import { DR_TYPES } from './digital-roadshow'
export const OPPORTUNITY_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
}

export const COMMON_FULL_ADDRESS_FORM = [
  {
    id: 'line1',
    input: 'text',
    label: 'Address Line 1',
    regex: '',
    // mandatory: true,
    dataSource: 'inline',
    shouldRequire: true,
  },
  {
    id: 'line2',
    input: 'text',
    label: 'Address Line 2',
    regex: '',
    // mandatory: false,
  },
  {
    id: 'line3',
    input: 'text',
    label: 'Address Line 3',
    regex: '',
    // mandatory: false,
  },
  {
    id: 'country',
    input: 'select',
    label: 'Country',
    regex: '',
    entity: 'countries',
    default: '1',
    // mandatory: true,
    dataSource: 'entity_common',
    shouldRequire: true,
  },
  {
    id: 'province',
    input: 'select',
    label: 'State/Region',
    regex: '',
    entity: 'states',
    // mandatory: false,
    dataSource: 'entity_common',
    radioQueries: [
      {
        compare: 'starts_with',
        fieldName: 'country',
        inputId: 'country',
        // Do not need section id here
        // sectionId: '1d19f5ce-9931-402a-a8fd-353a2da90c71',
      },
    ],
  },
  {
    id: 'city',
    input: 'text',
    label: 'City',
    regex: '',
    // mandatory: true,
    shouldRequire: true,
  },
  {
    id: 'postal',
    input: 'number',
    label: 'Zip/Postal Code',
    regex: '',
    // mandatory: true,
    shouldRequire: true,
  },
]

export const COMPANY_FORM_TEMPLS = [
  {
    id: 'name',
    input: 'text',
    label: 'Legal Company Name',
    mandatory: true,
    regex: '',
  },
  // {
  //   id: 'branding.show_name',
  //   input: 'text',
  //   label: 'Company Branding Name',
  //   mandatory: true,
  //   regex: '',
  // },
  {
    // dataSource: 'entity',
    // entity: 'structure',

    dataSource: 'inline',
    data: [
      { id: 1, name: 'Corporation' },
      { id: 2, name: 'LLC' },
      { id: 3, name: 'Partnership' },
      { id: 4, name: 'NonProfit' },
      { id: 5, name: 'ForeignCorp' },
      { id: 6, name: 'Other' },
    ],
    radioIdFieldName: 'id',
    radioNameFieldName: 'name',
    returnObject: true,
    id: 'details.structure',
    input: 'select',
    label: 'Structure',
    mandatory: true,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'yearFounded',
    id: 'details.yearFounded',
    input: 'number',
    label: 'Year Founded',
    min: 0,
    mandatory: false,
    regex: '',
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'stage',
    id: 'stage',
    input: 'select',
    label: 'Company Stage',
    mandatory: true,
    regex: '',
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'market_short',
    id: 'market',
    input: 'select',
    label: 'Primary Market',
    mandatory: true,
    regex: '',
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'common_sector',
    id: 'details.sector',
    input: 'select',
    label: 'Sector',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'common_industry',
    id: 'details.industry',
    input: 'select',
    label: 'Industry',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    id: 'details.naics',
    input: 'text',
    label: 'NAICS Code',
    regex: '',
    mandatory: false,
    validation: {},
    columnNumber: 1,
    customRuleSets: {},
  },
  {
    id: 'details.isin',
    input: 'text',
    label: 'ISIN #',
    regex: '',
    mandatory: false,
    validation: {},
    columnNumber: 1,
    customRuleSets: {},
  },
  {
    id: 'details.headquarters',
    input: 'text',
    label: 'Headquarters',
    regex: '',
    mandatory: false,
    validation: {},
    columnNumber: 1,
    customRuleSets: {},
  },
  {
    id: 'details.employees',
    input: 'text',
    label: '# of Employees',
    regex: '^[0-9]+$',
    mandatory: false,
    validation: {},
    customRuleSets: {},
  },
  {
    id: 'details.address',
    input: 'address',
    label: 'Address',
    mandatory: true,
    regex: '',
    class: 'address',
  },
]

export const COMPANY_FORM_BRANDING_IMAGE_TEMPLS = [
  {
    id: 'branding.logo',
    input: 'public_image',
    label: 'Branding Expanded Logo',
    instructionsWide: 'At least 144px wide',
    instructionsHeigh: 'At least 48px high',
    instructionsImage: 'PNG( preferred), JPG',
    minCropBoxHeight: '48',
    minCropBoxWidth: '144',
    mandatory: true,
    regex: '',
  },
  {
    id: 'branding.logo_small',
    input: 'public_image',
    label: 'Branding Collapsed Logo',
    instructionsWide: 'At least 48px wide',
    instructionsHeigh: 'At least 48px high',
    instructionsImage: 'PNG( preferred), JPG',
    minCropBoxHeight: '48',
    minCropBoxWidth: '144',
    mandatory: true,
    regex: '',
  },
]

export const OPPORTUNITY_FORM_TEMPLS1 = [
  {
    id: 'name',
    input: 'text',
    label: 'Opportunity Name',
    mandatory: true,
    regex: '',
    limit: 50,
  },
  {
    id: 'details.shortDesc',
    input: 'text',
    label: 'Opportunity Description',
    mandatory: false,
    regex: '',
    limit: 50,
  },
]

const STRUCTURE_BY_DR_TYPE = {
  [DR_TYPES.GENERAL]: 'structure_corporate',
  [DR_TYPES.FUND]: 'structure_fund',
  [DR_TYPES.SPV]: 'structure_spv',
}

export const OPPORTUNITY_FORM_TEMPLS2 = (drType) => {
  return [
    {
      id: 'opportunity_type',
      input: 'radio',
      label: '',
      mandatory: true,
      dataSource: 'inline',
      regex: '',
      mdCol: 12,
      data: [
        { label: 'Primary', value: OPPORTUNITY_TYPE.PRIMARY },
        { label: 'Secondary', value: OPPORTUNITY_TYPE.SECONDARY },
      ],
      align: 'row',
      radioIdFieldName: 'value',
      radioNameFieldName: 'label',
    },
    {
      dataSource: 'entity',
      entity: STRUCTURE_BY_DR_TYPE[drType],
      id: 'type',
      input: 'select',
      label: 'Opportunity Type',
      mandatory: true,
      regex: '',
      mdCol: 6,
      fallbackCommon: true,
    },
    // {
    //   id: 'description',
    //   input: 'text_long',
    //   label: 'Description',
    //   mandatory: false,
    //   regex: '',
    // },
  ]
}

export const OPPORTUNITY_TYPE_PRIMARY_FORM = [
  {
    dataSource: 'entity',
    entity: 'market_short',
    id: 'details.market',
    input: 'select',
    label: 'Primary Market (Select One)',
    mandatory: true,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'common_sector',
    id: 'details.common_sector',
    input: 'select_multi',
    label: 'Common Sector',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'common_industry',
    id: 'details.common_industry',
    input: 'select_multi',
    label: 'Common Industry',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    id: 'size',
    input: 'currency',
    label: 'Target Raise',
    mandatory: true,
    regex: '',
    mdCol: 6,
    min_value: 1,
  },
  {
    id: 'minimum',
    input: 'currency',
    label: 'Minimum Investment',
    mandatory: true,
    regex: '',
    mdCol: 6,
    min_value: 1,
    customRuleSets: {
      less_than_ref: '@size',
    },
  },
  {
    id: 'pre',
    input: 'currency',
    label: 'Pre-Money Valuation',
    // mandatory: true,
    regex: '',
    mdCol: 6,
    // min_value: 1,
  },
  {
    id: 'post',
    input: 'currency',
    label: 'Post-Money Valuation',
    // mandatory: true,
    regex: '',
    mdCol: 6,
    // min_value: 1,
  },
  {
    id: 'start_date',
    input: 'date',
    label: 'Start Date',
    mandatory: false,
    regex: '',
    mdCol: 6,
  },
  {
    id: 'end_date',
    input: 'date',
    label: 'End Date',
    mandatory: false,
    regex: '',
    mdCol: 6,
    customRuleSets: {
      is_after_ref: '@start_date',
    },
  },
  {
    dataSource: 'entity',
    entity: 'stage',
    id: 'details.stage',
    input: 'select',
    label: 'Stage',
    mandatory: true,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
]

export const OPPORTUNITY_TYPE_PRIMARY_FORM_SHARE_TERMS = [
  {
    dataSource: 'cyndx',
    entity: 'share_terms',
    id: 'details.captable',
    input: 'select',
    label: 'Share Terms',
    mandatory: true,
    regex: '',
    mdCol: 6,
    actionLabel: 'Add new share term',
  },
]

export const OPPORTUNITY_TYPE_SECONDARY_FORM = [
  {
    mdCol: 6,
  },
  {
    dataSource: 'entity',
    entity: 'common_sector',
    id: 'details.common_sector',
    input: 'select_multi',
    label: 'Common Sector',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    dataSource: 'entity',
    entity: 'common_industry',
    id: 'details.common_industry',
    input: 'select_multi',
    label: 'Common Industry',
    mandatory: false,
    regex: '',
    mdCol: 6,
    fallbackCommon: true,
  },
  {
    id: 'start_date',
    input: 'date',
    label: 'Start Date',
    mandatory: true,
    regex: '',
    mdCol: 6,
  },
  {
    id: 'ticker',
    input: 'text',
    label: 'Ticker',
    mandatory: true,
    regex: '',
  },
]

export const BACK_IMAGE_INPUT_TEMPL = {
  id: 'details.logo',
  input: 'public_image',
  label: 'Background Image',
  // mandatory: true,
  regex: '',
  comment:
    'This image will show as a background image in the opportunity presentation. Tip: Darker backgrounds will look better.',
  version: 2,
  helperTexts: ['At least 1200px wide', 'At least 600px high', 'JPG, PNG'],
}

export const PROFILE_IMAGE_INPUT_TEMPL = {
  id: 'issuer.logo',
  input: 'public_image',
  label: 'Profile Image',
  // mandatory: true,
  regex: '',
  comment:
    'This image will show as a background image in the opportunity presentation. Tip: Darker backgrounds will look better.',
  version: 2,
  helperTexts: ['At least 144px wide', 'At least px high', 'JPG, PNG'],
}

const EQUITY_PREFERRED = [
  {
    id: 'conversionRatio',
    input: 'number',
    label: 'Conversion Ratio',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'liquidationPreferenceMultiple',
    input: 'number',
    label: 'Liquidation Preference: Multiple',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'liquidationPreferenceInterestRate',
    input: 'number',
    label: 'Liquidation Preference: Interest Rate',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'liquidationPreferenceDividends',
    input: 'number',
    label: 'Liquidation Preference: Dividends',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    dataSource: 'inline',
    id: 'antiDilutionProtection',
    input: 'select',
    label: 'Anti Dilution Protection',
    regex: '',
    data: ['Y', 'N'],
  },
]

const DEBT_OPT = [
  {
    id: 'interestRate',
    input: 'number',
    label: 'Interest Rate',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'Term (Months)',
    input: 'number',
    label: 'Term (Months)',
    regex: '',
    min: 0,
    mdCol: 6,
  },
]

const PARTNERSHIP_INTEREST = [
  {
    id: 'managementFee',
    input: 'text',
    label: 'Management Fee',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'carry',
    input: 'text',
    label: 'Carry',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'managementParticipation',
    input: 'text',
    label: 'Management Participation',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'hurdleRate',
    input: 'text',
    label: 'Hurdle Rate / Preferred Return',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'fundTerm',
    input: 'text',
    label: 'Fund Term (years)',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'administrator',
    input: 'text',
    label: 'Administrator',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'auditor',
    input: 'text',
    label: 'Auditor',
    regex: '',
    min: 0,
    mdCol: 6,
  },
  {
    id: 'secREgistered',
    input: 'text',
    label: 'SEC Registered - Yes/No',
    regex: '',
    min: 0,
    mdCol: 6,
  },
]

export const OPPORTUNITY_DETAIL_FORM_TEMPLS = {
  1: [
    {
      id: 'pricePerShare',
      input: 'currency',
      label: 'Price Per Share',
      mandatory: false,
      regex: '',
      mdCol: 6,
      precision: 2,
    },
  ],
  2: EQUITY_PREFERRED,
  3: EQUITY_PREFERRED,
  4: [
    {
      id: 'conversionRatio',
      input: 'number',
      label: 'Valuation Cap',
      regex: '',
      min: 0,
      mdCol: 6,
    },
    {
      id: 'conversionTrigger',
      input: 'number',
      label: 'Conversion Trigger',
      regex: '',
      min: 0,
      mdCol: 6,
    },
    {
      id: 'conversionDiscount',
      input: 'number',
      label: 'Conversion Discount',
      regex: '',
      min: 0,
      mdCol: 6,
    },
  ],
  5: [
    {
      id: 'Term (Months)',
      input: 'number',
      label: 'Term (Months)',
      regex: '',
      min: 0,
      mdCol: 6,
    },
    {
      id: 'conversionTrigger',
      input: 'text',
      label: 'Conversion Trigger',
      regex: '',
      mdCol: 6,
    },
    {
      id: 'conversionDiscount',
      input: 'number',
      label: 'Conversion Discount',
      regex: '',
      min: 0,
      mdCol: 6,
    },
    {
      id: 'interestRate',
      input: 'number',
      label: 'Interest Rate',
      regex: '',
      min: 0,
      mdCol: 6,
    },
    {
      dataSource: 'inline',
      id: 'interestCompoundPeriod”',
      input: 'select',
      label: 'Interest Compound Period”',
      regex: '',
      mdCol: 6,
      data: ['Simple', 'Annually'],
    },
    {
      dataSource: 'inline',
      id: 'interestAccrualPeriod',
      input: 'select',
      label: 'Interest Accrual Period',
      regex: '',
      mdCol: 6,
      data: ['Monthly', 'Quarterly', 'Annually'],
    },
    {
      id: 'earlyExitMultiple',
      input: 'text',
      label: 'Early Exit Multiple',
      regex: '',
      mdCol: 6,
    },
    {
      id: 'warrantCoverage',
      input: 'text',
      label: 'Warrant Coverage',
      regex: '',
      mdCol: 6,
    },
  ],
  6: DEBT_OPT,
  7: DEBT_OPT,
  8: DEBT_OPT,
  9: PARTNERSHIP_INTEREST,
}
