import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import i18n from '@/i18n'
import '@/sass/overrides.sass'
import theme from '@/components/core/styles/theme'

Vue.use(Vuetify)

export default new Vuetify({
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    options: {
      customProperties: true,
    },
  },
})
