<template>
  <v-card
    v-if="layout === 'card'"
    class="mt-0"
    width="320"
  >
    <v-card-title>
      {{ document.document_name || document.document_type }}
      <v-progress-circular
        v-if="loading || docLoading"
        indeterminate
        color="primary"
        size="20"
        class="ml-2"
      />
    </v-card-title>
    <v-card-text class="py-0">
      <v-row
        v-if="oppDocument"
        dense
      >
        <v-col>
          <v-list-item-subtitle>Opportunity</v-list-item-subtitle>
        </v-col>
        <v-col>
          <v-list-item-title class="mb-0">
            <router-link
              :to="{ name: 'DigitalRoadshow.Landing', params: { id: document.opportunity_id } }"
            >
              {{ document.opportunity_name }}
            </router-link>
          </v-list-item-title>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-list-item-subtitle>Updated</v-list-item-subtitle>
        </v-col>
        <v-col>
          <v-list-item-title class="mb-0">
            {{ formatDate(document.modified_on) }}
          </v-list-item-title>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-list-item-subtitle>Type</v-list-item-subtitle>
        </v-col>
        <v-col>
          <v-list-item-title class="mb-0">
            {{ document.document_type }}
          </v-list-item-title>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-0">
      <v-spacer />
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <common-trs-btn
              class="primary--text"
              type="tertiary"
              dark
              text
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </common-trs-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="showViewBtn && !selecteddocument"
              :loading="loading || docLoading"
              @click="loadDocument(document)"
            >
              {{ document.signed_document_id ? 'View Signed' : 'View' }}
            </v-list-item>
            <common-common-preview-file-modal
              v-if="showViewBtn && selecteddocument && selecteddocument.signed_url"
              fullscreen
              :initial-visible="true"
              :title="CONSTS.DOCUMENT_TYPES_TEXT[document.document_type]"
              :url="selecteddocument.signed_url"
              :mime-type="selecteddocument.mime_type"
              :has-download-btn="true"
              :description="selecteddocument.description"
            >
              <template v-slot:activator="{ on }">
                <v-list-item
                  :loading="loading || docLoading"
                  v-on="on"
                >
                  {{ document.signed_document_id ? 'View Signed' : 'View' }}
                </v-list-item>
              </template>
            </common-common-preview-file-modal>
            <v-list-item
              v-if="showSignBtn"
              :loading="loading || signLoading"
              @click="fidDocSign(document)"
            >
              Sign
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-actions>
    <slot name="card-bottom-actions" />
  </v-card>
  <tr v-else-if="layout ==='table'">
    <slot name="table-content">
      <td>{{ document.document_name || document.document_type }}</td>
      <td v-if="oppDocument">
        <router-link
          :to="{ name: 'OpportunityDetail', params: { id: document.opportunity_id } }"
        >
          {{ document.opportunity_name }}
        </router-link>
      </td>
      <td>{{ formatDate(document.modified_on) }}</td>
      <td>{{ document.document_type }}</td>
      <td>
        <common-trs-btn
          v-if="showViewBtn && !selecteddocument"
          text
          small
          type="tertiary"
          class="primary--text"
          :loading="loading || docLoading"
          @click="loadDocument(document)"
        >
          {{ document.signed_document_id ? 'View Signed' : 'View' }}
        </common-trs-btn>
        <common-common-preview-file-modal
          v-if="showViewBtn && selecteddocument && selecteddocument.signed_url"
          fullscreen
          :initial-visible="true"
          :title="CONSTS.DOCUMENT_TYPES_TEXT[document.document_type]"
          :url="selecteddocument.signed_url"
          :mime-type="selecteddocument.mime_type"
          :has-download-btn="true"
          :description="selecteddocument.description"
        >
          <template v-slot:activator="{ on }">
            <common-trs-btn
              text
              small
              type="tertiary"
              class="primary--text"
              :loading="loading || docLoading"
              v-on="on"
            >
              {{ document.signed_document_id ? 'View Signed' : 'View' }}
            </common-trs-btn>
          </template>
        </common-common-preview-file-modal>
        <common-trs-btn
          v-if="showSignBtn"
          text
          small
          type="tertiary"
          class="primary--text"
          :loading="loading || signLoading"
          @click="fidDocSign(document)"
        >
          Sign
        </common-trs-btn>
      </td>
    </slot>
    <td>
      <slot name="table-actions" />
    </td>
  </tr>
  <v-row
    v-else-if="layout === 'tree-view'"
    align="center"
    no-gutters
  >
    <v-col cols="3">
      {{ document.document_name || document.document_type }}
    </v-col>
    <v-col cols="4">
      {{ $date(document.modified_on) }}
    </v-col>
    <v-col>
      <common-trs-btn
        v-if="showViewBtn && !selecteddocument"
        text
        small
        type="tertiary"
        class="primary--text"
        :loading="loading || docLoading"
        @click="loadDocument(document)"
      >
        {{ document.signed_document_id ? 'View Signed' : 'View' }}
      </common-trs-btn>
      <common-common-preview-file-modal
        v-if="showViewBtn && selecteddocument && selecteddocument.signed_url"
        fullscreen
        :initial-visible="true"
        :title="CONSTS.DOCUMENT_TYPES_TEXT[document.document_type]"
        :url="selecteddocument.signed_url"
        :mime-type="selecteddocument.mime_type"
        :has-download-btn="true"
        :description="selecteddocument.description"
      >
        <template v-slot:activator="{ on }">
          <common-trs-btn
            text
            small
            type="tertiary"
            class="primary--text"
            :loading="loading || docLoading"
            v-on="on"
          >
            {{ document.signed_document_id ? 'View Signed' : 'View' }}
          </common-trs-btn>
        </template>
      </common-common-preview-file-modal>
      <common-trs-btn
        v-if="showSignBtn"
        text
        small
        type="tertiary"
        class="primary--text"
        :loading="loading || signLoading"
        @click="fidDocSign(document)"
      >
        Sign
      </common-trs-btn>
    </v-col>
  </v-row>
</template>

<script>
  import { api } from '@/api'
  import moment from 'moment'

  export default {
    name: 'CommonDocumentListItem',

    props: {
      document: {
        type: Object,
        default: () => {},
      },
      layout: {
        type: String,
        validate: (val) => ['tree-view', 'card', 'table'].indexOf(val) !== -1,
        default: 'card',
      },
      type: {
        type: String,
        validate: (val) => ['my-document', 'fid-document'].indexOf(val) !== -1,
        default: 'my-document',
      },
      loading: {
        type: Boolean,
      },
    },
    data: () => ({
      selecteddocument: null,
      docLoading: false,
      signLoading: false,
    }),
    computed: {
      fidDocument () {
        return this.type === 'fid-document'
      },
      oppDocument () {
        return !!this.document.opportunity_id
      },
      showSignBtn () {
        return this.document.action_required === this.CONSTS.DOC_ACTION_REQUIRED.SIGNATURE_REQUIRED && !this.document.signed_document_id
      },
      showViewBtn () {
        if (this.type !== 'fid-document') { return true }
        if (!this.document.action_required) { return true }
        if (this.document.signed_document_id) { return true }
        if (this.document.action_required === this.CONSTS.DOC_ACTION_REQUIRED.REVIEW_REQUIRED) { return true }
        return false
      },
    },
    methods: {
      async fidDocSign (document) {
        this.signLoading = true
        try {
          const apiResponse = await api.get(`fiduciary-documents/${document.doc_id}/esign?entity_uid=${document.entity ? document.entity.trellis_uid : ''}`, null)
          console.log('apiResponse: ', apiResponse.data.url)
          this.$store.dispatch('app/loading', 'Redirecting...', { root: true })
          window.open(apiResponse.data.url, '_self')
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to sign document',
          })
        }
        this.signLoading = false
      },
      formatDate (value) {
        if (value) {
          return moment(String(value)).format('MM/DD/YYYY hh:mm')
        }
        return ''
      },
      async loadDocument (document) {
        this.docLoading = true
        try {
          let apiResponse = null
          switch (this.type) {
            case 'my-document':
              apiResponse = await api.get(`mydocuments/${document.doc_id}`, null, { base: 'tenant' })
              this.selecteddocument = apiResponse.data
              break
            case 'fid-document':
              apiResponse = document.signed_document_id
                ? await api.get(`mydocuments/${document.signed_document_id}`, null, { base: 'tenant' })
                : await api.get(`fiduciary-documents/${document.doc_id}?entity_uid=${document.entity ? document.entity.trellis_uid : ''}`, null)
              this.selecteddocument = document.signed_document_id
                ? apiResponse.data
                : apiResponse.data.fiduciary_document
              this.selecteddocument.description = apiResponse.data.description
              break
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load document',
          })
        }
        this.docLoading = false
      },
    },
  }
</script>
