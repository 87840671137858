<template>
  <v-list-item
    v-if="pemAllowed"
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="`trsBack ${!isDark ? 'primary' : 'trsText'}--text`"
    class="d-flex align-center pl-7"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text align-self-center"
      v-text="computedText"
    />

    <v-list-item-icon
      v-else-if="item.icon === 'trs-thumb'"
      class="align-self-center"
    >
      <common-trs-logos-thumb
        max-width="20"
        contain
        :percent-level="6"
      />
    </v-list-item-icon>

    <div v-else-if="item.icon">
      <v-list-item-icon
        v-if="!disableItemNavTooltip"
        class="align-self-center d-flex align-items-center"
      >
        <v-icon v-text="item.icon" />
      </v-list-item-icon>
      <v-tooltip
        v-else-if="disableItemNavTooltip"
        right
      >
        <template v-slot:activator="{ on }">
          <v-list-item-icon
            class="align-self-center"
            v-on="on"
          >
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </div>

    <v-list-item-avatar
      v-else-if="!!item.avatar"
      class="align-self-center"
      color="grey"
    >
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-avatar
      v-else-if="item.avatarText"
      class="align-self-center"
      color="primary"
    >
      <span class="white--text text-h5">{{ item.avatarText.split(' ').map(v => v.slice(0, 1)).join('').toUpperCase() }}</span>
    </v-list-item-avatar>

    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title v-text="item.title" />

      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'
  import permissionMixin from '@/components/core/mixins/permission'

  export default {
    name: 'Item',

    mixins: [Themeable, permissionMixin],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
      disableItemNavTooltip: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
    },
  }
</script>
<style lang="scss" scoped>
 .v-list-item {
   min-height: 35px;
   padding: 3px 0px;
   margin-bottom: 8px!important;
   border-radius: 0px!important;
   &.v-list-item--active {
    &::after{
       content: '';
       width: 4px;
       right: 0px;
       height: 100%;
       position: absolute;
       background-color:var(--v-primary-base)!important;
     }
    .v-list-item__title {
       color:var(--v-primary-base)!important;
       font-weight: 600!important;
    }
     .v-icon {
       color: var(--v-primary-base)!important;
     }
   }
    &:last-child {
      margin-bottom: 10px;
    }
   .v-list-item__icon {
     margin-right: 10px!important;
     .v-icon{
       font-size: 18px;
       color: var(--v-info-darken3);
     }
   }
    .v-list-item__content {
     padding: 9px 0;
      .v-list-item__title {
        cursor: pointer;
        font:normal 400 1rem/20px $font-family-base;
        color: var(--v-info-darken3);
        margin-bottom: 0rem;
        word-break: break-word;
        white-space: initial;
      }
    }
  }
  //side nav minimize css
  .v-navigation-drawer--mini-variant {
    .v-list {
      a {
        padding-left: 42px!important;
      }
      .v-list-item__icon {
        .v-icon {
          font-size: 25px!important;
          padding: 0.344rem;
        }
      }
    }
  }
</style>
