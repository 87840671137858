<template>
  <div />
</template>

<script>
  export default {
    name: 'BreadCrumb',

    props: {
      value: {
        required: true,
        validator: v => true,
      },
    },

    data: () => ({
    }),

    computed: {
    },

    watch: {
    },

    created () {
    },

    methods: {
    },
  }
</script>
