<template>
  <div>
    <common-common-preview-file-modal
      :title="title"
      :url="url"
      :mime-type="mimeType"
      :has-download-btn="hasDownloadBtn"
      fullscreen
      :loading="loading"
      @onModalOpenChanged="$emit('onModalOpenChanged', $event)"
    >
      <template v-slot:activator="{ on }">
        <slot
          name="activator"
          v-bind="{ on }"
        >
          <common-trs-a-link
            class="black--text text-body-2"
            v-on="on"
          >
            <v-icon
              v-if="isViewIcon"
              class="icon-eye"
            />
            <span
              v-else
              v-html="title"
            />
          </common-trs-a-link>
        </slot>
      </template>
    </common-common-preview-file-modal>
  </div>
</template>

<script>
  /**
    * Previews pdf or image file. Requires uil and mimeType
    */
  export default {
    name: 'BaseCommonPreviewFile',

    components: {
    },

    props: {
      title: {
        type: String,
        required: false,
      },
      url: {
        required: true,
      },
      mimeType: {
        type: String,
        default: '',
      },
      hasDownloadBtn: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isViewIcon: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
      }
    },

    computed: {
      filename () {
        return ''
      },
    },

    watch: {
    },

    methods: {
    },
  }
</script>

<style lang="scss" scoped>
  .preview-file {
    width: 100%;
  }

  .image-preview {

    img {
      max-width: 100%;
      max-width: 600px;
    }
  }
  .pdf-preview {
    width: 100%;
  }
</style>
