
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/common'
import './plugins/vee-validate'
import './plugins/vue-fragment'
import './plugins/vue-clipboard2'
import './plugins/vue-line-clamp'
import '@/components/common/plugins/vue-mask'
import './mixins/constants'
import i18n from './i18n'
import '@/components/common/plugins/video-plyr'
import './styles/custom.scss'
import { feature } from '@/components/common/directives/featureRbac'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  directives: { feature },
  render: h => h(App),
}).$mount('#app')
