<template>
  <div
    v-bind="{ ...$attrs }"
    v-on="$listeners"
  >
    <div
      v-if="!tooltip"
      v-line-clamp="lineClamp"
    >
      {{ text }}
    </div>
    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{on: tooltip }">
        <div
          v-line-clamp="lineClamp"
          class="line-clamp-div"
          v-bind="{ ...$attrs }"
          v-on="{...tooltip }"
        >
          {{ text }}
        </div>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'CommonTextTruncate',

    props: {
      text: {
        type: String,
        required: true,
      },
      tooltip: {
        type: Boolean,
        default: true,
      },
      lineClamp: {
        type: Number,
        default: 1,
      },
    },
  }
</script>
<style lang="scss" scoped>
.line-clamp-div {
  word-break: normal !important;;
}
</style>
