<template>
  <common-trs-btn
    type="secondary"
    text
    trs-icon
    x-small
    @click="onClick"
  >
    <v-icon
      small
      dark
    >
      mdi-filter
    </v-icon>
  </common-trs-btn>
</template>

<script>
  export default {
    name: 'CommonTrsFilterCardExpandBtn',

    props: {
      expanded: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
      }
    },

    methods: {
      onClick (ev) {
        ev.stopPropagation()
        ev.preventDefault()
        this.$emit('toggleExpanded')
        return true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-btn {
    margin-top: -1px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 3px!important;
  }
</style>
