<template>
  <v-list-group
    v-if="pemAllowed"
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
    :style="genStyle"
  >
    <template v-slot:activator>
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-avatar
        v-else-if="!!item.avatar"
        class="align-self-center"
        color="grey"
      >
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-avatar
        v-else-if="item.avatarText"
        class="align-self-center"
        color="primary"
      >
        <span class="white--text text-h5">{{ item.avatarText.split(' ').map(v => v.slice(0, 1)).join('').toUpperCase() }}</span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
        <v-list-item-subtitle
          v-if="item.subtitle"
          v-text="item.subtitle"
        />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <common-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        :pem-roles-allowed="child.pemRolesAllowed"
        :pem-roles-unallowed="child.pemRolesUnallowed"
        :pem-when-unallowed="child.pemWhenUnallowed"
      />

      <common-item
        v-else
        :key="`item-${i}`"
        :item="child"
        :pem-roles-allowed="child.pemRolesAllowed"
        :pem-roles-unallowed="child.pemRolesUnallowed"
        :pem-when-unallowed="child.pemWhenUnallowed"
        text
      />
    </template>
  </v-list-group>
</template>

<script>
  // Utilities
  import kebabCase from 'lodash.kebabcase'
  import { mapState } from 'vuex'
  import permissionMixin from '@/components/core/mixins/permission'

  export default {
    name: 'ItemGroup',
    mixins: [permissionMixin],

    inheritAttrs: false,

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
      subGroup: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState(['barColor']),
      genStyle () {
        return {
          backgroundColor: this.$route.fullPath.startsWith(this.item.group)
            ? this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].primary
            : 'inherit',
        }
      },
      children () {
        return this.item.children
          .filter(item => item !== null)
          .map(item => ({
            ...item,
            // to: !item.to ? undefined : `${this.item.group}/${item.to}`,
            to: `${this.item.group}/${item.to}`,
          }))
      },
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      group () {
        return this.genGroup(this.item.children)
      },
    },

    methods: {
      genGroup (children) {
        return children
          .filter(item => item && item.to)
          .map(item => {
            const parent = item.group || this.item.group
            let group = `${parent}/${kebabCase(item.to)}`

            if (item.children) {
              group = `${group}|${this.genGroup(item.children)}`
            }

            return group
          }).join('|')
      },
    },
  }
</script>

<style>
  .v-list-group__activator p {
    margin-bottom: 0;
  }
</style>
