var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_vm._t("table-content",[_c('td',[_vm._v(" "+_vm._s(_vm.$date(_vm.document.modified_on))+" ")]),(_vm.oppDocument)?_c('td',[_c('router-link',{staticClass:"font-weight-medium",attrs:{"to":{ name: 'DigitalRoadshow.Landing', params: { id: _vm.document.opportunity_id }, query: { mode: _vm.CONSTS.DR_MODES.PRESENTATION } },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.document.opportunity_name)+" ")])],1):_c('td',[_vm._v(" "+_vm._s(_vm.document.document_name || _vm.document.document_type)+" ")]),(!(_vm.tab === 0))?_c('td',[_vm._v(" "+_vm._s(_vm.CONSTS.DOCUMENT_TYPES_TEXT[_vm.document.document_type])+" ")]):_vm._e(),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.showViewBtn && !_vm.selecteddocument)?_c('common-trs-btn',_vm._g({staticClass:"primary--text rounded-sm mr-2",attrs:{"text":"","icon":"","type":"secondary","loading":_vm.loading || _vm.docLoading},on:{"click":function($event){return _vm.loadDocument(_vm.document)}}},tooltip),[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View'))])]),(_vm.showViewBtn && _vm.selecteddocument && _vm.selecteddocument.signed_url)?_c('common-common-preview-file-modal',{attrs:{"fullscreen":"","initial-visible":true,"title":_vm.CONSTS.DOCUMENT_TYPES_TEXT[_vm.document.document_type],"url":_vm.selecteddocument.signed_url,"mime-type":_vm.selecteddocument.mime_type,"has-download-btn":true,"description":_vm.selecteddocument.description},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('common-trs-btn',_vm._g({staticClass:"primary--text rounded-sm",attrs:{"text":"","small":"","icon":"","type":"secondary","loading":_vm.loading || _vm.docLoading}},Object.assign({}, on, tooltip)),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.document.signed_document_id ? 'View Signed' : 'View'))])])]}}],null,false,1510829589)}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.showSignBtn)?_c('common-trs-btn',_vm._g({staticClass:"white--text rounded-sm ml-2",attrs:{"text":"","icon":"","type":"primary","loading":_vm.loading || _vm.signLoading},on:{"click":function($event){return _vm.fidDocSign(_vm.document)}}},tooltip),[_c('v-icon',[_vm._v(" mdi-draw ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Sign")])])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }