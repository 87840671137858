/* eslint-disable */
import {
  extend,
} from 'vee-validate'

import {
  email,
  max,
  min,
  numeric,
  required,
  regex,
  confirmed,
  max_value,
  min_value,
  length,
  alpha_dash,
} from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'

extend ('alpha_dash', alpha_dash) // Need for UUID validation
extend('numeric', numeric)
extend('confirmed', {
  ...confirmed,
  message: '{_field_} does not match',
})
extend('required', {
  ...required,
  message: '{_field_} is required',
})
extend('regex', {
  ...regex,
  message: (fieldName, params) => {
    return `${fieldName} is invalid. (Regular Express - ${params.regex})`
  },
})

extend('length', {
  ...length,
  message: (fieldName, params) => {
    return `${fieldName} should be length of ${params.length}`
  },
})

extend('email', email)

extend('max', {
  ...max,
  message: (fieldName, params) => {
    return `${fieldName} should be at most ${params.length} characters`
  },
})

extend('min', {
  ...min,
  message: (fieldName, params) => {
    return `${fieldName} should be at least ${params.length} characters`
  },
})

extend('min_value', {
  validate: (value, params) => {
    if (value === null || typeof value === 'undefined' || isNaN(+value)) {
      return true
    }
    if (+value >= params[0]) {
      return true
    }
    return false
  },
  message: (fieldName, params) => {
    return `${fieldName} should be equals to or higher than ${params[0]}`
  },
})
extend('max_value', {
  validate: (value, params) => {
    if (value === null || typeof value === 'undefined' || isNaN(+value)) {
      return true
    }
    if (+value <= params[0]) {
      return true
    }
    return false
  },
  message: (fieldName, params) => {
    return `${fieldName} should be less than ${params[0]}`
  },
})

extend('less_than_ref', {
  validate: (value, params) => {
    if (value === null || typeof value === 'undefined' || value === '' || isNaN(+value)) {
      return true
    }
    if (+value < params[0]) {
      return true
    }
    return false
  },
  message: (fieldName, params, args) => {
    return isNaN()
      ? `${fieldName} is not valid`
      : `${fieldName} should be less than ${+params[0]}`
  },
})

extend('is_after_ref', {
  validate: (value, params) => {
    if (!moment(value).isValid() || !moment(params[0]).isValid()) {
      return true
    }
    if (moment(value).isAfter(params[0])) {
      return true
    }
    return false
  },
  message: (fieldName, params, args) => {
    return `${fieldName} should be after ${params[0]}`
  },
})

extend('valid-regex', {
  validate: value => {
    let isValid = true
    try {
      RegExp(value)
    } catch (e) {
      isValid = false
    }
    return isValid
  },
  message: '{_field_} is not valid RegExp',
})

extend('notOneOf', {
  validate: (value, params) => {
    return params.indexOf(value) === -1
  },
  message: '{_field_} should be unique',
})

extend('includeANumber', {
  params: [],
  validate (value) {
    return /\d/.test(value)
  },
  message: '{_field_} should include at least 1 number',
})

extend('includeASymbol', {
  params: [],
  validate (value) {
    // eslint-disable-next-line
    const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g
    return regex.test(value)
  },
  // eslint-disable-next-line
  message: `{_field_} should include at least 1 special character - !@#$%^&*()_+-=[]{};':"\\|,.<>/?`,
})

extend('includeAUpperCase', {
  params: [],
  validate (value) {
    const regex = /[A-Z]/
    return regex.test(value)
  },
  message: '{_field_} should include at least 1 upper case',
})

extend('includeALowerCase', {
  params: [],
  validate (value) {
    const regex = /[a-z]/
    return regex.test(value)
  },
  message: '{_field_} should include at least 1 lower case',
})

extend('isPhoneNumber', {
  params: [],
  validate (value) {
    const activeCountry = PhoneNumber(value).getRegionCode()
    if (!activeCountry) {
      return false
    }
    const result = PhoneNumber(value || "", activeCountry.iso2).toJSON();
    return result.valid
  },
  message: '{_field_} is not valid phone number',
})

extend('url', {
  validate (value) {
    const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    return regex.test(value)
  },
  message: '{_field_} is not url',
})

extend('isDateStandard', {
  validate (value) {
    const regex = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
    return regex.test(value)
  },
  message: '{_field_} should be MM/DD/YYYY',
})

extend('noNumbers', {
  validate (value) {
    const regex = /\d/
    return !regex.test(value)
  },
  message: '{_field_} should not include any numbers',
})

extend('noSymbols', {
  validate (value) {
    const regex = /^[a-z0-9 ,.'-]+$/i
    return regex.test(value)
  },
  message: '{_field_} should not include any special characters'
})

extend('isSSN', {
  validate (value) {
    const regex = /^\d{9}$/i
    return regex.test(value)
  },
  message: '{_field_} is not valid SSN'
})

extend('numberOnly', {
  validate (value) {
    const regex = /^\d*$/i
    return regex.test(value)
  },
  message: '{_field_} should be numbers only.'
})

extend('digitsOnly', {
  validate (value) {
    const regex = /^\d*$/i
    return regex.test(value)
  },
  message: '{_field_} should be numbers only.'
})
