<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-textarea
        v-bind="{ ...defaultAttrs, ...$attrs }"
        class="trs-input"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $slots"
          v-slot:[slot]
        >
          <slot
            :name="slot"
          />
        </template>
        <!-- https://github.com/vuetifyjs/vuetify/issues/11533 -->
        <template slot="label">
          <slot name="label" />
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputTextArea',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },
  }
</script>
