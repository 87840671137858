<template>
  <v-btn
    v-if="pemAllowed"
    class="ma-0"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-btn>
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    name: 'BaseBtn',
    mixins: [permissionMixin],
    props: {
    },

    data () {
      return {
        defaultAttrs: {
          elevation: 0,
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  button.v-btn {
    background-color: #f1f1f1 !important;
    text-transform: none;
    color: #b4b4b4 !important;
    border: 1px dashed #b4b4b4 !important;
  }
</style>
