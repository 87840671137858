import moment from 'moment'
import _ from 'lodash-core'
import { PORTAL } from '@/constants'
export { hasPermissionRoles } from '@/components/core/utils'

export function $dateLLLL (date) {
  return moment(date).isValid() ? moment(date).format('LLLL') : ''
}

export function $date (date) {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : ''
}

export function $dateFrom (date, from = moment()) {
  return moment(date).isValid() ? moment(date).from(from) : ''
}

export function $time (date) {
  return moment(date).isValid() ? moment(date).format('hh:mm a') : ''
}

export function $tradeTime (date) {
  return moment(date).isValid() ? moment(date).utc().format('hh:mm a') : ''
}

export function $humanFriendlyOpportunityStatus (status) {
  if (status === 'BIG_BOYS_SEEN') {
    return 'REVIEWED'
  }
  return status
}

export function $titlecase (value) {
  if (!value) {
    return ''
  }
  const str = `${value}`
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export function $uppercase (value) {
  if (!value) {
    return ''
  }
  return `${value}`.toUpperCase()
}

export function $number (num) {
  if (isNaN(num)) {
    return '0'
  }

  const formatter = new Intl.NumberFormat('en-US')
  return formatter.format(num)
}

export function $currency (num, currency = 'USD', decimals = 0) {
  if (isNaN(num)) {
    return '$0'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
  return formatter.format(num)
}

export function $currencyWithPrecision (num, currency = 'USD', decimals = 2) {
  if (isNaN(num)) {
    return '$0'
  }
  if (num >= 1000000) {
    return $formatThousandReadable(num)
  } else {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: decimals,
    })
    return formatter.format(num)
  }
}

export function $numberFormatter (value) {
  if (!value) {
    return ''
  }

  try {
    return (+value).toLocaleString('en-US')
  } catch (err) {
    return value
  }
}

export function $currencyFormatter (value) {
  if (!value) {
    return '$0'
  }
  if (value >= 1000000) {
    return $formatThousandReadable(value)
  } else {
    return $currency(value)
  }
}

export function $formatThousandReadable (value, currency = 'USD') {
  if (!value) {
    return '$0'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })

  if (value >= 1000000000000) {
    return `$${Number(value / 1000000000000).toFixed(
      2,
    )}T`
  } else if (value >= 1000000000) {
    return `$${Number(value / 1000000000).toFixed(
      2,
    )}B`
  } else if (value >= 1000000) {
    return `$${Number(value / 1000000).toFixed(
      2,
    )}MM`
  } else if (value >= 1000) {
    return `$${Number(value / 1000).toFixed(
      2,
    )}K`
  }

  return `${formatter.format(value)}`
}

export function $datetime (date) {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY hh:mm a') : ''
}

export function $formatAddr (addr) {
  let html = ''
  if (addr.address1) {
    html += addr.address1 + '<br />'
  }
  if (addr.address2) {
    html += addr.address2 + '<br />'
  }
  if (addr.address3) {
    html += addr.address3 + '<br />'
  }
  const line2 = `${addr.city ? addr.city + ' ' : ''}${addr.state ? addr.state + ' ' : ''}${addr.zip ? addr.zip + ' ' : ''}`
  html += line2 ? line2 + '<br/>' : ''
  const country = `${addr.country ? addr.country : ''}`
  html += country ? country + '<br/>' : ''

  return html
}

export function $getFileNameFromSignedUrl (signedUrl) {
  if (!signedUrl) {
    return ''
  }

  const decoded = decodeURIComponent(signedUrl)
  const url = decoded.split('?')[0]
  if (!url) {
    return ''
  }
  return url.split('/')[url.split('/').length - 1]
}

/**
  * key - x.y.z
  * value - any
  * result object[x][y][z] = value
  */
export function $setObjectDeepValue (obj, key, value) {
  if (!obj) {
    return
  }

  const func = (obj, key, value) => {
    const keys = key.split('.')
    if (keys.length === 0) {
      // return
    } else if (keys.length === 1) {
      obj[keys[0]] = value
      // return
    } else {
      if (!obj[keys[0]]) {
        obj[keys[0]] = {}
      }
      func(obj[keys[0]], keys.slice(1).join('.'), value)
    }
  }

  func(obj, key, value)
}

/**
  * key - x.y.z
  * value - any
  * result object[x][y][z] = value
  */
export function $getObjectDeepValue (obj, key) {
  if (!obj) {
    return null
  }

  const func = (obj, key) => {
    const keys = key.split('.')
    if (keys.length === 0) {
      return null
    } else if (keys.length === 1) {
      return obj[keys[0]]
    } else {
      if (!obj[keys[0]]) {
        return null
      }
      return func(obj[keys[0]], keys.slice(1).join('.'))
    }
  }

  return func(obj, key)
}

export function $getCommonFullAddrHtml (addr) {
  if (!addr) {
    return ''
  }
  let str = ''
  if (addr.line1) {
    str += `${addr.line1}`
    str += '<br />'
  }
  if (addr.line2) {
    str += `${addr.line2}`
    str += '<br />'
  }
  if (addr.line3) {
    str += `${addr.line3}`
    str += '<br />'
  }
  if (addr.city || addr.province || addr.postal) {
    str += `${addr.city ? addr.city + ', ' : ''}`
    str += `${addr.province && addr.province.name ? addr.province.name + ', ' : ''}`
    str += `${addr.postal ? addr.postal : ''}`
    str += '<br />'
  }
  if (addr.country && addr.country.name) {
    str += `${addr.country.name}`
    str += '<br />'
  }
  return str
}

export function $getCommonAddrStateCountry (addr) {
  if (!addr) {
    return ''
  }
  let str = ''
  if (addr.province && addr.province.name) {
    str += addr.province.name + ', '
  }
  if (addr.country && addr.country.name) {
    str += `${addr.country.name}`
  }
  return str
}

export function $getCommonAddrCityState (addr, sep = ' ') {
  if (!addr) {
    return ''
  }
  const arr = []
  if (addr.city) {
    arr.push(addr.city)
  }
  if (addr.province && addr.province.name) {
    arr.push(addr.province.name)
  }
  return arr.join(sep)
}

export function $greeting (username) {
  const now = new Date()
  const hrs = now.getHours()
  let msg = 'Hi,'

  if (hrs > 0) msg = 'Good Morning,'
  if (hrs > 12) msg = 'Good Afternoon,'
  if (hrs > 17) msg = 'Good Evening,'

  return `${msg} ${username}`
}

export function $isEmptyValue (val) {
  return val === null || val === undefined || val === ''
}

export function $getPortal () {
  return window.location.pathname.split('/')[1]
}

export function $getPublicBrandingHelpAddress (publicBranding = {}) {
  return publicBranding.help_address || 'help@trellisplatform.com'
}

export function $getPublicBrandingFooterInfo (publicBranding = {}) {
  const footerInfo = {
    contact: 'contact@trellishelp.zendesk.com',
    privacy_policy: 'https://s3.amazonaws.com/prod.static.trellisplatform.com/trellis_privacy.pdf',
    terms: 'https://s3.amazonaws.com/prod.static.trellisplatform.com/trellis_terms.pdf',
  }

  const portal = $getPortal()

  if (portal === PORTAL.ISSUER) {
    footerInfo.contact = publicBranding.issuer_contact || footerInfo.contact
    footerInfo.privacy_policy = publicBranding.issuer_privacy_policy || footerInfo.privacy_policy
    footerInfo.terms = publicBranding.issuer_terms || footerInfo.terms
  } else if (portal === PORTAL.INVESTOR) {
    footerInfo.contact = publicBranding.digital_locker_contact || footerInfo.contact
    footerInfo.privacy_policy = publicBranding.digital_locker_privacy_policy || footerInfo.privacy_policy
    footerInfo.terms = publicBranding.digital_locker_terms || footerInfo.terms
  } else if (portal === PORTAL.FID_ADMIN) {
    footerInfo.contact = publicBranding.tenant_contact || footerInfo.contact
    footerInfo.privacy_policy = publicBranding.tenant_privacy_policy || footerInfo.privacy_policy
    footerInfo.terms = publicBranding.tenant_terms || footerInfo.terms
  } else if (portal === PORTAL.TR_ADMIN) {
    footerInfo.contact = publicBranding.admin_contact || footerInfo.contact
    footerInfo.privacy_policy = publicBranding.admin_privacy_policy || footerInfo.privacy_policy
    footerInfo.terms = publicBranding.admin_terms || footerInfo.terms
  }

  return {
    ...footerInfo,
  }
}

export function $getAppliedFilter (value, availableKeys, hideFilters) {
  let applied = 0
  availableKeys.forEach(k => {
    const disableIf = k.disableIf || k.key
    if (hideFilters.indexOf(disableIf) === -1 && value[k.key]) {
      applied++
    }
  })

  return applied
}

export function $formTemplInputShortLabel (inputTempl) {
  return `${inputTempl.label} ${(inputTempl.mandatory || inputTempl.hideOptionalText) ? '' : '(optional)'}`
  // if (inputTempl.label) {
  //   if (inputTempl.label.length < 40) {
  //     return `${inputTempl.label} ${(inputTempl.mandatory || inputTempl.hideOptionalText) ? '' : '(optional)'}`
  //   } else {
  //     return ''
  //   }
  // }
}

export function $formTemplInputLongLabel (inputTempl) {
  return ''
  // if (inputTempl.label) {
  //   if (inputTempl.label.length >= 40) {
  //     return `${inputTempl.label} ${(inputTempl.mandatory || inputTempl.hideOptionalText) ? '' : '(optional)'}`
  //   } else {
  //     return ''
  //   }
  // } else {
  //   return ''
  // }
}

export function $openUrl (url, newTab = true) {
  if (newTab) {
    window.open(url, '_blank')
  } else {
    window.open(url, '_self')
  }
}

/*
 * change the dot-separated key to the last part of the key
 */
export function $extractDot (obj) {
  const newObj = {}
  for (const key in obj) {
    newObj[key.split('.')[key.split('.').length - 1]] = obj[key]
  }

  return newObj
}

/*
 * run extractDot for an array
 */
export function $extractDotFromArray (arr) {
  if (!arr || !Array.isArray(arr)) {
    return null
  }

  return arr.map($extractDot)
}

export function $fileSizeString (size) {
  const units = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb']
  let idx = 0
  while (size > 1024) {
    size = (size / 1024).toFixed(1)
    idx = idx + 1
  }
  return size + units[idx]
}

export function $processWithVisibility (key, availableHeaders) {
  var validateHeaders = []
  const viewColumns = this.$store.getters['tables/viewColumns'][key]
  if (viewColumns && viewColumns.length > 0) {
    for (let i = 0; i < viewColumns.length; i++) {
      if (availableHeaders.includes(viewColumns[i])) {
        validateHeaders.push(viewColumns[i])
      }
    }
  } else {
    validateHeaders = {}
  }
  return validateHeaders
}

export function $formatUrl (url) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
     return url
  } else {
    url = 'http://' + url
    return url
  }
}

export function $overwriteForms (formTempls, overwriteForms) {
  if (!overwriteForms) {
    return formTempls
  }

  return formTempls.map(formTempl => {
    const overwriteForm = overwriteForms.find(form => form.internal_name === formTempl.internal_name)

    if (!overwriteForm) {
      return formTempl
    } else if (overwriteForm.replaceAll) {
      formTempl = {
        ...formTempl,
        sections: overwriteForm.sections,
      }
    } else {
      formTempl.sections = formTempl.sections.map(formTemplSection => {
        if (!overwriteForm.sections) {
          return formTemplSection
        }

        const overwriteSection = overwriteForm.sections.find(sec => sec.key === formTemplSection.key)

        if (overwriteSection) {
          const { definition: overwriteDefinitions, ...rest } = overwriteSection

          formTemplSection = {
            ...formTemplSection,
            ...rest,
          }
          if (overwriteDefinitions) {
            formTemplSection.definition = formTemplSection.definition.map(formTemplDefinition => {
              const overwriteDefinition = overwriteDefinitions.find(def => def.id === formTemplDefinition.id)

              if (overwriteDefinition) {
                formTemplDefinition = {
                  ...formTemplDefinition,
                  ...overwriteDefinition,
                }
              }
              return formTemplDefinition
            })
          }
        }

        return formTemplSection
      })
    }

    return formTempl
  })
}

export function $lodashGet (object, path, fallback) {
  return _.get(object, path, fallback)
}
