var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-2 py-4",staticStyle:{"box-shadow":"0px 4px 10px rgba(0, 0, 0, 0.1)","height":"100%"}},[_c('draggable',{staticStyle:{"width":"100%"},attrs:{"options":{ handle:'.drag-handle' }},on:{"change":_vm.onMove},model:{value:(_vm.internalItems),callback:function ($$v) {_vm.internalItems=$$v},expression:"internalItems"}},_vm._l((_vm.internalItems),function(item,index){return _c('v-col',{key:index,staticClass:"pa-0 draggable-item py-3 px-4 d-flex justify-space-between align-center cursor-pointer",class:{
        'primary--text': index === _vm.internalValue
      },style:({
        'background' : index === _vm.internalValue ? _vm.backgroundColor : 'transparent'
      }),attrs:{"cols":"12"},on:{"click":function($event){_vm.internalValue = index}}},[_c('div',{staticClass:"muted indexing d-flex"},[(_vm.showDrag)?_c('v-icon',{staticClass:"drag-handle mr-2",class:{
            'primary--text': index === _vm.internalValue
          }},[_vm._v(" mdi-drag ")]):_vm._e(),_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}]},[_vm._t("label",null,null,{ item: item })],2)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.showDeleteItem)?_c('common-trs-btn',_vm._g({attrs:{"type":"tertiary","icon":""},on:{"click":function($event){return _vm.deleteItem($event, index)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)}),1),_vm._t("actions")],2)}
var staticRenderFns = []

export { render, staticRenderFns }