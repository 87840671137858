<template>
  <v-dialog
    v-model="internalDialog"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>

    <slot
      name="default"
      v-bind="{ close, internalDialog }"
    />

    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-dialog>
</template>

<script>
  export default {
    name: 'BaseCommonModal',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      controlVisibility: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalValue: false,
    }),

    computed: {
      internalDialog: {
        get () {
          return this.controlVisibility ? this.value : this.internalValue
        },
        set (val) {
          if (this.controlVisibility) {
            this.$emit('input', val)
          } else {
            this.internalValue = val
          }
        },
      },
    },

    watch: {
    },

    async created () {
    },

    methods: {
      close () {
        this.internalDialog = false
      },
    },
  }
</script>

<style lang="sass">
</style>
