<template>
  <v-autocomplete
    v-bind="{ ...defaultAttrs, ...$attrs }"
    class="trs-input"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'BaseInputAutocomplete',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          menuProps: { offsetY: true },
          attach: false,
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },

    mounted () {
    },
  }
</script>

<style lang="scss" scoped>
  .v-autocomplete {
    ::v-deep .v-select__slot v-input {
      overflow: visible;
      white-space: normal;
    }
  }

  ::v-deep .v-chip__content {
    text-transform: initial;
  }
  .v-input {
    ::v-deep .v-radio .v-label {
      height: auto;
    }
  }
  ::v-deep .v-list-item__title, .v-list-item__subtitle {
    white-space: initial;
    line-height: 1.6 !important;
  }
</style>
