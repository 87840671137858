<template>
  <v-card
    v-bind="$attrs"
    class="v-card--dashboard pa-4 d-flex flex-column elevation-0 rounded-0"
  >
    <div class="d-flex flex-wrap justify-space-between">
      <div>
        <slot name="heading" />
      </div>
      <v-sheet
        v-if="icon"
        :color="color"
        :height="50"
        :width="50"
        elevation="6"
        class="d-flex justify-center align-center text-start v-card--dashboard__heading"
        dark
      >
        <v-icon
          size="32"
          v-text="icon"
        />
      </v-sheet>
    </div>
    <slot />
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialCard',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      icon: {
        type: String,
        default: undefined,
      },
    },

    computed: {
    },
  }
</script>

<style lang="sass" scoped>
  .v-card--dashboard
    &__heading
      position: relative
      top: -25px
</style>
