export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  PENDING_ACKNOWLEDGEMENT: 'PENDING_ACKNOWLEDGEMENT',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED',
}

export const PAYMENT_STATUS_TEXT = {
  PENDING: 'Pending',
  SENT: 'Sent',
  PENDING_ACKNOWLEDGEMENT: 'Pending Acknowledgement',
  CONFIRMED: 'Confirmed',
  COMPLETED: 'Completed',
}
