export const SYSTEM_NOTIFICATION_TYPES = [
  'Announcement',
  'Incident',
  'Maintenance',
]
export const SYSTEM_NOTIFICATION_SEVERITIES = [
  'High Impact',
  'Severity 1',
  'Severity 2',
]

export const SYSTEM_NOTIFICATION_SEVERITIES_ITEMS = {
  HIGH_IMPACT: 'High Impact',
  SEVERITY_1: 'Severity 1',
  SEVERITY_2: 'Severity 2',
}

export const SYSTEM_NOTIFICATION_SEVERITIES_COLORS = {
  HIGH_IMPACT: '#EA7939',
  SEVERITY_1: '#232C56',
  SEVERITY_2: '#A1A1A1',
}

export const SYSTEM_NOTIFICATION_MODE = {
  ALERTS: 'alerts',
  COMMUNICATION: 'communication',
}
