<template>
  <v-dialog
    v-model="internalDialog"
    v-bind="$attrs"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>

    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          {{ title || 'Confirm' }}
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <common-page-loader v-if="loading" />
        <common-common-preview-file
          v-else
          :url="url"
          :mime-type="mimeType"
          :has-download-btn="hasDownloadBtn"
          :description="description"
        />
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'PreviewFileModal',

    components: {
    },

    props: {
      title: {
        type: String,
        default: 'Preview',
      },
      url: {
        required: true,
      },
      mimeType: {
        type: String,
        required: true,
      },
      hasDownloadBtn: {
        type: Boolean,
        default: false,
      },
      initialVisible: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      internalDialog: false,
    }),

    computed: {
    },

    watch: {
      internalDialog (val) {
        this.$emit('onModalOpenChanged', val)
      },
    },

    async created () {
    },

    mounted () {
      this.internalDialog = this.initialVisible
    },

    methods: {
      close () {
        this.internalDialog = false
      },
    },
  }
</script>

<style lang="scss">
  .v-dialog .v-card .v-card__title .v-icon.header-icon {
    top: 0px;
    left: 0px;
  }
</style>
