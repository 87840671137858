<template>
  <v-img
    :src="require(`./assets/finger-icon-${percentLevel}.svg`)"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  />
</template>

<script>
  export default {
    name: 'TrellisLogoThumb',

    props: {
      percentLevel: {
        type: Number,
        default: 1,
        validate: (val) => [1, 2, 3, 4, 5, 6].includes(val),
      },
    },

    data () {
      return {
        defaultAttrs: {},
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>
