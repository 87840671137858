<template>
  <v-card class="ma-0 pa-2">
    <slot />
  </v-card>
</template>

<script>
  import { VCard } from 'vuetify/lib'

  export default {
    name: 'Card',

    extends: VCard,
  }
</script>
