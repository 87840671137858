<template>
  <div class="breadcrumb">
    <span
      v-if="showBack"
      class="mr-3 cursor-pointer"
      @click="goBack"
    >
      <v-icon>
        mdi-keyboard-backspace
      </v-icon>
    </span>
    <span
      v-for="(item, idx) of items"
      :key="idx"
      @click="handleClick(item)"
    >
      <span
        v-if="idx > 0"
        class="mx-1"
      >/</span>
      <span
        :class="{
          'cursor-pointer': item.route
        }"
      >{{ item.label }}</span>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'Breadcrumb',
    props: {
      showBack: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    methods: {
      goBack () {
        this.$router.back()
      },
      handleClick (item) {
        if (item.route) {
          this.$router.push(item.route)
        }
        this.$emit('item-click', item)
      },
    },
  }
</script>
<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
}
</style>
