<template>
  <div
    v-if="pagination.pageCount"
    class="py-2 trs-paginator"
  >
    <div class="d-flex flex-row justify-end">
      <div
        class="d-flex align-center"
        :style="{ opacity: 0.8 }"
      >
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="trsText--text text-body-1 text--light-1 mr-5 white-space-nowrap"
        >
          {{ $t('core.rowsPerPage') }}
        </div>
        <div style="width: 50px">
          <common-input-select
            v-model="internalOptionsItemsPerPage"
            :items="itemsPerPageOptions"
            label="Items Per Page"
            hide-details
            single-line
            dense
            :filled="false"
          />
        </div>
      </div>
      <common-trs-pagination
        v-model="internalOptionsPage"
        :length="pagination.pageCount"
        :total-visible="5"
      />
    </div>
  </div>
</template>

<script>
  import { DATATABLE_DEFAULT_PAGINATION } from '@/constants'

  export default {
    name: 'CommonTrsDataTablePaginator',

    props: {
      pagination: {
        type: Object,
      },
      options: {
        type: Object,
      },
      itemsPerPageText: {
        type: String,
        default: 'Items Per Page',
      },
      itemsPerPageOptions: {
        type: Array,
        default: () => DATATABLE_DEFAULT_PAGINATION,
      },
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },

    computed: {
      internalOptionsItemsPerPage: {
        get () {
          return this.options.itemsPerPage
        },
        set (val) {
          this.$emit('update:options', {
            ...this.options,
            itemsPerPage: val,
            page: 1,
          })
        },
      },
      internalOptionsPage: {
        get () {
          return this.options.page
        },
        set (val) {
          this.$emit('update:options', {
            ...this.options,
            page: val,
          })
        },
      },
    },

    created () {
    },
  }
</script>
<style lang="scss" scoped>
.trs-paginator {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
