<template>
  <div
    class="d-flex align-center flex-column mt-4 mb-1"
    @click="$emit('navigate')"
  >
    <div
      v-if="profile.avatar"
      class="d-flex justify-center align-center mb-3"
    >
      <v-avatar
        size="40"
      >
        <v-img
          :src="profile.avatar"
        />
      </v-avatar>
    </div>

    <v-avatar
      v-else-if="profile.avatarText"
      class="align-self-center mb-3"
      color="primary"
      size="40"
    >
      <span class="white--text text-h5">
        {{ profile.avatarText.split(' ').map(v => v.slice(0, 1)).join('').toUpperCase() }}
      </span>
    </v-avatar>

    <!-- <div class="trsText--text text-body-2 font-weight-regular mt-2 px-2 text-center">
      Welcome {{ profile.avatarText }}!
    </div> -->
  </div>
</template>

<script>
  export default {
    name: 'CommonTrsNavAvatar',

    props: {
      profile: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>
