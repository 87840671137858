<template>
  <v-dialog
    v-model="internalValue"
    :max-width="500"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          {{ options.title || 'Confirm' }}
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="closeSimple"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <v-icon
          v-if="options.icon"
          :color="options.color"
          class="header-icon"
          size="30"
        >
          {{ options.icon.name }}
        </v-icon>
        <div
          v-if="options.message"
          style="word-break: break-word;"
          class="ma-3"
        >
          {{ options.message }}
        </div>
        <div
          v-if="options.note"
          style="word-break: break-word;"
          class="ma-3"
        >
          {{ options.note }}
        </div>
        <v-alert
          v-if="options.successAlert"
          class="px-2 py-2 mb-4"
          text
          color="success"
        >
          {{ options.successAlert }}
        </v-alert>
      </common-trs-modal-card-text>

      <common-trs-modal-card-text
        v-if="options.description"
        class="text-center"
      >
        <div
          style="word-break: break-word;"
          v-html="options.description"
        />
      </common-trs-modal-card-text>

      <common-trs-modal-card-actions>
        <div v-if="!options.noButton" />
        <common-trs-btn
          v-if="options.noButton"
          class="mr-3 primary--text"
          type="tertiary"
          text
          @click="close"
        >
          {{ options.noButton }}
        </common-trs-btn>

        <common-trs-btn
          v-if="options.yesButton"
          type="primary"
          text
          class="white--text"
          @click="onYes"
        >
          {{ options.yesButton }}
        </common-trs-btn>
      </common-trs-modal-card-actions>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  /**
    * opens simple modal box with yes and no optional buttons.
    * <common-app-confirm
        message="Are you sure?"
        yes-button="Yes"
        no-button="Cancel"
        :entity="internalValue"
        @yes="$emit('removeDefAt', index)"
      >
        <template v-slot:activator="{ on }">
          <common-trs-btn
            :disabled="disabled"
            color="error"
            icon
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </common-trs-btn>
        </template>
      </common-app-confirm>
    *
    Or another way
    *
      this.$root.$confirm({
        entity: null,
        message: '',
        description: 'Columns with skipped will not be able to import via csv. Please add those columns after csv is imported.',
        yesButton: 'Got it',
        maxWidth: 400,
        color: 'primary',
        icon: {
          name: 'mdi-information',
        },
      })
    */
  export default {
    props: {
      mode: {
        type: String,
        validate: (val) => ['inline', 'ref'].indexOf(val) !== -1,
        default: 'inline',
      },
      entity: {
        validator: v => true,
      },
      title: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      note: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      yesButton: {
        type: String,
      },
      noButton: {
        type: String,
      },
      maxWidth: {
        type: [Number, String],
        default: 300,
      },
      icon: {
        validate: (val) => true,
        default: () => null,
      },
      color: {
        type: String,
        default: 'primary',
      },
    },
    data () {
      return {
        internalValue: false,
        refOptions: {},
      }
    },

    computed: {
      options () {
        return this.mode === 'inline'
          ? this._props
          : this.refOptions
      },
    },

    watch: {
    },

    methods: {
      // {
      //   entity: null,
      //   message: '',
      //   yesButton: 'Ok',
      //   noButton: 'Cancel',
      //   maxWidth: 300,
      // }
      open (options) {
        this.internalValue = true
        this.refOptions = options

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      closeSimple (ev) {
        this.internalValue = false
        this.resolve && this.resolve(false)
      },
      close (ev) {
        this.internalValue = false
        this.$emit('close', ev)
        this.reject && this.reject()
      },
      onYes (ev) {
        this.internalValue = false
        this.$emit('yes', ev, this.entity)
        this.resolve && this.resolve(true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog .v-card .v-card__title .v-icon.header-icon {
    top: 0px;
    left: 0px;
  }
</style>
