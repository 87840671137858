// const theme = {
//   primary: '#023c56',
//   secondary: '#b0c4da',
//   accent: '#f4f4f4',
//   info: '#000000',
//   error: '#F10808',
//   success: '#1FC824',
//   warning: '#e6a818',
// }

export default {
  primary: '#009F46',
  secondary: '#0075A1',
  accent: '#f4f4f4',
  info: '#000000',
  error: '#E55957',
  success: '#009F46',
  warning: '#F6841B',

  trsText: {
    // Regular
    base: '#000000',
    // Dark
    darken1: '#000000',
    // Light
    lighten1: '#8A989B',
    // Gray
    lighten2: '#4F4D4D',
  },
  trsBack: {
    // mediumlight
    base: '#EDF2F3',
    // dark
    darken1: '#323A3C',
    // lighten1
    lighten1: '#EDF2F3',
    // lighten2
    lighten2: '#D6DDDF',
    // lighten3
    lighten3: '#FFFFFF',
  },
}
