<template>
  <v-simple-table
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </v-simple-table>
</template>

<script>
  export default {
    name: 'CommonTrsSimpleTable',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },
  }
</script>
