<template>
  <v-img
    :src="require(`./assets/finger-icon-${type}.svg`)"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    contain
    v-on="$listeners"
  />
</template>

<script>
  export default {
    name: 'TrellisFingerPrintIcon',

    props: {
      type: {
        type: String,
        default: '1',
      },
    },

    data () {
      return {
        defaultAttrs: {},
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>
