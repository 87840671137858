import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { hasPermissionRoles } from '@/components/core/utils'
import { USER_ROLES } from '@/constants'

Vue.use(Router)

const mainRoutes = [
  {
    path: '/pages',
    component: () => import('@/views/pages/Index'),
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: '/logout',
        name: 'Logout',
      },
      {
        name: 'AutoLogin',
        path: 'sso',
        component: () => import('@/views/pages/AutoLogin'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: '',
        path: '',
        redirect: { name: 'Captable' },
      },
      // JWT Expired
      {
        name: 'JwtExpired',
        meta: {
          title: 'JwtExpired',
        },
        path: '/sessionexpired',
        component: () => import('@/views/dashboard/captable/JwtExpired'),
      },
      // Dashboard
      {
        name: 'Dashboard',
        meta: {
          // title: 'Dashboard',
        },
        path: '',
        component: () => import('@/views/dashboard/dashboard/Index'),
      },

      // Users
      {
        name: 'Users.List',
        meta: {
          title: 'Users',
          roles: [
            USER_ROLES.TAPP_ADMIN,
          ],
        },
        path: '/captable-users',
        component: () => import('@/views/dashboard/users/UsersList'),
      },
      {
        name: 'Users.Create',
        meta: {
          // title: 'Create User',
          roles: [
            USER_ROLES.TAPP_ADMIN,
          ],
        },
        path: '/captable-users/create',
        component: () => import('@/views/dashboard/users/components/Create'),
      },
      {
        name: 'Users.Edit',
        meta: {
          // title: 'Edit User',
          roles: [
            USER_ROLES.TAPP_ADMIN,
          ],
        },
        path: '/captable-users/:id/edit',
        component: () => import('@/views/dashboard/users/components/Edit'),
      },

      {
        name: 'Organizations',
        meta: {
          // title: 'Organizations',
          roles: [
            USER_ROLES.TAPP_ADMIN,
          ],
        },
        path: 'organizations',
        component: () => import('@/views/dashboard/organizations/List'),
      },

      // Captable Admin
      {
        name: 'Captable',
        meta: {
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: 'captable',
        component: () => import('@/views/dashboard/captable/Index'),
      },
      // Securities
      {
        name: 'Securities',
        title: 'Securities',
        meta: {
          // title: 'Securities',
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
          ],
        },
        path: '/securities',
        component: () => import('@/views/dashboard/securities/Index'),
        children: [
          {
            name: '',
            path: '',
            redirect: { name: 'FinancingRound' },
          },
          {
            name: 'FinancingRound',
            title: 'Financing Round',
            meta: {
              // title: 'Financing Round',
            },
            path: 'financing-round',
            component: () => import('@/views/dashboard/securities/financingRound/Index'),
          },
          {
            name: 'ShareTerms',
            title: 'Share Terms',
            meta: {
              // title: 'Share Terms',
            },
            path: 'securities-terms',
            component: () => import('@/views/dashboard/securities/shareTerms/Index'),
          },
          {
          name: 'EquityIncentivePlans',
            title: 'Equity Incentive Plans',
            meta: {
              // title: 'Equity Incentive Plans',
            },
            path: 'eip',
            component: () => import('@/views/dashboard/securities/eip/Index'),
          },
          {
            name: 'IssuedSecurities',
            title: 'Issued Securities',
            meta: {
              // title: 'Issued Securities',
            },
            path: 'issued-securities',
            component: () => import('@/views/dashboard/securities/issuedSecurities/Index'),
          },
          {
            name: 'DraftSecurities',
            title: 'Draft Securities',
            meta: {
              // title: 'Draft Securities',
            },
            path: 'draft-securities',
            component: () => import('@/views/dashboard/securities/draftSecurities/Index'),
          },
          {
            name: 'ManageSecurities',
            title: 'Manage Securities',
            meta: {
              // title: 'Manage Securities',
            },
            path: 'manage',
            component: () => import('@/views/dashboard/securities/manage/Index'),
          },
        ],
      },
      {
        name: 'NewFinancingRound',
        title: 'Create Financing Round',
        meta: {
          // title: 'Create Financing Round',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/financing-round/create-financing-round',
        component: () => import('@/views/dashboard/securities/financingRound/Create'),
      },
      {
        name: 'EditFinancingRound',
        title: 'Edit Financing Round',
        meta: {
          // title: 'Edit Financing Round',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/financing-round/edit-financing-round',
        component: () => import('@/views/dashboard/securities/financingRound/Edit'),
      },
      {
        name: 'NewShareTerm',
        title: 'Create Share Term',
        meta: {
          // title: 'Create Share Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/create-share-term',
        component: () => import('@/views/dashboard/securities/shareTerms/CreateShareTerm'),
      },
      {
        name: 'NewConvertibleTerm',
        title: 'Create Convertible Term',
        meta: {
          // title: 'Create Convertible Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/create-convertible-term',
        component: () => import('@/views/dashboard/securities/shareTerms/CreateConvertibleTerm'),
      },
      {
        name: 'EditConvertibleTerm',
        title: 'Edit Convertible Term',
        meta: {
          // title: 'Edit Convertible Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/edit-convertible-term',
        component: () => import('@/views/dashboard/securities/shareTerms/EditConvertibleTerm'),
      },
      {
        name: 'EditShareTerm',
        title: 'Edit Share Term',
        meta: {
          // title: 'Edit Share Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/edit-share-term',
        component: () => import('@/views/dashboard/securities/shareTerms/Edit'),
      },
      {
        name: 'NewWarrantTerm',
        title: 'Create Warrant Term',
        meta: {
          // title: 'Create Warrant Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/create-warrant-term',
        component: () => import('@/views/dashboard/securities/shareTerms/CreateWarrantTerm'),
      },
      {
        name: 'EditWarrantTerm',
        title: 'Edit Warrant Term',
        meta: {
          // title: 'Edit Warrant Term',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/securities-terms/edit-warrant-term',
        component: () => import('@/views/dashboard/securities/shareTerms/EditWarrantTerm'),
      },
      {
        name: 'NewEIP',
        title: 'Create Equity Incentive Plans',
        meta: {
          // title: 'Create Equity Incentive Plans',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/eip/create-eip',
        component: () => import('@/views/dashboard/securities/eip/CreateEIP'),
      },
      {
        name: 'EditEIP',
        title: 'Edit Equity Incentive Plans',
        meta: {
          // title: 'Edit Equity Incentive Plans',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/eip/edit-eip',
        component: () => import('@/views/dashboard/securities/eip/EditEIP'),
      },
      {
        name: 'DraftWarrant',
        title: 'Draft Warrant',
        meta: {
          // title: 'Draft Warrant',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/draft-securities/draft-warrant',
        component: () => import('@/views/dashboard/securities/draftSecurities/draft/DraftWarrants'),
      },
      {
        name: 'DraftShare',
        title: 'Draft Share',
        meta: {
          // title: 'Draft Share',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/draft-securities/draft-share',
        component: () => import('@/views/dashboard/securities/draftSecurities/draft/DraftShares'),
      },
      {
        name: 'DraftOption',
        title: 'Draft Option',
        meta: {
          // title: 'Draft Option',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/draft-securities/draft-option',
        component: () => import('@/views/dashboard/securities/draftSecurities/draft/DraftOptions'),
      }, {
        name: 'DraftConvertible',
        title: 'Draft Convertible',
        meta: {
          // title: 'Draft Convertible',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/draft-securities/draft-convertible',
        component: () => import('@/views/dashboard/securities/draftSecurities/draft/DraftConvertibles'),
      },
      // manage - vesting Schedule
      {
        name: 'NewVestingSchedule',
        title: 'Create Vesting Schedule',
        meta: {
          // title: 'Create Vesting Schedule',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/manage/create-vesting-schedule',
        component: () => import('@/views/dashboard/securities/manage/vestingSchedule/CreateVestingSchedule'),
      },
      {
        name: 'EditVestingSchedule',
        title: 'Edit Vesting Schedule',
        meta: {
          // title: 'Edit Vesting Schedule',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'securities/manage/edit-vesting-schedule',
        component: () => import('@/views/dashboard/securities/manage/vestingSchedule/EditVestingSchedule'),
      },
      // Scenarios & Waterfall Analysis
      {
        name: 'Scenarios',
        title: 'Scenarios',
        path: 'scenarios',
        meta: {
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        component: () => import('@/views/dashboard/scenarios/Index'),
      },
      {
        name: 'AddScenarios',
        title: 'Add Scenarios',
        meta: {
          // title: 'Add Scenarios',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'scenarios/add-scenarios',
        component: () => import('@/views/dashboard/scenarios/AddScenarios'),
      },
      {
        name: 'EditScenarios',
        title: 'Edit Scenarios',
        meta: {
          // title: 'Edit Scenarios',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'scenarios/edit-scenarios',
        component: () => import('@/views/dashboard/scenarios/EditScenarios'),
      },
      {
        name: 'CompareScenarios',
        title: 'Compare Scenarios',
        meta: {
          // title: 'Compare Scenarios',
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: 'scenarios/compare-scenarios',
        component: () => import('@/views/dashboard/scenarios/CompareScenarios'),
      },
      {
        name: 'ScenarioFinancingRound',
        title: 'Financing Round',
        path: 'scenarios/new-financing-round',
        meta: {
          // title: 'Create Financing Round',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        component: () => import('@/views/dashboard/scenarios/components/NewFinancingRound'),
      },
      {
        name: 'EditScenarioFinancingRound',
        title: 'Edit Financing Round',
        path: 'scenarios/edit-financing-round',
        meta: {
          // title: 'Edit Financing Round',
          roles: [
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        component: () => import('@/views/dashboard/scenarios/components/EditFinancingRound'),
      },
      {
        name: 'RunWaterfall',
        title: 'Run Waterfall',
        path: 'scenarios/run-waterfall',
        meta: {
          // title: 'Run Waterfall',
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        component: () => import('@/views/dashboard/scenarios/components/RunWaterfall'),
      },
      // Holders Section
      {
        name: 'Holder.Dashboard',
        meta: {
          roles: [
            USER_ROLES.HOLDER,
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: '/holders-dashboard',
        component: () => import('@/views/dashboard/holders/dashboard/Index'),
      },
      {
        name: 'Holder.CaptableSummary',
        meta: {
          roles: [
            USER_ROLES.HOLDER,
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: '/holders-captable',
        component: () => import('@/views/dashboard/holders/captable/Index'),
      },
      {
        name: 'Holder.Holdings',
        meta: {
          roles: [
            USER_ROLES.HOLDER,
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: '/holders-holdings/:id/:type',
        component: () => import('@/views/dashboard/holders/holdings/Holdings'),
      },
      {
        name: 'Holder.HoldingsDetails',
        meta: {
          roles: [
            USER_ROLES.HOLDER,
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        path: '/holders-holding-details/:id/:type',
        component: () => import('@/views/dashboard/holders/securityDetails/Index'),
      },

      // Holders
      {
        name: 'ManageHolders',
        title: 'Holders',
        path: 'manage-holders',
        meta: {
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        component: () => import('@/views/dashboard/manage-holders/Index'),
      },
      {
        name: 'AddHolder',
        title: 'Invite a Holder',
        meta: {
          // title: 'Add Scenarios',
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'manage-holders/invite-holder',
        component: () => import('@/views/dashboard/manage-holders/InviteHolder'),
      },

      // Service Requests
      {
        name: 'ServiceRequests',
        title: 'Service Requests',
        path: 'service-requests',
        meta: {
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
            USER_ROLES.CAPTABLE_EDITOR,
            USER_ROLES.CAPTABLE_VIEWER,
          ],
        },
        component: () => import('@/views/dashboard/service-requests/Index'),
      },

      // Captable User Access Control
      {
        name: 'SettingsAccess',
        title: 'Manage users and their roles',
        meta: {
          title: 'Manage users and their roles',
        },
        path: 'access',
        component: () => import('@/views/dashboard/settings/Index'),
      },
      {
        name: 'SettingsAccess.CreateUser',
        path: 'access/create',
        meta: {
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        component: () => import('@/views/dashboard/settings/AddCaptableUser'),
      },
      {
        name: 'SettingsAccess.details',
        meta: {
          title: 'User Details',
          roles: [
            USER_ROLES.CAPTABLE_ADMIN, USER_ROLES.CAPTABLE_SUPER_ADMIN,
          ],
        },
        path: 'access/details',
        component: () => import('@/views/dashboard/settings/UserDetails'),

        children: [
          {
            name: '',
            path: '',
            redirect: { name: 'UserProfileDetails' },
          },
          {
            name: 'UserProfileDetails',
            meta: {
              title: 'Manage users and their roles',
            },
            path: 'profile',
            component: () => import('@/views/dashboard/settings/components/UserProfile'),
          },
          {
            name: 'UserAccess',
            meta: {
              title: 'Manage users and their roles',
            },
            path: 'access',
            component: () => import('@/views/dashboard/settings/components/UserAccess'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/pages/Index'),
    name: '404',
    children: [
      {
        name: '404 Error',
        path: '',
        component: () => import('@/views/pages/Error'),
      },
    ],
  },
]

let routes = []
routes = mainRoutes

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((m) => m.meta.requiresAuth)) {
    if ((!store.getters['auth/cyndxAccessToken'] && !store.getters['auth/trellisAccessToken']) && to.name !== 'Login') {
      await store.dispatch('auth/loginCaptable')

      if (!store.getters['auth/cyndxAccessToken']) {
        return next({
          name: 'Login',
          query: {
            redirect: to.fullPath,
          },
        })
      }
    }

    const hasPermission = hasPermissionRoles(to.meta.roles, store.getters['auth/roles'])
    if (!hasPermission) {
      return next({ name: '404 Error' })
    }
  }

  if (to.name === 'Logout') {
    store.dispatch('auth/logout')
    return next({
      name: 'Login',
      query: {
        redirect: from.fullPath,
      },
    })
  }

  return next()
})

export default router
