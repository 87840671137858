<template>
  <div>
    <div
      v-if="alerts.length > 0 || notifications.length > 0 || systemNotificationCount > 0"
      class="justify-space-between d-flex"
    >
      <v-tabs v-model="currentTab">
        <v-tab
          key="actions"
          class="text-capitalize"
          @click="$emit('see-all', false)"
        >
          Actions ({{ alerts.length }})
        </v-tab>
        <v-tab
          key="notifications"
          class="text-capitalize"
          @click="$emit('see-all', false)"
        >
          Notifications ({{ notifications.length }})
        </v-tab>
        <v-tab
          v-if="systemNotificationCount"
          key="system-notifications"
          class="text-capitalize"
          @click="$emit('see-all', false)"
        >
          System Notifications ({{ systemNotificationCount }})
        </v-tab>
        <div
          class="primary--text text-subtitle-1 font-weight-regular mt-3 ml-auto mr-4 pr-4"
        >
          <div
            v-if="showAllBtn"
            class="cursor-pointer ttrsText--text text-caption primary--text font-weight-medium"
            @click="goToSeeAll"
          >
            See All
          </div>
        </div>

        <v-tabs-items
          v-model="currentTab"
          class="pa-5"
        >
          <v-divider class="mt-3" />
          <v-tab-item key="actions">
            <v-simple-table class="notification-table">
              <tbody>
                <tr
                  v-for="(alert, index) of filteredAlerts"
                  :key="index"
                  class="need-attention-item"
                >
                  <td class="trsText--text text-caption font-weight-regular mr-2">
                    {{ alert.message }}
                  </td>

                  <td class="trsText--text text-caption font-weight-regular">
                    <div
                      class="cursor-pointer primary--text font-weight-medium px-2"
                      @click="$emit('alert-selected', alert)"
                    >
                      {{ alert.action }}
                    </div>
                    <div>{{ $date(alert.timestamp) }}</div>
                  </td>
                </tr>
                <tr
                  v-if="alerts.length === 0"
                  class="alert-table"
                >
                  <td class="trsText--text text-h5 text-center mx-3">
                    You have no actions
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item key="notifications">
            <v-simple-table class="notification-table">
              <tbody>
                <tr
                  v-for="(alert, index) of filteredNotifications"
                  :key="index"
                  class="need-attention-item cursor-pointer"
                  @click="$emit('alert-selected', alert)"
                >
                  <td class="trsText--text text-caption font-weight-regular">
                    {{ alert.message }}
                  </td>

                  <td class="trsText--text text-caption font-weight-regular px-2">
                    <div>{{ $date(alert.timestamp) }}</div>
                  </td>
                </tr>
                <tr
                  v-if="notifications.length === 0"
                  class="alert-table"
                >
                  <td class="trsText--text text-h5 text-center mx-3">
                    You have no notifications
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item
            v-if="systemNotificationCount"
            key="system-notifications"
          >
            <div
              v-for="(item, index) in filteredSystemNotifications"
              :key="index"
              class="notification-table"
            >
              <v-list-item
                class="pa-0"
                @click="goToSeeAll"
              >
                <template v-slot:default="{ }">
                  <v-list-item-icon class="mr-1">
                    <v-icon :color="severityColor(item.severity)">
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="trsText--text text-caption font-weight-bold"
                      v-text="item.title"
                    />

                    <v-list-item-subtitle
                      class="trsText--text text-caption font-weight-regular"
                      v-text="item.message_details.details"
                    />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      class="trsText--text text-caption font-weight-regular px-2"
                      v-text="$datetime(item.reported_at)"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                :key="index"
              />
            </div>

            <div
              v-if="systemNotifications.length === 0"
              class="alert-table text-center pa-3"
            >
              You have no system notifications
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <div
      v-else
      class="alert-table"
    >
      <div class="trsText--text text-h5 text-center ma-5">
        You have no notifications
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'NeedsAttention',

    props: {
      alerts: {
        type: Array,
        required: true,
        default: () => [],
      },
      notifications: {
        type: Array,
        default: () => [],
      },
      systemNotifications: {
        type: Array,
        default: () => [],
      },
      systemNotificationCount: {
        type: Number,
        default: 0,
      },
      dateField: {
        type: String,
        default: 'created_on',
      },
      seeAllAlerts: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        showAll: false,
        currentTab: 0,
        showAllBtn: true,
      }
    },
    watch: {
      currentTab: {
        handler (val) {
          this.showAllBtn = false
          if (val === 2 && this.systemNotificationCount > 3) {
            this.showAllBtn = true
          }
          if (val === 1 && this.notifications && this.notifications.length > 3) {
            this.showAllBtn = true
          }
          if (val === 0 && this.alerts && this.alerts.length > 3) {
            this.showAllBtn = true
          }
        },
        immediate: true,
      },
    },
    computed: {
      filteredAlerts () {
        return this.showAll ? this.alerts : this.alerts.slice(0, 3)
      },
      filteredNotifications () {
        return this.showAll ? this.notifications : this.notifications.slice(0, 3)
      },
      filteredSystemNotifications () {
        return this.showAll ? this.systemNotifications : this.systemNotifications.slice(0, 3)
      },
    },
    methods: {
      goToSeeAll () {
        this.$emit('see-all', true)
        this.$emit('hideContent')
        this.$router.push({ name: this.seeAllAlerts, query: { tab: this.currentTab } })
      },
      severityColor (severity) {
        let color = ''
        switch (severity) {
          case this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_ITEMS.HIGH_IMPACT:
            color = this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_COLORS.HIGH_IMPACT
            break
          case this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_ITEMS.SEVERITY_1:
            color = this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_COLORS.SEVERITY_1
            break
          case this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_ITEMS.SEVERITY_2:
            color = this.CONSTS.SYSTEM_NOTIFICATION_SEVERITIES_COLORS.SEVERITY_2
            break
          default:
            color = '#A1A1A1'
        }
        return color
      },
    },
  }
</script>
<style lang="scss" scoped>
  .mdi-alert-circle-outline {
    font-size: 16px;
  }
  ::v-deep.notification-table {
    width: 565px;
  }
</style>
