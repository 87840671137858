/** THIS IS COMMON CODE. PLEASE DO NOT CHANGE WITHOUT DISCUSSION WITH THE TEAM **/
// eslint-disable

import * as jwt from 'jsonwebtoken'
import router from '@/router'
import { CAPTABLE_SOURCES_TYPES } from '@/constants'

// initial state
const initialState = {
  user: {},
  permissions: [],
  cyndxAccessToken: '',
  trellisAccessToken: '',
  capTableId: '',
  isCaptableAdmin: false,
  tokenDecoded: {},
  refreshToken: '',
  roles: [],
  currentOrganization: {},
  currentFidId: '',
  holderCaptableList: [],
  source: '',
  holder: '',
  isDirectLogin: '',
  isSuperAdmin: false,
  selectedCaptableHolder: [],
}

// getters
const getters = {
  email: state => state.email,
  user: state => state.user,
  refreshToken: state => state.refreshToken,
  cyndxAccessToken: state => state.cyndxAccessToken,
  trellisAccessToken: state => state.trellisAccessToken,
  tokenDecoded: state => state.tokenDecoded,
  roles: state => state.roles,
  capTableId: state => state.capTableId,
  isCaptableAdmin: state => state.isCaptableAdmin,
  currentOrganization: state => state.currentOrganization,
  currentFidId: state => state.currentFidId,
  holderCaptableList: state => state.holderCaptableList,
  source: state => state.source,
  holder: state => state.holder,
  isDirectLogin: state => state.isDirectLogin,
  isSuperAdmin: state => state.isSuperAdmin,
  selectedCaptableHolder: state => state.selectedCaptableHolder,
}

// actions
const actions = {
  async loginCaptable ({ commit, state, dispatch }, credentials) {
    if (credentials && !credentials.force && state.refreshToken) {
      return
    }
    try {
      commit('app/setLoading', true, { root: true })
      const source = state.source
      let cyndxToken = {}
      if (source === CAPTABLE_SOURCES_TYPES.DL) {
        cyndxToken = { access_token: atob(router.history.current.query.token) }
      } else if (source === CAPTABLE_SOURCES_TYPES.ISSUER) {
        cyndxToken = { external_token: atob(router.history.current.query.token) }
      }
      const captableData = JSON.parse(atob(router.history.current.query.data))

      commit('app/setLoading', false, { root: true })

      dispatch('setAuthInfo', cyndxToken)
      dispatch('setCaptableId', captableData?.issuer?.id)
      dispatch('setCurrentOrganization', captableData?.issuer)
      dispatch('setUser', captableData?.user)
      dispatch('setHolder', captableData?.user)

      return cyndxToken
    } catch (error) {
      commit('app/setLoading', false, { root: true })
      throw error
    }
  },
  setAuthInfo ({ commit }, data) {
    commit('setCyndxAccessToken', data.external_token)
    commit('setTrellisAccessToken', data.access_token)
  },
  setCaptableId ({ commit }, data) {
    commit('setCaptableId', data)
  },
  setIsCaptableAdmin ({ commit }, data) {
    commit('setIsCaptableAdmin', data)
  },
  setCurrentOrganization ({ commit }, data) {
    commit('setCurrentOrganization', data)
  },
  setUser ({ commit }, data) {
    commit('setUser', data)
  },
  setRole ({ commit }, data) {
    commit('setRole', data)
  },
  async logout ({ commit }) {
    commit('setUser', {})
    commit('setCyndxAccessToken', '')
    commit('setTrellisAccessToken', '')
    commit('setCaptableId', '')
    commit('setRefreshToken', '')
    commit('setPermissions', [])
    commit('theme/RESET', null, { root: true })
    commit('account/RESET', null, { root: true })
    commit('setCurrentFidId', '')
    commit('setHolderCaptableList', [])
    commit('setHolder', {})
    commit('setRole', [])
    commit('setSelectedCaptableHolder', [])
  },
  setCurrentFidId ({ commit }, data) {
    commit('setCurrentFidId', data)
  },
  setHolderCaptableList ({ commit }, list) {
    commit('setHolderCaptableList', list)
  },
  setSource ({ commit }, data) {
    commit('setSource', data)
  },
  setHolder ({ commit }, data) {
    commit('setHolder', data)
  },
  setSelectedCaptableHolder ({ commit }, data) {
    commit('setSelectedCaptableHolder', data)
  },
  setIsDirectLogin ({ commit }, data) {
    commit('setIsDirectLogin', data)
  },
  setIsSuperAdmin ({ commit }, data) {
    commit('setIsSuperAdmin', data)
  },
}

// mutations
const mutations = {
  setCyndxAccessToken (state, data) {
    state.cyndxAccessToken = data
  },
  setTrellisAccessToken (state, data) {
    state.trellisAccessToken = data
    state.tokenDecoded = jwt.decode(state.trellisAccessToken)
  },
  setRefreshToken (state, data) {
    state.refreshToken = data
  },
  setPermissions (state, data) {
    state.permissions = data
    state.roles = data.roles
  },
  setUser (state, data) {
    state.user = data
  },
  setRole (state, roles) {
    state.roles = roles
  },
  setCaptableId (state, data) {
    state.capTableId = data
  },
  setIsCaptableAdmin (state, data) {
    state.isCaptableAdmin = data
  },
  setCurrentOrganization (state, data) {
    state.currentOrganization = data
  },
  setCurrentFidId (state, fidId) {
    state.currentFidId = fidId
  },
  setHolderCaptableList (state, list) {
    state.holderCaptableList = list
  },
  setSource (state, data) {
    state.source = data
  },
  setHolder (state, data) {
    state.holder = data
  },
  setIsDirectLogin (state, data) {
    state.isDirectLogin = data
  },
  setIsSuperAdmin (state, data) {
    state.isSuperAdmin = data
  },
  setSelectedCaptableHolder (state, data) {
    state.selectedCaptableHolder = data
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
