<template>
  <v-checkbox
    v-model="internalValue"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: 'BaseInputCheckbox',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        defaultAttrs: {
          offIcon: 'mdi-checkbox-blank-outline',
          onIcon: 'mdi-checkbox-outline',
          color: 'primary',
        },
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', !!val)
        },
      },
    },

    watch: {
    },

    created () {
    },
  }
</script>
