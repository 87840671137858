<template>
  <div
    class="d-flex align-center"
  >
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <div class="ml-2 flex-fill">
      <div class="text-body-2 font-weight-light">
        {{ label }}
      </div>
      <div class="text-h4 font-weight-normal">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InfoItem',

    components: {
    },

    props: {
      icon: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      detailTab: null,
    }),
  }
</script>
