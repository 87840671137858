<template>
  <div>
    <input
      v-show="false"
      ref="fileInput"
      :disabled="loading"
      type="file"
      :accept="allowedFileTypes"
      @change="uploadFile"
    >
    <slot
      :openFileDialog="openFileDialog"
      :filename="name"
      :loading="loading"
      :progress="progress"
    />
  </div>
</template>
<script>
  export default {
    name: 'CommonInputFileButton',
    props: {
      uploadFunction: {
        type: Function,
        required: false,
      },
      successCallback: {
        type: Function,
        default: () => {},
      },
      fileType: {
        type: String,
        required: true,
      },
      shouldUpload: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      loading: false,
      progress: 0,
      name: '',
      allowedFileTypes: [],
    }),
    computed: {
      fileTypeMsg () {
        const foundFileType = this.CONSTS.FILE_TYPES_DISPLAY.find(type => type.value === this.fileType)
        return foundFileType ? foundFileType.exts : this.CONSTS.FILE_TYPE_DEFAULT.exts
      },
    },
    watch: {
      fileType: {
        handler (val) {
          if (val) {
            const types = this.CONSTS.FILE_MIME_TYPES.filter((obj) => {
              if (obj.type === val) {
                return obj
              }
            })
            this.allowedFileTypes = types[0] && types[0].value
          }
        },
        immediate: true,
      },
    },
    methods: {
      openFileDialog () {
        this.$refs.fileInput.click()
      },
      async uploadFile (event) {
        const file = event.target.files[0]
        if (file && this.fileType && this.allowedFileTypes.includes(file.type)) {
          if (this.shouldUpload) {
            await this.upload(file)
          } else {
            this.$emit('changed', file)
            event.target.files = []
          }
        } else {
          this.name = null
          this.$store.dispatch('app/handleError', {
            message: `Please upload ${this.fileTypeMsg} files only`,
          })
        }
      },
      async upload (file) {
        this.loading = true
        try {
          const progressCallback = (progress) => {
            this.progress = progress
          }
          this.name = file.name
          const res = await this.uploadFunction(file, progressCallback)
          if (this.successCallback) {
            this.successCallback(res)
          }
        } catch (err) {
          // console.log(err)
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to upload file',
          })
        }
        this.loading = false
      },
    },
  }
</script>
