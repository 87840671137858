<template>
  <!-- Communication popup -->
  <common-trs-nav-nav-popup
    v-if="!isViewer && portal!==CONSTS.PORTAL.TR_ADMIN"
    :icon-name="'icon-chat-messages trsText--text'"
    :tooltip-title="'Communication'"
  >
    <template v-slot:content="{ hideContent }">
      <communication-list-wrapper
        v-if="portal !== CONSTS.PORTAL.FID_ADMIN"
        :pre-filters="{ has_response: true }"
        :show-create-item="false"
        :hide-header="true"
        :see-all-communications="{ name: 'Communication' }"
        :notification="true"
        :base="portal === CONSTS.PORTAL.INVESTOR ? 'tenant' : ''"
        class="px-3"
        @hideContent="hideContent"
      >
        <template v-slot:item="{ item, update, remove }">
          <communication-dense-item
            :item="item"
            :show-reply="true"
            :can-reply="true"
            :see-all-communications="{ name: 'Communication' }"
            @update="update"
            @remove="remove"
            @hideContent="hideContent"
          />
        </template>
      </communication-list-wrapper>
      <v-tabs
        v-if="portal === CONSTS.PORTAL.FID_ADMIN"
        v-model="currentTab"
      >
        <v-tab
          key="answers"
        >
          Investor Communication
        </v-tab>
        <v-tab
          key="questions"
        >
          My Communications
        </v-tab>

        <v-tabs-items
          v-model="currentTab"
          class="transparent"
        >
          <v-tab-item
            key="answers"
          >
            <fid-communication-list-wrapper
              :pre-filters="{ has_response: false }"
              :show-create-item="false"
              :hide-header="true"
              :see-all-communications="{ name: 'Communication'}"
              :notification="true"
              :base="portal === CONSTS.PORTAL.INVESTOR ? 'tenant' : ''"
              class="px-3"
              @hideContent="hideContent"
            >
              <template v-slot:item="{ item, update, remove }">
                <communication-dense-item
                  :item="item"
                  :show-reply="true"
                  :can-reply="true"
                  :see-all-communications="{ name: 'Communication' }"
                  @update="update"
                  @remove="remove"
                  @hideContent="hideContent"
                />
              </template>
            </fid-communication-list-wrapper>
          </v-tab-item>
          <v-tab-item
            key="questions"
          >
            <communication-list-wrapper
              :pre-filters="{ has_response: true }"
              :show-create-item="false"
              :hide-header="true"
              :see-all-communications="{ name: 'Communication', query: { tab: 1 } }"
              :notification="true"
              :base="portal === CONSTS.PORTAL.INVESTOR ? 'tenant' : ''"
              class="px-3"
              @hideContent="hideContent"
            >
              <template v-slot:item="{ item, update, remove }">
                <communication-dense-item
                  :item="item"
                  :show-reply="true"
                  :can-reply="true"
                  :see-all-communications="{ name: 'Communication', query: { tab: 1 } }"
                  @update="update"
                  @remove="remove"
                  @hideContent="hideContent"
                />
              </template>
            </communication-list-wrapper>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </template>
  </common-trs-nav-nav-popup>
</template>
<script>
  // Utilities
  import CommunicationListWrapper from '@/components/common/communication/ListWrapper'
  import FidAdminListWrapper from '@/components/common/communication/FidAdminListWrapper'

  import CommunicationDenseItem from '@/components/common/communication/DenseItem'
  export default {
    name: 'DashboardCoreAppBar',

    components: {
      CommunicationDenseItem,
      CommunicationListWrapper,
      FidCommunicationListWrapper: FidAdminListWrapper,
    },

    props: {
      isViewer: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      currentTab: null,
    }),

    computed: {
      portal () {
        return this.$getPortal()
      },

    },

    methods: {
    },
  }
</script>
<style lang="scss" scoped>
</style>
