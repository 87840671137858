// import Vue from 'vue'
import { hasPermissionRoles } from '@/components/core/utils'
export const PERM_WHEN_ALLOWED = {
  HIDE: 'hide',
  SHOW_ALT: 'show_alt',
}

const permissionMixin = {
  props: {
    pemRolesAllowed: {
      type: Array | null,
      default: () => (null),
    },
    pemRolesUnallowed: {
      type: Array | null,
      default: () => (null),
    },
    // 'show_alt'
    pemWhenUnallowed: {
      type: String,
      default: PERM_WHEN_ALLOWED.HIDE,
    },
  },

  data: function () {
    return {
    }
  },

  methods: {
  },

  computed: {
    pemCurrentRoles () {
      return this.$store.getters['auth/roles']
    },
    pemAllowed () {
      let isAllowed = false
      if (this.pemRolesAllowed) {
        isAllowed = isAllowed || hasPermissionRoles(this.pemRolesAllowed, this.pemCurrentRoles)
      } else if (this.pemRolesUnallowed) {
        isAllowed = isAllowed || !hasPermissionRoles(this.pemRolesUnallowed, this.pemCurrentRoles)
      } else {
        isAllowed = true
      }

      return isAllowed
    },
  },
}

export default permissionMixin
