<template>
  <span
    v-if="pemAllowed"
    :class="`${name} trs-icon`"
    :style="computedStyle"
  >
    <span class="path1" />
    <span class="path2" />
    <span class="path3" />
    <span class="path4" />
    <span class="path5" />
    <span class="path6" />
    <span class="path7" />
    <span class="path8" />
    <span class="path9" />
    <span class="path10" />
  </span>
</template>

<script>
  import permissionMixin from '@/components/core/mixins/permission'
  export default {
    name: 'CommonTrsIcon',
    mixins: [permissionMixin],
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: [Number, String],
        default: () => 16,
      },
      color: {
        type: String,
        default: 'primary',
      },
    },

    data: () => ({
      sizes: {
        small: 12,
        medium: 16,
        large: 24,
      },
    }),

    computed: {
      computedStyle () {
        const styles = {}
        styles['font-size'] = this.sizes[this.size] || `${this.size}px`

        const colors = this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? 'dark' : 'light']
        styles.color = colors[this.color] || this.color
        return styles
      },
    },
  }
</script>
<style lang="scss" scoped>
  .trs-icon:before {
    color: currentColor;
  }
  .trs-icon .path1:before {
    color: currentColor;
  }
  .trs-icon .path2:before {
    color: currentColor;
  }
  .trs-icon .path3:before {
    color: currentColor;
  }
  .trs-icon .path4:before {
    color: currentColor;
  }
  .trs-icon .path5:before {
    color: currentColor;
  }
  .trs-icon .path6:before {
    color: currentColor;
  }
  .trs-icon .path7:before {
    color: currentColor;
  }
  .trs-icon .path8:before {
    color: currentColor;
  }
  .trs-icon .path9:before {
    color: currentColor;
  }
  .trs-icon .path10:before {
    color: currentColor;
  }
  .trs-icon .path11:before {
    color: currentColor;
  }
</style>
