<template>
  <v-select
    v-bind="{ ...defaultAttrs, ...$attrs }"
    class="trs-input"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
    <!-- <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template> -->
    <!-- https://github.com/vuetifyjs/vuetify/issues/11533 -->
    <template slot="label">
      <slot name="label" />
    </template>
  </v-select>
</template>

<script>
  export default {
    name: 'BaseInputTextField',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          menuProps: { offsetY: true },
          attach: false,
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-select {
    ::v-deep .v-select__selection--comma {
      overflow: visible;
      white-space: normal;
    }
  }

  ::v-deep .v-chip__content {
    text-transform: initial;
  }
  .v-input {
    ::v-deep .v-radio .v-label {
      height: auto;
    }
  }
</style>
