<template>
  <div
    class="logo-icon d-inline-block"
    :style="{backgroundColor: backgroundColor}"
  >
    <v-img
      :src="require(`./assets/logo-${type}.svg`)"
      v-bind="{ ...defaultAttrs, ...$attrs }"
      contain
      v-on="$listeners"
    />
  </div>
</template>

<script>
  export default {
    name: 'TrellisLogo',

    props: {
      type: {
        type: String,
        default: 'black-1',
      },
      background: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        defaultAttrs: {},
      }
    },

    computed: {
      backgroundColor () {
        return this.background || this.type === 'white-1' || (this.type === 'white-2' ? '#5D686B' : 'white')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .logo-icon {
    padding: 4px 15px;
  }
</style>
