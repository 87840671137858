<template>
  <v-tabs
    v-model="internalValue"
    class="mt-2 px-5"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
  </v-tabs>
</template>
<script>
  export default {
    name: 'TrsPageNavHorizontal',

    props: {
      value: {
        validate: () => true,
      },
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-tabs {
    border-bottom: 1px solid var(--v-trsBack-base);
  }
  ::v-deep .v-tab {
    text-transform: initial;
    &:hover, &:active, &:focus {
      background: transparent !important;
    }
  }
</style>
