<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    computed: {
      userId () {
        return this.$store.getters['auth/userId']
      },
    },

    watch: {
      userId: {
        handler (val) {
          if (val) {
            this.loadInitData()
          }
        },
        immediate: true,
      },
      $route: {
        handler () {
          setTimeout(() => {
            if (document.getElementById('trs-page-container')) {
              document.getElementById('trs-page-container').scrollIntoView()
            }
          }, 100)
        },
        immediate: true,
      },
    },

    created () {
      // this.$store.dispatch('app/initApp')
    },

    methods: {
      loadInitData () {
        // this.$store.dispatch('app/initAuthData', true, { root: true })
      },
    },
  }
</script>
