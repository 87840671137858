<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      class="mb-1"
    >
      <common-input-label
        class="text-left"
        :class="{ 'error--text': $attrs['hide-details'] && $attrs['error-messages'] && $attrs['error-messages'].length > 0 }"
      >
        <slot name="label">
          <span v-if="$attrs.label">{{ $attrs.label }}</span>
        </slot>
      </common-input-label>
    </v-col>

    <v-col cols="12">
      <v-radio-group
        v-model="internalValue"
        v-bind="{ ...defaultAttrs, ...$attrs }"
        label=""
        class="mt-0"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $slots"
          v-slot:[slot]
        >
          <slot
            v-if="slot !== 'label'"
            :name="slot"
          />
        </template>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputRadioGroup',

    props: {
      value: {
        validate: () => true,
      },
    },

    data () {
      return {
        defaultAttrs: {
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', !!val)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-select {
    ::v-deep .v-select__selection--comma {
      overflow: visible;
      white-space: normal;
    }
  }
  .v-input {
    ::v-deep .v-radio .v-label {
      height: auto;
    }
  }
</style>
