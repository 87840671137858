<template>
  <a
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </a>
</template>

<script>
  export default {
    name: 'ALink',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
</style>
