<template>
  <common-input-autocomplete
    v-model="internalValue"
    :clearable="clearable"
    cache-items
    v-bind="$attrs"
    :chips="!!$attrs.multiple"
    :items="allItems"
    :loading="loading"
    :item-text="itemText"
    :item-value="itemValue"
    :search-input.sync="search"
    @update:search-input="updateSearch"
    @focus="querySelections(search)"

    @keydown="keydown"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
  </common-input-autocomplete>
</template>

<script>
  // import { mapState } from 'vuex'
  import debounce from 'lodash.debounce'
  import { api } from '@/api'
  export default {
    name: 'BaseAppEntityItemSelector',

    props: {
      value: {
        required: true,
        validator: v => true,
      },
      entity: {
        type: String,
        required: true,
      },
      isOnApprovalModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      include: {
        type: String,
        enum: (val) => ['common', 'both', 'tenant'].indexOf(val) !== -1,
        default: 'tenant',
      },
      itemText: {
        type: [String, Function],
        default: 'name',
      },
      itemValue: {
        type: [String, Function],
        default: 'id',
      },
      searchItemTextKey: {
        type: String,
        default: '',
      },
      preFilters: {
        type: Object,
        default: () => ({}),
      },
      useQuery: {
        type: Boolean,
        default: true,
      },
      itemTransformFunc: {
        type: [Function, null],
        default: null,
      },
      additionalFieldsToQuery: {
        type: Array,
        default: () => ([]),
      },
      clearable: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      search: null,
      items: [],
      loading: false,
      selectedItems: [],
    }),

    computed: {
      allItems () {
        return this.items.concat(this.selectedItems)
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          try {
            // this process collecte the object of currently selected items
            if (!Array.isArray(val)) {
              throw Error('Not an array')
            }

            const newSelectedItems = [...this.selectedItems]

            const originSelectedIds = newSelectedItems.map(it => it[this.itemValue])
            const selectedIds = this.$attrs.returnObject
              ? this.internalValue.map(it => it[this.itemValue])
              : this.internalValue

            originSelectedIds.forEach((id, index) => {
              if (selectedIds.indexOf(id) === -1) {
                const indexToRemove = newSelectedItems.findIndex(it => it[this.itemValue] === id)
                newSelectedItems.splice(indexToRemove, 1)
              }
            })
            selectedIds.forEach((id, index) => {
              const indexToAdd = newSelectedItems.findIndex(it => it[this.itemValue] === id)
              if (indexToAdd === -1) {
                const newItem = this.items.find(it => it[this.itemValue] === id)
                if (newItem) {
                  newSelectedItems.push(newItem)
                }
              }
            })

            this.selectedItems = newSelectedItems
          } catch (err) {
            // pass
          }
          this.$emit('input', val)
        },
      },
    },

    watch: {
      search: {
        handler: debounce(function (val) {
          this.querySelections(val)
        }, 500),
      },
      include (val) {
        this.search = ''
        this.items = []
        this.querySelections(this.search)
      },
    },
    created () {
      this.querySelections('')
    },

    methods: {
      updateSearch (value) {
        // pass
      },
      keydown (event) {
        if (event.keyCode === 32) {
          event.preventDefault()

          this.search += ' '
        }
      },

      async querySelections (v) {
        this.loading = true
        const promises = []

        let query = ''
        if (typeof this.itemText === 'string' && typeof this.itemValue === 'string') {
          let additionalQuery = ''
          // For addtional field to load when it's return_object
          if (this.additionalFieldsToQuery.length > 0) {
            additionalQuery = ', ' + this.additionalFieldsToQuery.join(', ')
          }
          query = `{${this.itemText}, ${this.itemValue}${additionalQuery}}`
        }

        const searchItemTextKey = this.searchItemTextKey
          ? this.searchItemTextKey
          : (typeof this.itemText === 'string' ? this.itemText : 'name')
        const params = this.isOnApprovalModal ? {
          params: {
            page: 1,
            limit: 1000,
            ...this.preFilters,
          },
        }
          : {
            params: {
              page: 1,
              limit: 20,
              [searchItemTextKey]: v,
              ...this.preFilters,
            },
          }
        if (this.useQuery) {
          params.params.query = query
        }
        if (this.include === 'common' || this.include === 'both') {
          promises.push(api
            .get(`${this.entity}`, params, { base: 'common' })
            .then(result => {
              return result.data.results.map(item => ({
                ...(this.itemTransformFunc ? this.itemTransformFunc(item) : item),
                source: 'common',
              }))
            }),
          )
        }
        if (this.include !== 'both') {
          promises.push(api
            .get(`${this.entity}`, params, { base: this.include })
            .then(result => {
              return result.data.results.map(item => ({
                ...(this.itemTransformFunc ? this.itemTransformFunc(item) : item),
                source: '',
              }))
            }),
          )
        }
        const apiResponse = await Promise.all(promises)
        this.items = apiResponse.reduce((r1, r2) => r1.concat(r2), [])
        this.loading = false
      },
    },
  }
</script>
