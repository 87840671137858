<template>
  <div>
    <v-overlay
      :value="internalDialog"
      dark
    />
    <v-dialog
      v-model="internalDialog"
      v-bind="$attrs"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="false"
      scrollable
      v-on="$listeners"
      @input="$emit('change', $event)"
    >
      <template v-slot:activator="{ on }">
        <slot
          name="activator"
          v-bind="{ on }"
        />
      </template>

      <!-- Let's show the default modal if title is specified -->
      <common-trs-modal-card
        v-if="title"
        class="d-flex flex-column"
      >
        <common-trs-modal-card-title>
          <common-trs-modal-card-title-content>
            {{ title }}
          </common-trs-modal-card-title-content>

          <common-trs-btn
            aria-label="Close"
            type="secondary"
            class="rounded-0"
            color="primary"
            size="large"
            icon
            text
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </common-trs-btn>
        </common-trs-modal-card-title>
        <div class="flex-fill d-flex flex-column">
          <slot
            v-bind="{ close, internalDialog }"
          />
        </div>
      </common-trs-modal-card>

      <!-- If not, let it customize its way -->
      <slot
        v-if="!title"
        name="default"
        v-bind="{ close, internalDialog }"
      />

      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]
      >
        <slot :name="slot" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'BaseCommonSideModal',

    components: {
    },

    props: {
      title: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Boolean,
        default: false,
      },
      controlVisibility: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalValue: false,
    }),

    computed: {
      internalDialog: {
        get () {
          return this.controlVisibility ? this.value : this.internalValue
        },
        set (val) {
          if (this.controlVisibility) {
            this.$emit('input', val)
          } else {
            this.internalValue = val
          }
        },
      },
    },

    watch: {
      internalDialog: {
        handler (val) {
          this.$emit('internalDialogChanged', val)
        },
        immediate: true,
      },
      show: {
        handler (val) {
          this.internalDialog = val
        },
        immediate: true,
      },
    },

    async created () {
    },

    methods: {
      close () {
        this.internalDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .common-side-modal {
    min-width: 320px;
    width: 30vw;
    justify-self: flex-end;
    right: 0;
    left: auto;
    @media (max-width: 780px) {
      width: 100vw;
    }
  }
  ::v-deep .v-sheet.v-card {
    box-shadow:none
  }
  ::v-deep .v-dialog {
    background: white;
  }
</style>
