<template>
  <div>
    <pdf
      :id="source.pageUuid + source.page"
      :src="source.pdfdata"
      :page="source.page"
      :scale.sync="scale"
      :annotation="true"
      :resize="true"
      @link-clicked="source.handle_pdf_link"
    >
      <template slot="loading">
        <v-progress-circular
          indeterminate
        />
      </template>
    </pdf>
  </div>
</template>

<script>
  import pdfvuer from 'pdfvuer'

  export default {
    name: 'PdfViewerPage',

    components: {
      pdf: pdfvuer,
    },

    props: {
      source: {
        type: Object,
        default () {
          return {}
        },
      },

      // pageUuid: {
      //   type: String,
      //   required: true,
      // },
      // pdfdata: {
      //   validate: () => true,
      //   required: true,
      // },
      // page: {
      //   type: Number,
      //   required: true,
      // },
      // // eslint-disable-next-line vue/prop-name-casing
      // handle_pdf_link: {
      //   validate: () => true,
      //   required: true,
      // },
    },
    data () {
      return {
        scale: 'page-width',
      }
    },

    computed: {
    },

    watch: {
    },

    methods: {
    },
  }
</script>
