// eslint-disable
import { api } from '@/api'

// initial state
const initialState = {
  loading: false,
  initialized: false,
  entities: [],
}

// getters
const getters = {
  loading: state => state.loading,
  initialized: state => state.initialized,
  entities: state => state.entities,
}

// actions
const actions = {
  // TODO: REMOVE THIS UNUSED
  async loadEntities ({ commit, state }, params) {
    try {
      commit('setLoading', true)
      commit('setEntities', [])
      const axiosResponse = await api.get('data-entities', {
        page: 1,
        limit: 1000000,
        query: '{id, name}',
      }, { base: params.isCommon ? 'common' : '' })
      commit('setEntities', axiosResponse.data.results)
      commit('setLoading', false)
    } catch (error) {
      commit('setLoading', false)
      throw error
    }
  },
  async loadEntityData ({ commit, state }, params) {
    try {
      const axiosResponse = await api.get(`data-entities/${params.entity}`, null, { base: params.isCommon ? 'common' : '' })
      return axiosResponse.data.value
    } catch (error) {
      commit('setLoading', false)
      throw error
    }
  },
}

// mutations
const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  setEntities (state, entities) {
    state.entities = entities
    state.initialized = true
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
