
<template>
  <common-input-text-field
    v-model="internalValue"
    type="time"
  />
  <!-- <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <common-input-text-field
        v-model="internalValue"
        type="time"
        v-bind="attrs"
        v-on="on"
      ></common-input-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="temp"
      full-width
      @click:minute="internalValue = temp"
    ></v-time-picker>
  </v-menu> -->
</template>

<script>
  /**
    * Previews pdf or image file. Requires uil and mimeType
    */
  export default {
    name: 'CommonInputInlineTimePicker',

    components: {
    },

    props: {
      value: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        menu: false,
        temp: '',
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      menu (val) {
        if (val) {
          this.temp = this.internalValue
        }
      },
    },

    methods: {
    },
  }
</script>
