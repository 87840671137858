<template>
  <v-row no-gutters>
    <cropper-modal
      ref="cropperModal"
      :min-crop-box-height="minCropBoxHeight"
      :min-crop-box-width="minCropBoxWidth"
    />
    <v-col
      cols="12"
      class="mb-1"
    >
      <common-input-label
        class="text-left"
        :class="{ 'error--text': $attrs['hide-details'] && $attrs['error-messages'] && $attrs['error-messages'].length > 0 }"
      >
        <slot name="label">
          <span v-if="$attrs.label">{{ $attrs.label }}</span>
        </slot>
      </common-input-label>
    </v-col>

    <!-- :class="`${dragHighlight !==0 ? 'drag-highlight' : ''} avatar-container`" -->
    <v-col
      cols="12"
      :class="`avatar-container`"
    >
      <input
        v-show="!hideInput"
        ref="fileInput"
        type="file"
        :accept="allowedFileTypes"
        @change="uploadFile"
      >
      <slot
        name="preview"
        :loading="loading"
        :progress="progress"
        :file="file"
        :url="internalValue"
        :openFileDialog="openFileDialog"
      >
        <div>
          <common-common-preview-file-line-wrapper
            v-if="internalValue"
            title="Uploaded Image"
            :mime-type="'image/png'"
            :url="internalValue"
            :has-download-btn="true"
          >
            <template v-slot:activator="{ on }">
              <v-img
                v-if="internalValue"
                contain
                :src="internalValue"
                height="200"
                max-width="200"
                :loading="loading"
                class="mt-2"
                v-on="on"
              />
            </template>
          </common-common-preview-file-line-wrapper>

          <div
            v-else
            class="my-2"
          >
            No Image
          </div>

          <common-trs-btn
            text
            outlined
            type="secondary"
            small
            block
            class="primary--text mt-2"
            @click="openFileDialog"
          >
            <v-icon v-if="!loading">
              mdi-upload
            </v-icon>
            <div v-if="!loading">
              Upload New
            </div>
            <v-progress-circular
              v-if="loading"
              :size="30"
              :rotate="270"
              :width="5"
              :value="progress"
              color="primary"
            >
              <span style="font-size: 10px">{{ progress.toFixed(0) }}</span>
            </v-progress-circular>
          </common-trs-btn>
        </div>
      </slot>
      <v-icon
        v-if="clearable && value && !publicImage"
        class="avatar-close"
        @click="removeAvatar()"
      >
        mdi-close
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
  import { uploadStaticAsset } from '@/components/core/services/common'
  import CropperModal from '@/components/core/image-cropper/CropperModal'
  import fileDragMixin from '../mixins/file-drag'

  export default {
    name: 'BaseInputFile',

    components: {
      CropperModal,
    },

    mixins: [fileDragMixin],

    props: {
      value: {
        validate: () => true,
      },
      hideInput: {
        type: Boolean,
        default: true,
      },
      cropSettings: {
        type: Object,
        default: () => {},
        // doCrop: boolean,
        // aspectW: number,
        // aspectH: number
      },
      fileType: {
        type: String,
        required: true,
      },
      apiBase: {
        stype: String,
        default: 'common',
      },
      minCropBoxHeight: {
        type: String,
        default: '200',
      },
      minCropBoxWidth: {
        type: String,
        default: '200',
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      publicImage: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        loading: false,
        file: null,
        progress: 0,
        allowedFileTypes: [],
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      fileTypeMsg () {
        switch (this.fileType) {
          case this.CONSTS.FILE_TYPES.DOCUMENT: return 'PDF'
          case this.CONSTS.FILE_TYPES.IMAGE: return 'JPEG / PNG'
          case this.CONSTS.FILE_TYPES.DATA: return 'excel sheet / CSV'
          case this.CONSTS.FILE_TYPES.ZIP: return 'ZIP'
          case this.CONSTS.FILE_TYPES.ALL_DOCS: return 'PDF / excel '
          default: return 'PDF'
        }
      },
    },
    watch: {
      fileType: {
        handler (val) {
          if (val) {
            const types = this.CONSTS.FILE_MIME_TYPES.filter((obj) => {
              if (obj.type === val) {
                return obj
              }
            })
            this.allowedFileTypes = types[0] && types[0].value
          }
        },
        immediate: true,
      },
      clearable: function (newVal, oldVal) {
        if (!newVal) {
          this.internalValue = ''
        }
      },
    },
    methods: {
      openFileDialog () {
        this.$refs.fileInput.click()
      },
      async uploadFile (event) {
        const file = event.target.files[0]
        if (file && this.fileType && this.allowedFileTypes.includes(file.type)) {
          await this.upload(file)
        } else {
          this.name = null
          this.$store.dispatch('app/handleError', {
            message: `Please upload ${this.fileTypeMsg} files only`,
          })
        }
      },
      async upload (finalFile) {
        if (this.cropSettings && this.cropSettings.doCrop) {
          try {
            finalFile = await this.$refs.cropperModal.open({
              file: finalFile,
            })
          } catch (err) {
            // handle error
            console.log('cropping cancelled', err)
            return
          }
        }
        this.loading = true
        try {
          const progressCallback = (progress) => {
            this.progress = progress
          }
          const res = await uploadStaticAsset(finalFile, this.apiBase, progressCallback)
          this.internalValue = res.url
        } catch (err) {
          // console.log(err)
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to upload file',
          })
        }
        this.loading = false
      },
      removeAvatar () {
        this.clearable = false
      },
    },
  }
</script>
<style lang="scss" scoped>
.drag-highlight {
  box-shadow: 0 0 2px 2px var(--v-primary-base);
  position: relative;
  input {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }
}

  .avatar-container {
    position: relative;
  }

  .avatar-close {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
