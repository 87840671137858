<template>
  <vue-doc-preview
    :value="encodeURIComponent(uri)"
    type="office"
  />
</template>

<script>
  import VueDocPreview from 'vue-doc-preview'
  export default {
    name: 'DocViewer',
    components: {
      VueDocPreview,
    },
    props: {
      uri: {
        type: String,
        required: true,
      },
    },
  }
</script>
