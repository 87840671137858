<template>
  <v-container
    class="trs-page-container"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
  >
    <div
      :class="`mx-${margin}`"
      :style="{
        background: background,
        padding: `${padding}px`,
      }"
    >
      <slot />
      <slot name="page-content" />
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'CommonTrsPageContainer',

    props: {
      background: {
        type: String,
        default: '#FFF',
      },
      padding: {
        type: Number,
        default: 24,
      },
      margin: {
        type: Number,
        default: 2,
      },
    },
  }
</script>
<style lang="scss" scoped>
  .trs-page-container {
    & > div {
      border-radius: 4px;
    }
    max-width: 1300px !important;
    margin-top: 16px;
    padding: 0px;
  }
</style>
