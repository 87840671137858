
export const INVESTOR_ROLES = {
  INVESTOR: 'fiduciary_investor',
  VIEWER: 'fiduciary_viewer',
  INVESTOR_ADMIN: 'fiduciary_investor-admin',
}
export const INVESTOR_ROLES_NAMES = {
  fiduciary_investor: 'investor',
  fiduciary_viewer: 'viewer',
 'fiduciary_investor-admin': 'admin',
}
