var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isViewer && _vm.portal!==_vm.CONSTS.PORTAL.TR_ADMIN)?_c('common-trs-nav-nav-popup',{attrs:{"icon-name":'icon-chat-messages trsText--text',"tooltip-title":'Communication'},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var hideContent = ref.hideContent;
return [(_vm.portal !== _vm.CONSTS.PORTAL.FID_ADMIN)?_c('communication-list-wrapper',{staticClass:"px-3",attrs:{"pre-filters":{ has_response: true },"show-create-item":false,"hide-header":true,"see-all-communications":{ name: 'Communication' },"notification":true,"base":_vm.portal === _vm.CONSTS.PORTAL.INVESTOR ? 'tenant' : ''},on:{"hideContent":hideContent},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var update = ref.update;
var remove = ref.remove;
return [_c('communication-dense-item',{attrs:{"item":item,"show-reply":true,"can-reply":true,"see-all-communications":{ name: 'Communication' }},on:{"update":update,"remove":remove,"hideContent":hideContent}})]}}],null,true)}):_vm._e(),(_vm.portal === _vm.CONSTS.PORTAL.FID_ADMIN)?_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{key:"answers"},[_vm._v(" Investor Communication ")]),_c('v-tab',{key:"questions"},[_vm._v(" My Communications ")]),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{key:"answers"},[_c('fid-communication-list-wrapper',{staticClass:"px-3",attrs:{"pre-filters":{ has_response: false },"show-create-item":false,"hide-header":true,"see-all-communications":{ name: 'Communication'},"notification":true,"base":_vm.portal === _vm.CONSTS.PORTAL.INVESTOR ? 'tenant' : ''},on:{"hideContent":hideContent},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var update = ref.update;
var remove = ref.remove;
return [_c('communication-dense-item',{attrs:{"item":item,"show-reply":true,"can-reply":true,"see-all-communications":{ name: 'Communication' }},on:{"update":update,"remove":remove,"hideContent":hideContent}})]}}],null,true)})],1),_c('v-tab-item',{key:"questions"},[_c('communication-list-wrapper',{staticClass:"px-3",attrs:{"pre-filters":{ has_response: true },"show-create-item":false,"hide-header":true,"see-all-communications":{ name: 'Communication', query: { tab: 1 } },"notification":true,"base":_vm.portal === _vm.CONSTS.PORTAL.INVESTOR ? 'tenant' : ''},on:{"hideContent":hideContent},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var update = ref.update;
var remove = ref.remove;
return [_c('communication-dense-item',{attrs:{"item":item,"show-reply":true,"can-reply":true,"see-all-communications":{ name: 'Communication', query: { tab: 1 } }},on:{"update":update,"remove":remove,"hideContent":hideContent}})]}}],null,true)})],1)],1)],1):_vm._e()]}}],null,false,3460359448)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }