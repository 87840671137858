<template>
  <common-trs-card
    v-bind="$attrs"
    class="pa-3 rounded-1"
  >
    <div
      class="d-flex justify-space-between font-weight-semibold"
    >
      <div>
        <v-icon
          medium
          class="mb-2 pa-3"
          color="trsText"
        >
          {{ icon }}
        </v-icon>
        <slot name="heading" />
      </div>
      <div class="mt-3 mr-3">
        <slot name="card-actions" />
      </div>
    </div>
    <div
      class="dashboard-card-content custom-scroll-1"
      :style="{ height: height }"
    >
      <slot />
    </div>
  </common-trs-card>
</template>

<script>
  export default {
    name: 'DashboardCardV3',

    props: {
      icon: {
        type: String,
        default: undefined,
      },
      height: {
        type: String,
        default: '380px',
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dashboard-card-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 10px;
  }
  ::v-deep .v-icon.v-icon {
    font-size: 30px !important;
  }
</style>
