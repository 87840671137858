/** THIS IS COMMON CODE. PLEASE DO NOT CHANGE WITHOUT DISCUSSION WITH THE TEAM **/
// eslint-disable-next-line
import router from '@/router'

// initial state
const initialState = {
  loading: false,
  message: {
    color: 'success',
    timeout: 4000,
    mode: '',
    text: '',
    x: null,
    y: 'bottom',
  },
  showAlertModal: false,
  drawer: null,
}

// getters
const getters = {
  loading: state => state.loading,
  message: state => state.message,
  showAlertModal: state => state.showAlertModal,
}

// actions
const actions = {
  async initAuthData ({ commit, dispatch }, state) {
    // dispatch('sharedData/initData', null, { root: true })
  },
  async initApp ({ commit, dispatch }, state) {
    dispatch('publicBranding/getS3Data', null, { root: true })
  },
  async loading ({ commit }, state) {
    commit('setLoading', state)
  },
  async message ({ commit }, message) {
    commit('showMessage', message)
  },
  async handleError ({ commit }, payload) {
    let message = payload.message

    try {
      message = payload.err.response.data.detail || payload.message
    } catch (err) {
      // pass
    }

    commit('showMessage', {
      text: message,
      color: 'error',
    })
  },
}

// mutations
const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  showMessage (state, message) {
    state.message = Object.assign({}, {
      color: 'success',
      snackbar: false,
      y: 'bottom',
      x: null,
      mode: '',
      timeout: 4000,
      text: '',
    }, message)
  },
  setShowAlertModal (state, val) {
    state.showAlertModal = val
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
