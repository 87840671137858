<template>
  <div class="input-cont">
    <common-trs-data-table
      :headers="headers"
      :items="list"
      :options.sync="internalOptions"
      :server-items-length="totalCount"
      :loading="loading"
      dense
      hide-default-footer
    >
      <template v-slot:top="{}">
        <common-input-text-field
          v-model="filters.name"
          label="Filter"
          dense
          prepend-inner-icon="mdi-magnify"
          hide-details
          clearable
        />
        <v-divider />
        <div
          v-if="list && list.length > 0"
          class="my-2 mx-4"
        >
          <common-trs-a-link
            type="text"
            class="mr-4"
            @click="selectAll"
          >
            Select All
          </common-trs-a-link>
          <common-trs-a-link
            type="text"
            @click="deselectAll"
          >
            Deselect All
          </common-trs-a-link>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <common-input-checkbox
          :value="checkedStatus[item.id]"
          :label="item.name"
          dense
          hide-details
          class="mt-0"
          @change="(value) => selectChanged(value, item)"
        />
      </template>
      <template v-slot:no-data>
        <span> No Results Found. </span>
      </template>
    </common-trs-data-table>
  </div>
</template>

<script>
  export default {
    name: 'LongChecklist',

    components: {
    },

    props: {
      options: {
        type: Object,
        default: () => ({
          sortDesc: [false],
          sortBy: ['name'],
          itemsPerPage: 10000000,
        }),
      },
      filters: {
        type: Object,
        default: () => {},
      },
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      value: {
        type: Array,
        default: () => [],
        required: true,
      },
      totalCount: {
        type: Number,
        required: true,
        default: 0,
      },
      list: {
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      loading: false,
      headers: [{ text: 'Name', value: 'name', sortable: true }],
      selectedTags: [],
    }),

    computed: {
      selectedOnThisPage () {
        return this.value.filter(d => this.listContainer.indexOf(d.id) !== -1).length
      },
      checkedStatus () {
        const checked = {}
        this.list.map(d => {
          checked[d.id] = this.isChecked(d.id)
        })
        return checked
      },
      listContainer: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      internalOptions: {
        get () {
          return this.options
        },
        set (val) {
          this.$emit('update:options', val)
        },
      },
    },

    async created () {
    },

    methods: {
      async selectAll () {
        this.options.itemsPerPage = 10000000
        this.options.page = 1
        this.list.forEach(d => {
          if (this.listContainer.indexOf(d.id) === -1) {
            this.listContainer.push(d.id)
          }
        })
        this.listContainer = [...this.listContainer]
      },
      deselectAll () {
        this.list.forEach(d => {
          if (this.listContainer.indexOf(d.id) !== -1) {
            this.listContainer.splice(this.listContainer.indexOf(d.id), 1)
          }
        })
        this.listContainer = []
      },
      isChecked (id) {
        return this.value.indexOf(id) !== -1
      },
      selectChanged (value, item) {
        if (this.listContainer.indexOf(item.id) !== -1) {
          this.listContainer.splice(this.listContainer.indexOf(item.id), 1)
          this.listContainer = [...this.listContainer]
        } else {
          this.listContainer.push(item.id)
          this.listContainer = [...this.listContainer]
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-input input {
    padding: 20px 0px !important;
  }
  .org-check {
    width: auto;
    &.v-input--selection-controls {
      margin-top: 0px;
    }
  }
  .input-cont {
    border: 1px solid var(--v-trsBack-lighten1);
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td {
    border-bottom: none !important;
  }
  ::v-deep .v-data-footer__select .v-select {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  ::v-deep .v-data-table__wrapper {
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-track
  {
    background-color: #F5F5F5;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar
  {
    width: 7px;
    background-color: #F5F5F5;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb
  {
    background-color: #cacaca;
  }
  ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    border: 0;
  }
  ::v-deep .theme--light.v-input:not(.v-input--is-disabled) input {
    padding: 0 !important;
  }
</style>
