import { render, staticRenderFns } from "./DashboardCardV2.vue?vue&type=template&id=f6ad9368&scoped=true&"
import script from "./DashboardCardV2.vue?vue&type=script&lang=js&"
export * from "./DashboardCardV2.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCardV2.vue?vue&type=style&index=0&id=f6ad9368&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6ad9368",
  null
  
)

export default component.exports