<template>
  <v-tabs
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-tabs>
</template>
<script>
  export default {
    name: 'SectionTabs',
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-tabs-bar {
    border-top: 1px solid var(--v-trsBack-base);
    border-bottom: 1px solid var(--v-trsBack-base);
  }
  ::v-deep .v-slide-group.v-item-group > .v-slide-group__prev {
    border-right: 1px solid var(--v-trsBack-base);
  }
  ::v-deep .v-slide-group.v-item-group > .v-slide-group__next {
    border-left: 1px solid var(--v-trsBack-base);
  }
  ::v-deep .v-tab {
    height: 20px;
    margin-top: 14px;
    border-right: 1px solid var(--v-trsBack-base);
    padding: 0 25px;
    flex: 1 0 auto;
    -webkit-box-flex: 1;
    max-width: none;
  }
  ::v-deep .v-tab:last-child {
    border: none;
  }
  ::v-deep .v-tabs-slider-wrapper {
    padding: 0 15px;
    height: 3px !important;
  }
</style>
