<template>
  <router-link
    class="link-btn primary--text"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </router-link>
</template>

<script>
  export default {
    name: 'BaseBtn',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  .link-btn {
    padding: 0px !important;
    text-decoration: none;
  }
</style>
