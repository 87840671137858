export const NDA_STATUS = {
  NDA_DECLINED: 'NDA_DECLINED',
  NDA_SIGNED: 'NDA_SIGNED',
  NDA_SIGN_IN_PROCESS: 'NDA_SIGN_IN_PROCESS',
  NDA_COUNTERSIGN_IN_PROCESS: 'NDA_COUNTERSIGN_IN_PROCESS',
  NDA_EXECUTED: 'NDA_EXECUTED',
}

export const NDA_STATUS_STEPS = {
  NDA_DECLINED: -1,
  NDA_SIGNED: 0,
  NDA_SIGN_IN_PROCESS: 1,
  NDA_COUNTERSIGN_IN_PROCESS: 2,
  NDA_EXECUTED: 3,
}

export const NDA_STATUS_TEXT = {
  NDA_DECLINED: 'NDA Declined',
  NDA_SIGNED: 'NDA Signed',
  NDA_SIGN_IN_PROCESS: 'NDA Sign In Progress',
  NDA_COUNTERSIGN_IN_PROCESS: 'NDA Countersign In Process',
  NDA_EXECUTED: 'NDA Executed',
}

export const NDA_STATUS_TEXT_WITH_COLORS = {
  NDA_DECLINED: {
    label: 'Declined',
    color: 'error',
  },
  NDA_SIGNED: {
    label: 'Signed',
    color: 'primary',
  },
  NDA_SIGN_IN_PROCESS: {
    label: 'Sign In Progress',
    color: 'warning',
  },
  NDA_COUNTERSIGN_IN_PROCESS: {
    label: 'Countersign In Process',
    color: 'warning',
  },
  NDA_EXECUTED: {
    label: 'Executed',
    color: 'success',
  },
}
