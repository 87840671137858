<template>
  <v-text-field
    :v-mask="internalValue && internalValue.maskField ? internalValue.maskField : ''"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    class="trs-input"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
    <!-- https://github.com/vuetifyjs/vuetify/issues/11533 -->
    <template slot="label">
      <slot name="label" />
    </template>
  </v-text-field>
</template>

<script>
  export default {
    name: 'BaseInputTextField',

    props: {
      mask: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
          validateOnBlur: true,
          filled: true,
        },
      }
    },
    computed: {
      internalValue: {
        get () {
          return this.mask
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>
