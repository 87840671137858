import { api } from '@/api'
import axios from 'axios'
import { composeGetQuery } from '@/services/utils'
import { STATIC_COMMUNICATION_FAQ_URL } from '@/constants'

export const getCommunications = async (options, apiOpts) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get('QnA', { params }, apiOpts)

  return apiResponse.data
}

export const getCommunicationsByFidAdmin = async (options, apiOpts) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get('fid-admin/QnA', { params }, apiOpts)

  return apiResponse.data
}

export const updateCommunication = async (id, data, apiOpts, source) => {
  const apiResponse = await api.patch(`QnA/${id}?source=${source}`, data, apiOpts)

  return apiResponse.data
}

export const updateCommunicationByFidAmdin = async (id, data, apiOpts, source) => {
  const apiResponse = await api.patch(`fid-admin/QnA/${id}?source=${source}`, data, apiOpts)

  return apiResponse.data
}

export const createCommunication = async (data, apiOpts) => {
  const apiResponse = await api.post('QnA', data, apiOpts)

  return apiResponse.data
}

export const deleteCommunication = async (id, apiOpts, source) => {
  const apiResponse = await api.delete(`QnA/${id}?source=${source}`, apiOpts)

  return apiResponse.data
}

export const getCommunicationFaq = async () => {
  const apiResponse = await axios.get(`${STATIC_COMMUNICATION_FAQ_URL}`)

  return apiResponse
}
