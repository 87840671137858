var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-core-header px-2"},[_c('v-app-bar',_vm._g(_vm._b({},'v-app-bar',Object.assign({}, _vm.$attrs),false),_vm.$listeners),[(_vm.$route.meta.title)?_c('v-app-bar-title',{staticClass:"text-h4 font-weight-medium mx-2",attrs:{"shrink-on-scroll":""},domProps:{"textContent":_vm._s(_vm.$route.meta.title)}}):_c('v-app-bar-title',{staticClass:"text-h4 font-weight-medium mx-2",attrs:{"shrink-on-scroll":""},domProps:{"textContent":_vm._s(_vm.$greeting(_vm.userProfile.avatarText))}}),_c('v-spacer'),[_vm._t("applications"),_c('app-bar-communication-popup',{attrs:{"is-viewer":_vm.isViewer}}),(!_vm.isViewer && _vm.portal !== _vm.CONSTS.PORTAL.TR_ADMIN)?_c('div',[_c('common-trs-nav-nav-popup',{attrs:{"icon-name":'icon-notification-indicator trsText--text',"tooltip-title":'Notifications',"show-green-indicator":_vm.socketHasNewMessage},on:{"click":_vm.onAlertClicked},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var hideContent = ref.hideContent;
return [_c('alerts-card',{attrs:{"alerts":_vm.alerts,"notifications":_vm.notifications,"system-notifications":_vm.systemNotifications,"system-notification-count":_vm.systemNotificationCount,"see-all-alerts":_vm.seeAllAlerts},on:{"hideContent":hideContent,"alert-selected":function (item) { return _vm.alertSelected(item, hideContent); }}})]}}],null,false,1601320657)})],1):_vm._e(),_c('common-trs-nav-nav-popup',{attrs:{"icon-name":'icon-notification-indicator trsText--text',"tooltip-title":'Profile'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.menu;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"cursor-pointer mx-3",class:!_vm.profile || _vm.profile.length === 0 ? 'disable-events' : '',attrs:{"color":"primary"}},'div',attrs,false),menu),[_c('common-trs-nav-avatar',{class:_vm.portal !== _vm.CONSTS.PORTAL.INVESTOR ? 'cursor-pointer' : '',attrs:{"profile":_vm.userProfile}})],1)]}},{key:"content",fn:function(ref){
var hideContent = ref.hideContent;
return [(_vm.profile && _vm.profile.length > 0)?_c('v-list',_vm._l((_vm.profile),function(item,index){return _c('v-list-item',{key:index,on:{"click":function () {
                _vm.$router.push({ name: item.to, params: { currentTab: item.currentTab }});
                hideContent()
              }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1):_vm._e()]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }