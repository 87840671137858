<template>
  <v-chip
    :color="backgroundColor"
    close-icon="mdi-close-circle-outline"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </v-chip>
</template>

<script>
  export default {
    name: 'CommonTrsChip',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },

    computed: {
      backgroundColor () {
        return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].primary + '33'
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-chip.v-size--default {
    ::v-deep .v-chip__content {
      font-size: 1rem;
      font-weight: 400;
    }
  }
</style>
