<template>
  <fragment>
    <slot
      name="activator"
      v-bind="{ selectFile }"
    />
    <input
      v-show="showInput"
      ref="file"
      type="file"
      :accept="accept"
      label="File input"
      @change="fileChanged"
    >
  </fragment>
</template>

<script>
  /**
    * Opens file select on slot component activities
    */
  export default {
    name: 'BaseCommonButtonFileInput',

    props: {
      showInput: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: String,
        default: '*',
      },
      params: {
        validate: () => true,
        default: () => {},
      },
    },
    data () {
      return {
      }
    },
    computed: {
    },
    watch: {
    },

    methods: {
      selectFile () {
        this.$refs.file.click()
      },
      fileChanged (event) {
        this.$emit('onFileSelected', event.target.files, this.params)
        event.target.value = ''
      },
    },
  }
</script>

<style lang="stylus" scoped>

</style>
