<template>
  <v-snackbar
    v-if="message && !isPwa"
    v-model="snackbar"
    class="temp"
    :class="[positionOfSnak ? 'snakPosition' : '']"
    :color="message.color"
    :timeout="message.timeout"
    :top="message.y === 'top'"
    :bottom="message.y === 'bottom'"
    :right="message.x === 'right'"
    :left="message.x === 'left'"
    :multi-line="message.mode === 'multi-line'"
    :vertical="message.mode === 'vertical'"
  >
    <span class="mb-3">
      {{ displayMessage }}
    </span>

    <div
      v-if="!(isViewer && portal === CONSTS.PORTAL.ISSUER)"
      class="mt-4"
    >
      <common-trs-a-link
        class="linkbtn"
        text
        @click="goToRoute(actionRoute) ; snackbar = false"
      >
        Show more
      </common-trs-a-link>
    </div>

    <template v-slot:action="{ attrs }">
      <common-trs-btn
        color="white"
        icon
        v-bind="attrs"
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </common-trs-btn>
    </template>
  </v-snackbar>
</template>

<script>
  /**
    * color: 'success',
    * snackbar: false,
    * y: 'bottom',
    * x: null,
    * mode: '',
    * timeout: 4000,
    * text: '',
    */
  export default {
    props: {
      isPwa: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        snackbar: false,
        // y: 'bottom',
        // x: null,
        // mode: '',
        // timeout: 4000,
        // text: ''
        positionOfSnak: false,
      }
    },
    computed: {
      portal () {
        return this.$getPortal()
      },
      isViewer () {
        return this.$store.getters['auth/roles'].length === 1 && this.$store.getters['auth/roles'].includes(this.CONSTS.ISSUER_ROLE.ISSUER_VIEWER)
      },
      SuccessOrErrorMessage () {
        return this.$store.getters['app/message']
      },
      message () {
        return this.wsMessage()
      },
      displayMessage () {
        if (this.message && this.message.data && this.message.data.payload !== null) {
          return this.message.data.payload.message
        } else {
          this.$store.commit('app/showMessage', { text: 'Something went wrong.', color: 'error' })
          return false
        }
      },
      actionRoute () {
        return this.message.data.payload
      },
    },
    watch: {
      message: function (msg) {
        if (msg) {
          this.$store.dispatch('wsMessage/resetRoute')
          this.snackbar = true
        }
      },
      SuccessOrErrorMessage: function (msg) {
        if (msg) {
          this.changePositionOfSnakbar()
        }
      },
    },

    methods: {
      changePositionOfSnakbar () {
        this.positionOfSnak = true
        setTimeout(() => {
          this.positionOfSnak = false
        }, 4000)
      },
      wsMessage () {
        const socketMessage = this.$store.getters['socket/socket'].message
        if (socketMessage && socketMessage.data !== undefined && socketMessage.data.payload !== undefined) {
          return socketMessage
        } else {
          return false
        }
      },
      isRouteMatch (action) {
        const route = this.CONSTS.ROUTES_MAPPING[action.navigate_to]
        return this.$router.history.current.name === route.routeName
      },
      goToRoute (action) {
        const route = this.CONSTS.ROUTES_MAPPING[action.navigate_to]
        if (this.isRouteMatch(action)) {
          this.$store.dispatch('wsMessage/initData', { routeName: action })
        } else {
          if (route && route.params && route.query !== '') {
            this.$router.push({ name: route.routeName, params: { id: action.resource_id }, query: route.query })
          } else if (route && route.params) {
            this.$router.push({ name: route.routeName, params: { id: action.resource_id } })
          } else if (route && route.query !== '') {
            this.$router.push({ name: route.routeName, query: route.query })
          } else {
            this.$router.push({ name: route.routeName })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .v-snack__content{
   align-items: center;
    .linkbtn {
      color:white !important;
   }
}
.temp{
  width:50px;
  margin: 0 auto;
}
.snakPosition {
::v-deep .v-snack__wrapper{
    bottom:100px
  }
}
</style>
