<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="internalValue"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <common-input-text-field
        v-model="internalValue"
        readonly
        v-bind="{ ...attrs, ...$attrs }"
        v-on="{ ...on, ...$listeners }"
      >
        <template v-slot:append>
          <v-icon
            v-on="on"
          >
            mdi-calendar
          </v-icon>
        </template>
      </common-input-text-field>
    </template>
    <common-input-date-picker
      v-model="internalValue"
      v-bind="datePickerAttrs"
      no-title
      scrollable
    >
      <common-trs-btn
        text
        type="tertiary"
        medium
        class="primary--text"
        @click="menu = false"
      >
        Cancel
      </common-trs-btn>
      <v-spacer />
      <common-trs-btn
        text
        medium
        type="primary"
        class="white--text"
        @click="$refs.menu.save(internalValue)"
      >
        OK
      </common-trs-btn>
    </common-input-date-picker>
  </v-menu>
</template>

<script>
  /**
    * Previews pdf or image file. Requires uil and mimeType
    */
  export default {
    name: 'BaseCommonInlineDatePicker',

    components: {
    },

    props: {
      value: {
        type: String,
        default: '',
      },
      datePickerAttrs: {
        type: Object,
        default: () => ({}),
      },
    },

    data () {
      return {
        menu: false,
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
    },

    methods: {
    },
  }
</script>
