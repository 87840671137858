<template>
  <v-text-field
    v-model="cmpValue"
    :readonly="readonly"
    :disabled="disabled"
    :error="error"
    :rules="rules"
    :clearable="clearable"
    :prefix="options.prefix"
    :suffix="options.suffix"
    :label="label"
    :hide-details="hideDetails"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    class="trs-input"
    @keypress="keyPress"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        v-if="slot !== 'label'"
        :name="slot"
      />
    </template>
  </v-text-field>
</template>

<script>
  export default {
    model: { prop: 'value', event: 'input' },
    props: {
      value: {
        // type: String,
        type: [String, Number],
        default: '0',
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: undefined,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: [Boolean, String],
        default: false,
      },
      rules: {
        type: [Array, String],
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      backgroundColor: {
        type: String,
        default: 'white',
      },
      valueWhenIsEmpty: {
        type: String,
        default: '', // "0" or "" or null
      },
      options: {
        type: Object,
        default: function () {
          return {
            locale: 'en-US',
            prefix: '',
            suffix: '',
            length: 20,
            precision: 0,
          }
        },
      },
    },
    data () {
      return {
        defaultAttrs: {
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },
    computed: {
      cmpValue: {
        get: function () {
          return this.value !== null && this.value !== ''
            ? this.humanFormat(this.value.toString())
            : this.valueWhenIsEmpty
        },
        set: function (newValue) {
          this.$emit('input', this.machineFormat(newValue))
        },
      },
    },
    methods: {
      humanFormat: function (number) {
        if (isNaN(number)) {
          number = ''
        } else {
          // number = Number(number).toLocaleString(this.options.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
          number = Number(number).toLocaleString(this.options.locale, {
            maximumFractionDigits: this.options.precision,
            minimumFractionDigits: this.options.precision,
          })
        }
        return number
      },
      machineFormat (number) {
        if (number) {
          number = this.cleanNumber(number)
          number = number.padStart(parseInt(this.options.precision) + 1, '0')
          number =
            number.substring(
              0,
              number.length - parseInt(this.options.precision),
            ) +
            '.' +
            number.substring(
              number.length - parseInt(this.options.precision),
              number.length,
            )
          if (isNaN(number)) {
            number = this.valueWhenIsEmpty
          }
        } else {
          number = this.valueWhenIsEmpty
        }
        if (this.options.precision === 0) {
          number = this.cleanNumber(number)
        }
        // ANDY: if number is empty string then just return empty not 0, +'' is 0
        if (number === '') {
          return ''
        }
        return +number
      },
      keyPress ($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which
        // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault()
        }
        if (this.targetLength()) {
          $event.preventDefault()
        }
      },
      cleanNumber: function (value) {
        let result = ''
        if (value) {
          let flag = false
          const arrayValue = value.toString().split('')
          for (var i = 0; i < arrayValue.length; i++) {
            if (this.isInteger(arrayValue[i])) {
              if (!flag) {
                if (arrayValue[i] !== '0') {
                  result = result + arrayValue[i]
                  flag = true
                }
              } else {
                result = result + arrayValue[i]
              }
            }
          }
        }
        return result
      },
      isInteger (value) {
        let result = false
        if (Number.isInteger(parseInt(value))) {
          result = true
        }
        return result
      },
      targetLength () {
        if (
          Number(this.cleanNumber(this.value).length) >=
          Number(this.options.length)
        ) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
