<template>
  <v-card
    v-bind="$attrs"
    class="pa-4 ma-0 elevation-0 rounded-1 stats-card"
    v-on="$listeners"
  >
    <div class="d-flex">
      <div
        class="dash-icon d-flex align-center justify-start"
      >
        <v-icon
          class="mr-2 icon-font-size"
          color="black"
        >
          {{ iconClass }}
        </v-icon>
      </div>
      <div class="flex-fill">
        <h3 class="trsText--text text-h3 font-weight-semibold">
          {{ value }}
        </h3>
        <div
          class="trsText--text text-body-2"
          v-text="title"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialStatsCardV2',

    inheritAttrs: false,

    props: {
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: [String, Number],
        default: undefined,
      },
      iconClass: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
<style lang="scss" scoped>
 .dash-icon {
    width: 60px;
    min-width: 60px;
  }
  .icon-font-size {
    font-size:40px!important;
  }
  .stats-card {
    min-height: 85px;
  }
</style>
