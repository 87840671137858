<template>
  <v-card-title
    class="trs-card-title trsBack lighten-1 d-flex align-center justify-space-between"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </v-card-title>
</template>

<script>
  export default {
    name: 'CommonTrsModalCardTitle',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .trs-card-title {
  }
</style>
