<template>
  <div>
    <common-trs-card
      class="app-page-header-v2"
      :style="{ padding: `${padding}px` }"
    >
      <slot
        name="prepend"
      />

      <div
        class="d-flex align-start flex-column flex-md-row align-md-center"
      >
        <div class="d-flex align-center icon-with-title">
          <v-icon
            v-if="icon"
            large
            class="mr-4"
            color="black"
          >
            {{ icon }}
          </v-icon>
          <slot
            name="title"
          >
            <common-trs-btn
              v-if="hasBackButton"
              text
              type="tertiary"
              class="back-btn mr-4"
              x-small
              color="black"
              @click="$router.back()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </common-trs-btn>
            <v-tooltip
              v-if="parsedTitle && parsedTitle.tooltip"
              bottom
            >
              <template v-slot:activator="{ on }">
                <span
                  class="text-h4 display-md-2 flex-fill overflow-limit"
                  v-on="on"
                >
                  {{ parsedTitle.value }}
                </span>
              </template>
              <span>{{ parsedTitle.tooltipMessage }}</span>
            </v-tooltip>
            <div
              v-else-if="parsedTitle && parsedTitle.value"
              class="trsText--text text-h3 font-weight-medium"
            >
              {{ parsedTitle.value }}
            </div>
          </slot>
        </div>

        <div
          class="flex-fill mb-2 mb-md-0"
        >
          <slot
            name="actions"
          />
        </div>

        <div
          v-if="$scopedSlots['primary-filter'] || $slots['primary-filter']"
          class="ml-md-4 primary-filter p-relative  mb-2 mb-md-0"
        >
          <div
            v-if="appliedFilter > 0"
            class="ma-2 primary--text p-absolute text-body-2"
            style="bottom: 32px; right:0px"
          >
            ({{ appliedFilter }}) Filters Applied
          </div>
          <slot
            name="primary-filter"
            v-bind="{ toggleExpanded, expanded }"
          />
        </div>
      </div>

      <v-expand-transition>
        <v-row
          v-show="expanded"
          dense
          class="mb-2 mb-md-4 mt-0 mt-md-2"
          :class="alignRight ? 'flex-row-reverse' : ''"
        >
          <slot name="content" />
        </v-row>
      </v-expand-transition>

      <slot
        name="append"
      />
    </common-trs-card>
    <common-common-modal
      :value="showModal"
      control-visibility
      :max-width="850"
    >
      <template>
        <common-trs-modal-card v-if="showModal">
          <validation-observer
            ref="observer"
            class="ml-auto"
          >
            <common-trs-modal-card-title>
              <common-trs-modal-card-title-content>
                Filters
              </common-trs-modal-card-title-content>

              <common-trs-btn
                type="secondary"
                class="rounded-0"
                color="primary"
                size="large"
                icon
                text
                @click="close"
              >
                <v-icon>mdi-close</v-icon>
              </common-trs-btn>
            </common-trs-modal-card-title>
            <common-trs-modal-card-text>
              <div><slot name="filter-body" /></div>
            </common-trs-modal-card-text>
            <common-trs-modal-card-actions>
              <!-- <slot
                v-if="showModal"
                name="action-button"
              /> -->
              <div
                class="d-flex justify-end align-center"
                style="width:100%"
              >
                <!-- <div>
                  <common-trs-btn
                    class="mr-3 primary--text"
                    type="tertiary"
                    text
                    @click="close"
                  >
                    Cancel
                  </common-trs-btn>
                </div> -->
                <div class="mr-2">
                  <common-trs-btn
                    type="primary"
                    class="white--text"
                    @click="applyFilterChanges"
                  >
                    Apply
                  </common-trs-btn>
                </div>
              </div>
            </common-trs-modal-card-actions>
          </validation-observer>
        </common-trs-modal-card>
      </template>
    </common-common-modal>
  </div>
</template>

<script>
  export default {
    name: 'CoreAppPageHeaderV2',

    components: {
    },

    props: {
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      appliedFilter: {
        type: Number,
        default: 0,
      },
      noHeaderPadding: {
        type: Boolean,
        default: false,
      },
      padding: {
        type: Number,
        default: 24,
      },
      alignRight: {
        type: Boolean,
        default: false,
      },
      hasBackButton: {
        type: Boolean,
        default: false,
      },
      showFilterOnModal: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        expanded: false,
        showModal: false,
      }
    },

    computed: {
      parsedTitle () {
        if (!this.title) {
          return {
            tooltip: false,
            value: '',
          }
        }
        const limit = 50
        if (this.title.length > limit) {
          return {
            tooltip: true,
            value: this.title.slice(0, 50) + '...',
            tooltipMessage: this.title,
          }
        } else {
          return {
            tooltip: false,
            value: this.title,
          }
        }
      },
    },

    methods: {
      toggleExpanded () {
        if (this.showFilterOnModal === true) {
          this.showModal = true
        } else {
          this.expanded = !this.expanded
        }
      },
      close () {
        this.showModal = false
      },
      applyFilterChanges () {
        this.$emit('updateFilter')
        setTimeout(() => {
          this.showModal = false
        }, 2000)
      },
    },
  }
</script>
<style lang="scss">
  .app-page-header-v2 .v-label {
    font-style: italic;
    padding-right: 3px;
    &.v-label--active {
      font-style: normal;
    }
  }
  .app-page-header-v2 .primary-filter .trs-input {
    width: 250px;
    .v-text-field__slot .v-label {
      top: 8px;
    }
    .v-input__prepend-inner {
      margin-top: 7px !important;
    }
    .v-input__append-inner {
      margin-top: 7px !important;
    }
  }
</style>
