export const DOCUMENT_TYPES = {
  NDA: 'NDA',
  DEAL: 'DEAL',
  'Fid Document': 'Fid Document',
  OPPORTUNITY_TAX_DOCUMENT: 'OPPORTUNITY_TAX_DOCUMENT',
  OPPORTUNITY_UPDATE_DOCUMENT: 'OPPORTUNITY_UPDATE_DOCUMENT',
  TAX: 'TAX',
  UPDATE: 'UPDATE',
  STATEMENT: 'STATEMENT',
}

export const DOCUMENT_TYPES_TEXT = {
  NDA: 'NDA',
  DEAL: 'Deal Documents',
  'Fid Document': 'Fid Document',
  OPPORTUNITY_TAX_DOCUMENT: 'Tax',
  OPPORTUNITY_UPDATE_DOCUMENT: 'Updates',
  TAX: 'Tax',
  UPDATE: 'Updates',
  STATEMENT: 'Statements',
}

export const DOCUMENT_TYPES_TEXT_SHORT = {
  NDA: 'NDA',
  DEAL: 'Deal',
  'Fid Document': 'Fid Document',
  OPPORTUNITY_TAX_DOCUMENT: 'Tax',
  OPPORTUNITY_UPDATE_DOCUMENT: 'Updates',
  TAX: 'Tax',
  UPDATE: 'Updates',
  STATEMENT: 'Statements',
}

export const DR_DOC_LOAD_FROM = {
  ISSUER_DOC: 'ISSUER_DOCUMENT',
  OPP_DOC: 'OPPORTUNITY_DOCUMENT',
  TENANT_DOC: 'TENANT_DOCUMENT',
}
