<template>
  <v-snackbar
    :class="classes"
    :value="value"
    v-bind="{
      ...$attrs,
      ...$props,
      'color': 'transparent'
    }"
    @change="$emit('change', $event)"
  >
    <common-material-alert
      v-model="internalValue"
      :color="color"
      :dismissible="dismissible"
      class="ma-0"
      dark
      style="width: 100%"
    >
      <slot />
    </common-material-alert>
  </v-snackbar>
</template>
<script>
  // Components
  import { VSnackbar } from 'vuetify/lib'

  export default {
    name: 'BaseMaterialSnackbar',

    extends: VSnackbar,

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
      },
    },

    computed: {
      classes () {
        return {
          ...VSnackbar.options.computed.classes.call(this),
          'v-snackbar--material': true,
        }
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>

<style lang="sass" scoped>
  .v-snackbar--material
    .v-alert--material,
    .v-snack__wrapper
      border-radius: 4px

    ::v-deep .v-snack__action
      margin-right: 0
    ::v-deep .v-snack__wrapper
      margin: 0
      margin-bottom: 32px
    ::v-deep .v-snack__content
      overflow: visible
      padding: 0
</style>
