<template>
  <v-row no-gutters>
    <v-col cols="12">
      <common-input-phone-input
        v-model="internalValue"
        v-bind="{ ...defaultAttrs, ...$attrs }"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $slots"
          v-slot:[slot]
        >
          <slot
            :name="slot"
          />
        </template>
      </common-input-phone-input>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputTextField',

    props: {
      value: {
        type: String,
        default: '+1',
      },
    },

    data () {
      return {
        defaultAttrs: {
          filled: true,
          color: 'primary',
          validateOnBlur: true,
        },
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value || ''
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    created () {
      if (!this.value) {
        // this.internalValue = '+1'
      }
    },

    methods: {
    },
  }
</script>
