<template>
  <div
    class="text-h5"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'CommonTrsModalCardTitleContent',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
