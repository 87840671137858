<template>
  <v-dialog
    v-model="visible"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      >
        <common-trs-btn
          type="primary"
          class="white--text"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Sign
        </common-trs-btn>
      </slot>
    </template>
    <div
      v-if="docuSignUrl"
      class="iframe-container d-flex flex-column"
    >
      <iframe
        ref="docusignIframe"
        :src="docuSignUrl"
        frameborder="0"
        width="100%"
        style="flex: 1"
        @load="handleUrl($event)"
      />
    </div>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DocuSignModal',

    props: {
      docuSignUrl: {
        type: String,
        required: true,
      },
    },

    data () {
      return {
        visible: false,
      }
    },

    watch: {
      docuSignUrl: {
        handler (current, prev) {
          if (current && !prev) {
            this.visible = true
          }
        },
        immediate: true,
      },
    },

    mounted () {
    },

    methods: {
      handleUrl (ev) {},
    },
  }
</script>
<style lang="scss" scoped>
  .iframe-container {
    width: 100%;
    height: 89vh;
    background: #FFF;
  }
</style>
